import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TripTypeFWHeader from './compound-components/TripTypeFWHeader';
import CategoryBars from './compound-components/CategoryBars';
import TripCard from './compound-components/TripCard';
import { tripData } from '../../constants/TripTypeExplorerData';
import styles from './TripTypesFramework.module.css';


export default function TripTypesFramework() {

  const navigate = useNavigate();

  const clickHandler = useCallback((navigateTo) => {
    navigate(navigateTo);
  }, [navigate]);

  return (
    <div className={styles.categoryStructureFramework}>
      <TripTypeFWHeader />
      <CategoryBars />
      <div className={styles.tripGrid}>
        {tripData.map(trip => (
          <TripCard key={trip.id} {...trip} onClick={() => clickHandler(trip.navigateTo)} />
        ))}
      </div>
    </div>
  );
}
