import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';
import '../../styles/SubNav.scss';
import PropTypes from 'prop-types';


const Header = styled.header`
  background-color: ${props => props.backgroundColor || '#cfbc09'};
`;

const ContentSpacer = styled.div`
  height: 40px;
`;

const SubNavigation = styled.nav`
  background-color: ${props => props.backgroundColor || '#cfbc09'};
`;

const NavLink = styled.a`
  font-family: 'Inter-Bold', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.textColor || '#000'};
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  height: 40px;

  &:hover {
    background-color: ${props => props.hoverColor};
  }

  &.active {
    background-color: ${props => props.activeColor};
  }
`;

const NavLabel = styled.span`
  a {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: ${props => props.textColor || '#000'};
    font-family: Inter-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

function CatTripSubNav({ 
  subCategory,
  mainNavLinks, 
  subNavLinks, 
  anchorBarColor, 
  anchorBarColorSecondary,
  textColor,
}) {
  const [activeSection, setActiveSection] = useState('');
  const location = useLocation();

  useEffect(() => {
    let observer;
    let retryCount = 0;
    const maxRetries = 5;
    
    const debouncedSetActive = debounce((newSection) => {
      setActiveSection(newSection);
    }, 100);
    
    const observeSections = () => {
      if (observer) {
        observer.disconnect();
      }

      const sections = document.querySelectorAll('.builder-block[id]');
      
      if (sections.length === 0 && retryCount < maxRetries) {
        retryCount++;
        const delay = Math.min(1000 * Math.pow(2, retryCount), 10000);
        setTimeout(observeSections, delay);
        return;
      }

      if (sections.length > 0) {
        observer = new IntersectionObserver(
          (entries) => {
            const visibleEntries = entries.filter(entry => entry.isIntersecting);
            
            if (visibleEntries.length > 0) {
              const mostVisible = visibleEntries.reduce((prev, current) => {
                const prevRect = prev.boundingClientRect;
                const currentRect = current.boundingClientRect;
                
                const getVisibleHeight = (rect) => {
                  const windowHeight = window.innerHeight;
                  const top = Math.max(0, rect.top);
                  const bottom = Math.min(windowHeight, rect.bottom);
                  return Math.max(0, bottom - top);
                };

                const prevVisible = getVisibleHeight(prevRect);
                const currentVisible = getVisibleHeight(currentRect);

                return currentVisible > prevVisible ? current : prev;
              }, visibleEntries[0]);

              if (mostVisible.target.id !== activeSection) {
                debouncedSetActive(mostVisible.target.id);
              }
            }
          },
          { 
            root: null,
            threshold: [0.1, 0.25, 0.5, 0.75, 0.9],
            rootMargin: '-20% 0px -20% 0px'
          }
        );

        sections.forEach((section) => {
          observer.observe(section);
        });
      }
    };

    const handleScroll = () => {
      if (!observer) {
        observeSections();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    const initialDelay = setTimeout(observeSections, 1500);

    return () => {
      if (observer) {
        observer.disconnect();
      }
      clearTimeout(initialDelay);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, activeSection]);

  return (
    <>
      <Header className="anchorBar" backgroundColor={anchorBarColor}>
        <nav className="mainNavAtomAnchorBarLink">
          <NavLabel textColor={textColor}>
            <Link to={mainNavLinks.mainCategory.to} className="navLinkLabel">{mainNavLinks.mainCategory.label}</Link>
          </NavLabel>
          <ChevronRightIcon className="navIcon" />
          <NavLabel textColor={textColor}>
            <span className="navLinkLabel">{mainNavLinks.subCategory[subCategory].label}</span>
          </NavLabel>
        </nav>
        <SubNavigation className="subNav" backgroundColor={anchorBarColorSecondary}>
          {subNavLinks.map(({ href, text }) => (
            <NavLink
              href={href}
              key={text}
              className={activeSection === href.substring(1) ? 'active' : ''}
              hoverColor={anchorBarColor}
              activeColor={anchorBarColor}
              textColor={textColor}
            >
              {text}
            </NavLink>
          ))}
        </SubNavigation>
      </Header>
      <ContentSpacer />
    </>
  );
}

CatTripSubNav.propTypes = {
  subCategory: PropTypes.string.isRequired,
  mainNavLinks: PropTypes.shape({
    mainCategory: PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    subCategory: PropTypes.object.isRequired,
  }).isRequired,
  subNavLinks: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  anchorBarColor: PropTypes.string,
  anchorBarColorSecondary: PropTypes.string,
  textColor: PropTypes.string,
};

export default CatTripSubNav;