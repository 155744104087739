import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import SectionIntro from "../../common/page-sections/SectionIntro";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import UGCCardSection from "../../custom/UGC_Cards/UGCCardSection";
import { useParams } from "react-router-dom";
import { peopleSegmentAssets } from "../../common/PeopleSegmentAssets";
import { peopleSegmentFocusOnHealthModularContent, peopleSegmentOutlookModularContent, peopleSegmentPropensitytoExploreModularContent, peopleSegmentSociabilityModularContent, peopleSegmentSpendingModularContent, peopleSegmentStatusModularContent } from "../../../constants/PeopleSegment";
import CustomSlider from "../../custom/Slider/CustomSlider";
import { getModularCards } from "../../../constants/constants";
import CarouselModal from "../../custom/Modal/CarouselModal";


export default function SectionTwo({ sectionData }) {
  const { t } = useTranslation(i18next.language);
  const { macroSegment, microSegment } = useParams();

  const percentSliderdata0 = sectionData[0]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  const percentSliderdata1 = sectionData[1]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  const percentSliderdata2 = sectionData[2]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  const percentSliderdata3 = sectionData[3]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  const percentSliderdata4 = sectionData[4]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  const percentSliderdata5 = sectionData[5]?.values?.filter(
    (subItem) => subItem.microCode === microSegment
  );

  useEffect(() => {
    // This code runs after the component has been mounted and the DOM elements are available
    // Get the heights of both paragraphs
    const text1Element = document.getElementById('text1');
    const text2Element = document.getElementById('text2');

    if (text1Element && text2Element) { // Check if elements exist
      const text1Height = text1Element.clientHeight;
      const text2Height = text2Element.clientHeight;

      // Determine the maximum height
      const maxHeight = Math.max(text1Height, text2Height);

      // Set the height of both paragraphs to the maximum height
      text1Element.style.height = `${maxHeight}px`;
      text2Element.style.height = `${maxHeight}px`;
    }
    const text3Element = document.getElementById('text3');
    const text4Element = document.getElementById('text4');

    if (text3Element && text4Element) { // Check if elements exist
      const text3Height = text3Element.clientHeight;
      const text4Height = text4Element.clientHeight;

      // Determine the maximum height
      const maxHeight = Math.max(text3Height, text4Height);

      // Set the height of both paragraphs to the maximum height
      text3Element.style.height = `${maxHeight}px`;
      text4Element.style.height = `${maxHeight}px`;
    }
    const card3Element = document.getElementById('card3');
    const card4Element = document.getElementById('card4');

    if (card3Element && card4Element) { // Check if elements exist
      const card3Height = card3Element.clientHeight;
      const card4Height = card4Element.clientHeight;

      // Determine the maximum height
      const maxHeight = Math.max(card3Height, card4Height);

      // Set the height of both paragraphs to the maximum height
      card3Element.style.height = `${maxHeight}px`;
      card4Element.style.height = `${maxHeight}px`;
    }
    if (card3Element && card4Element) { // Check if elements exist
      const card3Height = card3Element.clientHeight;
      const card4Height = card4Element.clientHeight;

      // Determine the maximum height
      const maxHeight = Math.max(card3Height, card4Height);

      // Set the height of both paragraphs to the maximum height
      card3Element.style.height = `${maxHeight}px`;
      card4Element.style.height = `${maxHeight}px`;
    }
  }, []); // Empty dependency array ensures this runs only once after component mount


  return (
    <>
      <Box className={`people-section2-background ${macroSegment}-dark`}>
        <Box className="people-section2-main">
          <SectionIntro
            heading={t("people-segment.anchorItems.whatDrivesThem")}
            description={t(
              "people-segment.template.whatDrivesThem.description"
            )}
            sectionImages={{
              back: peopleSegmentAssets.whatDrivesThem[microSegment].back(),
              front: peopleSegmentAssets.whatDrivesThem[microSegment].front(),
            }}
          />
        </Box>
      </Box>
      <Box className="modular-content">
        <Box className="propensitytoexplore-content">
          <Box className="ugc-propensitytoexplore-box">
            <UGCCardSection
              title={t("people-segment.sliders.slider1_heading")}
              content={
                <Trans components={{ colored: <i /> }}>
                  {t(
                    `people-segment.template.whatDrivesThem.${microSegment}.propensityToExplore.intro`
                  )}
                </Trans>
              }
              selectedSegment={microSegment}
            />
          </Box>
          <Box className="ugc-openness-box">
            <Box className="ugc-slider-div">
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                  fontFamily: 'Inter-Bold'
                }}>
                  {sectionData[0].sliderStartValue}
                </Typography>
                <CustomSlider space={microSegment} values={percentSliderdata0} />
                <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                  fontFamily: 'Inter-Bold'
                }}>
                  {sectionData[0].sliderEndtValue}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box>
            <Grid className="propensitytoexplore-four-cards-container" container spacing={2} justifyContent="space-between">
              {
                peopleSegmentPropensitytoExploreModularContent[`${microSegment}`].blockCards.map((modularItem, index) => (
                  <Grid item xs={6} key={`peoplesegment-intheirownwords-modularcard-${index + 1}`}
                    className="modular-item">
                    {getModularCards(
                      `${microSegment}`,
                      modularItem.cardType,
                      modularItem.data
                    )
                    }
                  </Grid>
                ))
              }
            </Grid>
            <CarouselModal microSegment={microSegment} carouselData={peopleSegmentPropensitytoExploreModularContent[`${microSegment}`].carouselCards} theme={microSegment} />
          </Box>
        </Box>
        <Box className="sociability-content">
          <Box className="ugc-sociability-box">
            <UGCCardSection
              title={t("people-segment.sliders.slider2_heading")}
              content={
                <Trans components={{ colored: <i /> }}>
                  {t(
                    `people-segment.template.whatDrivesThem.${microSegment}.sociability.intro`
                  )}
                </Trans>
              }
              selectedSegment={microSegment}
            />
          </Box>
          <Box className="ugc-openness-box">
            <Box className="ugc-slider-div">
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                  fontFamily: 'Inter-Bold'
                }}>
                  {sectionData[1].sliderStartValue}
                </Typography>
                <CustomSlider space={microSegment} values={percentSliderdata1} />
                <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                  fontFamily: 'Inter-Bold'
                }}>
                  {sectionData[1].sliderEndtValue}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box className="sociability-container">
            <Grid className="sociability-four-cards-container" container spacing={2} justifyContent="space-between">
              {
                peopleSegmentSociabilityModularContent[`${microSegment}`].blockCards.map((modularItem, index) => (
                  <Grid item xs={6} key={`peoplesegment-intheirownwords-modularcard-${index + 1}`}
                    className="modular-item">
                    {getModularCards(
                      `${microSegment}`,
                      modularItem.cardType,
                      modularItem.data
                    )
                    }
                  </Grid>
                ))
              }
            </Grid>
            <CarouselModal microSegment={microSegment} carouselData={peopleSegmentSociabilityModularContent[`${microSegment}`].carouselCards} theme={microSegment} />
          </Box>
        </Box>
        <Grid container className="focusonhealth-spending-status-outlook-container" gap={10} justifyContent="space-between">
          <Grid item xs={5.5} className="focusonhealth-content" >
            <Box className='introandsliderdata-box'>
                <Box className="ugc-focusonhealth-box" id="text1">
                  <UGCCardSection
                    title={t("people-segment.sliders.slider3_heading")}
                    content={
                      <Trans components={{ colored: <i /> }}>
                        {t(
                          `people-segment.template.whatDrivesThem.${microSegment}.focusOnHealth.intro`
                        )}
                      </Trans>
                    }
                    selectedSegment={microSegment}
                  />
                </Box>
                <Box className="ugc-openness-box">
                  <Box className="ugc-slider-div">
                    <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                      <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                        fontFamily: 'Inter-Bold'
                      }}>
                        {sectionData[2].sliderStartValue}
                      </Typography>
                      <CustomSlider space={microSegment} values={percentSliderdata2} />
                      <Typography whiteSpace="nowrap" variant="body1" color="white" sx={{
                        fontFamily: 'Inter-Bold'
                      }}>
                        {sectionData[2].sliderEndtValue}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
            </Box>
            <Box className='card-content-box' id="card1" sx={{ display: 'flex', flex: 1}}>
              <Grid className="modular-cards-container" container height="100%" alignItems="stretch" alignSelf="stretch">
                <Grid item xs={12} className="modular-item">
                  {getModularCards(
                    `${microSegment}`,
                    peopleSegmentFocusOnHealthModularContent[`${microSegment}`].modularCard1[0].cardType,
                    peopleSegmentFocusOnHealthModularContent[`${microSegment}`].modularCard1[0].data
                  )
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5.5} className="spending-content">
            <Box className='introandsliderdata-box'>
                <Box className="ugc-spending-box" id="text2">
                  <UGCCardSection
                    title={t("people-segment.sliders.slider4_heading")}
                    content={
                      <Trans components={{ colored: <i /> }}>
                        {t(
                          `people-segment.template.whatDrivesThem.${microSegment}.spending.intro`
                        )}
                      </Trans>
                    }
                    selectedSegment={microSegment}
                  />
                </Box>
                <Box className="ugc-openness-box">
                  <Box className="ugc-slider-div">
                    <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[3].sliderStartValue}
                      </Typography>
                      <CustomSlider space={microSegment} values={percentSliderdata3} />
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[3].sliderEndtValue}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
            </Box>
            <Box className='card-content-box' id="card2" sx={{ display: 'flex', flex: 1}}>
              <Grid className="modular-cards-container" container>
                <Grid item xs={12} className="modular-item">
                  {getModularCards(
                    `${microSegment}`,
                    peopleSegmentSpendingModularContent[`${microSegment}`].modularCard1[0].cardType,
                    peopleSegmentSpendingModularContent[`${microSegment}`].modularCard1[0].data
                  )
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5.5} className="status-content">
            <Box className='introandsliderdata-box'>
                <Box className="ugc-status-box" id="text3">
                  <UGCCardSection
                    title={t("people-segment.sliders.slider5_heading")}
                    content={
                      <Trans components={{ colored: <i /> }}>
                        {t(
                          `people-segment.template.whatDrivesThem.${microSegment}.status.intro`
                        )}
                      </Trans>
                    }
                    selectedSegment={microSegment}
                  />
                </Box>
                <Box className="ugc-openness-box">
                  <Box className="ugc-slider-div">
                    <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[4].sliderStartValue}
                      </Typography>
                      <CustomSlider space={microSegment} values={percentSliderdata4} />
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[4].sliderEndtValue}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
            </Box>
            <Box className='card-content-box' id="card3" sx={{ display: 'flex', flex: 1}}>
              <Grid className="modular-cards-container" container>
                <Grid item xs={12} className="modular-item">
                  {getModularCards(
                    `${microSegment}`,
                    peopleSegmentStatusModularContent[`${microSegment}`].modularCard1[0].cardType,
                    peopleSegmentStatusModularContent[`${microSegment}`].modularCard1[0].data
                  )
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5.5} className="outlook-content">
            <Box className='introandsliderdata-box'>
                <Box className="ugc-outlook-box" id="text4">
                  <UGCCardSection
                    title={t("people-segment.sliders.slider6_heading")}
                    content={
                      <Trans components={{ colored: <i /> }}>
                        {t(
                          `people-segment.template.whatDrivesThem.${microSegment}.outlook.intro`
                        )}
                      </Trans>
                    }
                    selectedSegment={microSegment}
                  />
                </Box>
                <Box className="ugc-openness-box">
                  <Box className="ugc-slider-div">
                    <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[5].sliderStartValue}
                      </Typography>
                      <CustomSlider space={microSegment} values={percentSliderdata5} />
                      <Typography whiteSpace="nowrap" variant="body1" color="white"
                        sx={{
                          fontFamily: 'Inter-Bold'
                        }}>
                        {sectionData[5].sliderEndtValue}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
            </Box>
            <Box className='card-content-box' id="card4" sx={{ display: 'flex', flex: 1}}>
              <Grid className="modular-cards-container" container>
                <Grid item xs={12} className="modular-item">
                  {getModularCards(
                    `${microSegment}`,
                    peopleSegmentOutlookModularContent[`${microSegment}`].modularCard1[0].cardType,
                    peopleSegmentOutlookModularContent[`${microSegment}`].modularCard1[0].data
                  )
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

}
