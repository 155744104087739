import { Link } from "react-router-dom";

export default function CustomLink({ href, children, ...props }) {

    if (href.startsWith('/')) {
        return (
            <Link to={href} {...props}>
                {children}
            </Link>
        );
    }

    return (
        <a href={href} {...props}>
            {children}
        </a>
    );
}