import React, { useState } from 'react';
import { Tab } from './compound-components/Tab';
import styles from './TopBrandTable.module.css';
import PropTypes from 'prop-types';


const tabs = [
  { id: 'brands', label: 'Top Brands by Price Tier' },
  { id: 'growth', label: 'Size & Growth by Price Tier' }
];

export const TopBrandTable = ({ 
  categoryStructure, 
  topBrandsTable, 
  topBrandsTableAltText, 
  sizeGrowthTable, 
  sizeGrowthTableAltText 
}) => {

  const [activeTab, setActiveTab] = useState('brands');

  return (
    <div className={styles.container}>
      <div className={styles.navigationSection}>
        <h1 className={styles.mainTitle}>{categoryStructure}</h1>
        <div className={styles.tabContainer} role="tablist">
          {tabs.map(tab => (
            <Tab
              key={tab.id}
              isActive={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </Tab>
          ))}
        </div>
      </div>
      <img
        src={activeTab === 'brands' ? topBrandsTable : sizeGrowthTable}
        alt={activeTab === 'brands' ? topBrandsTableAltText : sizeGrowthTableAltText}
        loading="lazy"
        className={styles.chartImage}
      />
    </div>
  );
};

TopBrandTable.propTypes = {
  categoryStructure: PropTypes.string.isRequired,
  topBrandsTable: PropTypes.string.isRequired,
  topBrandsTableAltText: PropTypes.string.isRequired,
  sizeGrowthTable: PropTypes.string.isRequired,
  sizeGrowthTableAltText: PropTypes.string.isRequired,
};
