import React from 'react';
import { GrowthChart } from './GrowthChart';
import { GrowthMetrics } from './GrowthMetrics';
import { BrandShare } from './BrandShare';
import styles from './SegmentGrowthSection.module.css';
import PropTypes from 'prop-types';


export const SegmentGrowthSection = ({ 
  chartTitle, 
  chartImage, 
  growthMetrics, 
  brandShareData,
  subcatAccentColor,
  onAccentTextColor,
  brandShareDataColor
}) => {
  return (
    <div className={styles.container}>
      <GrowthChart 
        chartTitle={chartTitle} 
        chartImage={chartImage} 
      />
      <GrowthMetrics 
        growthMetrics={growthMetrics}
        subcatAccentColor={subcatAccentColor}
        onAccentTextColor={onAccentTextColor}
      />
      <BrandShare 
        brandShareData={brandShareData}
        brandShareDataColor={brandShareDataColor}
      />
    </div>
  );
};

SegmentGrowthSection.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  chartImage: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
  }).isRequired,
  growthMetrics: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  brandShareData: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shareInfo: PropTypes.string.isRequired,
  })).isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
  brandShareDataColor: PropTypes.string.isRequired,
};
