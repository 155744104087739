import React from 'react';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './AlcoholCategories.module.css';


const ALCOHOL_CATEGORIES_IMAGE_SRC = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd97f2e395c5e41fabc5e65add18ece47";

export const AlcoholCategories = () => {
  return (
    <div className={styles.container}>
      <Card data-testid="research-section-container" height="600px">
        <h1 className={styles.heading}>
          The Alcohol industry is comprised of 4 categories: Beer, Hard
          Beverage, Spirits, & Wine
        </h1>
        <img 
          src={ALCOHOL_CATEGORIES_IMAGE_SRC} 
          alt="Alcohol categories chart" 
          className={styles.alcoholCategoriesChart}
        />
      </Card>
    </div>
  );
};
