import React from 'react';
import { ResearchCard } from './compound-components/ResearchCard';
import { researchData } from './data/data';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './ResearchSection.module.css';

export function ResearchSection() {
  return (
    <Card data-testid="research-section-container" height="600px">
      <section className={styles.container}>
        <h2 className={styles.heading}>
          This holistic understanding of shopper is gained through extensive research
        </h2>
        <div className={styles.cardsContainer}>
          {researchData.map((item) => (
            <ResearchCard
              key={item.id}
              image={item.image}
              title={item.title}
              number={item.number}
              description={item.description}
            />
          ))}
        </div>
      </section>
    </Card>
  );
}
