import StatementCard from "../components/custom/UGC_Cards/StatementCard";
import QuoteAttributionCard from "../components/custom/UGC_Cards/QuoteAttributionCard";
import ImageCard from "../components/custom/UGC_Cards/ImageCard";
import { downloadsAssets } from "../components/common/DownloadsAssets.js";
import { assets } from "../components/common/images";
import JumboQuoteImageRight from "../components/custom/UGC_Cards/JumboQuoteImageRight";
import JumboQuoteImageLeft from "../components/custom/UGC_Cards/JumboQuoteImageLeft";
import JumboSmallQuoteImageRight from "../components/custom/UGC_Cards/JumboSmallQuoteImageRight";
import JumboSmallQuoteImageLeft from "../components/custom/UGC_Cards/JumboSmallQuoteImageLeft";
import BigQuoteImageRightCard from "../components/custom/UGC_Cards/BigQuoteImageRightCard";
import BigQuoteVideoRightCard from "../components/custom/UGC_Cards/BigQuoteVideoRightCard";
import StatementQuoteImageCard from "../components/custom/UGC_Cards/StatementQuoteImageCard";

//Download section items
export const homepageDownloadItems = [
  {
    title: "common.downloadPeopleSegment",
    subtitle: "common.downloadPeopleSegmentSubtitle",
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.peopleSegmentsProfilePDF,
      ppt: downloadsAssets.commonDownloads.peopleSegmentsProfilePPT,
    },
  },
  {
    title: "common.downloadOccasions",
    subtitle: "common.downloadOccasionsSubtitle",
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.occasionsProfilePDF,
      ppt: downloadsAssets.commonDownloads.occasionProfilePPT,
    },
  },
  {
    title: "common.downloadDemandSpace",
    subtitle: "common.downloadDemandSpaceSubtitle",
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.demandSpaceProfilesPDF,
      ppt: downloadsAssets.commonDownloads.demandSpaceProfilesPPT,
    },
  },
  {
    title: "common.downloadDataPack",
    subtitle: "common.downloadDataPackSubtitle",
    multipleFiles: false,
    fileToDownload: downloadsAssets.commonDownloads.DataPackSpreadsheet,
  },
];

//Demandspace Template Anchor Nav Items
export const DemandSpaceAnchorNavItems = [
  {
    key: "section2",
    text: "thePeople",
  },
  {
    key: "section3",
    text: "theOccasions",
  },
  {
    key: "section4",
    text: "contextualFactors",
  },
  {
    key: "section5",
    text: "driversandNeeds",
  },
  {
    key: "section6",
    text: "consumption",
  },
  {
    key: "section7",
    text: "wrapUp",
  },
  {
    key: "section8",
    text: "downloads",
  },
];

export const ComfortCraverAnchorNavItems = [
  {
    key: "section2",
    text: "whatDrivesThem",
  },
  {
    key: "section3",
    text: "meetThePeople",
  },
  {
    key: "section4",
    text: "demographics",
  },
  {
    key: "section5",
    text: "whatTheyDrink",
  },
  {
    key: "section6",
    text: "demandSpaces",
  },
  {
    key: "section7",
    text: "wrapUp",
  },
  {
    key: "section8",
    text: "downloads",
  },
];

export const demandspaceSectionWayfinderMapping = {
  //People segments
  Fram_PSGMT_2: ["Fram_PSGMT_2", "Fram_PSGMT_3", "Fram_DS_1", "Fram_DS_2"],
  Fram_PSGMT_3: ["Fram_PSGMT_2", "Fram_PSGMT_3", "Fram_DS_1", "Fram_DS_2"],
  Fram_PSGMT_5: [
    "Fram_PSGMT_5",
    "Fram_PSGMT_6",
    "Fram_DS_9",
    "Fram_DS_10",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_11",
  ],
  Fram_PSGMT_6: [
    "Fram_PSGMT_5",
    "Fram_PSGMT_6",
    "Fram_DS_9",
    "Fram_DS_10",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_11",
  ],
  Fram_PSGMT_8: [
    "Fram_PSGMT_8",
    "Fram_PSGMT_9",
    "Fram_DS_3",
    "Fram_DS_4",
    "Fram_DS_5",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_6",
  ],
  Fram_PSGMT_9: [
    "Fram_PSGMT_8",
    "Fram_PSGMT_9",
    "Fram_DS_3",
    "Fram_DS_4",
    "Fram_DS_5",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_6",
  ],
  Fram_PSGMT_11: [
    "Fram_PSGMT_11",
    "Fram_PSGMT_12",
    "Fram_DS_3",
    "Fram_DS_4",
    "Fram_DS_7",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_8",
  ],
  Fram_PSGMT_12: [
    "Fram_PSGMT_11",
    "Fram_PSGMT_12",
    "Fram_DS_3",
    "Fram_DS_4",
    "Fram_DS_7",
    "Fram_DS_12",
    "Fram_DS_13",
    "Fram_DS_8",
  ],

  //Occasions
  Fram_OCCAS_2: ["Fram_OCCAS_2", "Fram_DS_1", "Fram_DS_3", "Fram_DS_9"],
  Fram_OCCAS_3: ["Fram_OCCAS_3", "Fram_DS_1", "Fram_DS_4", "Fram_DS_9"],
  Fram_OCCAS_5: [
    "Fram_OCCAS_5",
    "Fram_DS_1",
    "Fram_DS_5",
    "Fram_DS_7",
    "Fram_DS_10",
  ],
  Fram_OCCAS_6: [
    "Fram_OCCAS_6",
    "Fram_DS_1",
    "Fram_DS_5",
    "Fram_DS_7",
    "Fram_DS_10",
  ],
  Fram_OCCAS_7: [
    "Fram_OCCAS_7",
    "Fram_DS_1",
    "Fram_DS_5",
    "Fram_DS_7",
    "Fram_DS_10",
  ],
  Fram_OCCAS_9: ["Fram_OCCAS_9", "Fram_DS_2", "Fram_DS_12"],
  Fram_OCCAS_10: ["Fram_OCCAS_10", "Fram_DS_2", "Fram_DS_13"],
  Fram_OCCAS_12: [
    "Fram_OCCAS_12",
    "Fram_DS_2",
    "Fram_DS_6",
    "Fram_DS_8",
    "Fram_DS_11",
  ],
  Fram_OCCAS_13: [
    "Fram_OCCAS_13",
    "Fram_DS_2",
    "Fram_DS_6",
    "Fram_DS_8",
    "Fram_DS_11",
  ],
  Fram_OCCAS_14: [
    "Fram_OCCAS_14",
    "Fram_DS_2",
    "Fram_DS_6",
    "Fram_DS_8",
    "Fram_DS_11",
  ],
};

export function getModularCards(theme, cardType, cardData) {
  let card = null;
  switch (cardType) {
    case "StatementCard":
      card = <StatementCard theme={theme} cardData={cardData} />;
      break;
    case "QuoteAttributionCard":
      card = <QuoteAttributionCard theme={theme} cardData={cardData} />;
      break;
    case "ImageCard":
      card = <ImageCard theme={theme} cardData={cardData} />;
      break;
    case "StatementImageCardRight":
      card = <JumboQuoteImageRight theme={theme} cardData={cardData} />;
      break;
    case "StatementImageCardLeft":
      card = <JumboQuoteImageLeft theme={theme} cardData={cardData} />;
      break;
    case "StatementQuoteImageCardRight":
      card = <JumboSmallQuoteImageRight theme={theme} dataprops={cardData} />;
      break;
    case "StatementQuoteImageCardLeft":
      card = <JumboSmallQuoteImageLeft theme={theme} dataprops={cardData} />;
      break;
    case "BigQuoteImageRightCard":
      card = <BigQuoteImageRightCard theme={theme} dataprops={cardData} />;
      break;
    case "BigQuoteVideoRightCard":
      card = <BigQuoteVideoRightCard theme={theme} dataprops={cardData} />;
      break;
    case "StatementQuoteImageCard":
      card = <StatementQuoteImageCard theme={theme} dataprops={cardData} />;
      break;
    default:
      break;
  }

  return card;
}

export function getTemplateColors(space) {
  let color = "";
  switch (space) {
    case "Fram_PSGMT_2": //Comfort Cravers
      color = "#B983FF";
      break;
    case "Fram_PSGMT_3": //Loyal Traditionalists
      color = "#ADA1F8";
      break;
    case "Fram_PSGMT_5": //Balanced Enjoyers
      color = "#ffc759";
      break;
    case "Fram_PSGMT_6": //Diligent Discoverers
      color = "#ff8811";
      break;
    case "Fram_PSGMT_8": //Carefree Fun-Lovers
      color = "#8FE8BD";
      break;
    case "Fram_PSGMT_9": //Proud Celebrators
      color = "#79D271";
      break;
    case "Fram_PSGMT_11": //Creative Explorers
      color = "#A1E8F8";
      break;
    case "Fram_PSGMT_12": //Social Curators
      color = "#83BCFF";
      break;
    default:
      color = "#F6E003";
      break;
  }
  return color;
}

export const HomeTooltipCardData = {
  page: "home",
  tooltipData: [
    {
      label: "full-screen-experience-img",
      imgPath: assets.home.fullScreenExperienceImg,
      title: "home.modal.fullScreenExperienceTitle",
      description: "home.modal.fullScreenExperienceDescription",
    },
    {
      label: "first-time-using-the-demand-landscape-img",
      imgPath: assets.home.firstTimeImg,
      title: "home.modal.firstTimeTitle",
      description: "home.modal.firstTimeDescription",
    }
  ],
};
