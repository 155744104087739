import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../reusable-button/ReusableButton';
import styles from './LandingHeader.module.css';
import PropTypes from 'prop-types';


export const LandingHeader = ({ 
  title, 
  introText, 
  imageSrc, 
  altText, 
  buttonPath, 
  buttonCopy 
}) => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate(buttonPath);
  }, [navigate, buttonPath]);

  return (
    <div className={styles.container}>
      <div className={styles.contentColumn}>
        <div className={styles.contentWrapper}>
          <div className={styles.textContent}>
            <div className={styles.titleContainer}>
              <div className={styles.introText}>
                {introText}
              </div>
              <h1 className={styles.mainTitle}>
                {title}
              </h1>
            </div>
          </div>
          <ActionButton onClick={handleButtonClick}>
            {buttonCopy}
          </ActionButton>
        </div>
      </div>
      <div className={styles.imageColumn}>
        <img
          loading="lazy"
          src={imageSrc}
          className={styles.categoryImage}
          alt={altText}
        />
      </div>
    </div>
  );
}

LandingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string.isRequired,
};
