import React from 'react';
import { BrandRow } from './BrandRow';
import styles from './BrandShare.module.css';
import PropTypes from 'prop-types';


export const BrandShare = ({ 
  brandShareData,
  brandShareDataColor
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.cardBackground} />
      <div className={styles.content}>
        <div className={styles.titleSection}>
          <h2 className={styles.title}>Top 5 Brands by $ Share</h2>
          <div className={styles.divider} />
        </div>
        <div className={styles.brandsList}>
          {brandShareData.map((brand) => (
            <BrandRow 
              key={brand.name} 
              {...brand} 
              brandShareDataColor={brandShareDataColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

BrandShare.propTypes = {
  brandShareData: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shareInfo: PropTypes.string.isRequired,
  })).isRequired,
  brandShareDataColor: PropTypes.string.isRequired,
};
