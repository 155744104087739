import React from 'react';
import styles from './PriceTiers.module.css';


const PRICE_TIERS_IMAGE_SRC = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F36a4c3469fec4ff99e2104a3cf9d7a70";

export const PriceTiers = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Within each category and segment there are a range of price tiers:
      </h1>
      <img 
        src={PRICE_TIERS_IMAGE_SRC} 
        alt="Price tiers table" 
        className={styles.priceTiersTable}
      />
    </div>
  );
};
