import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CarouselIndicator } from '../carousel-indicator/CarouselIndicator';
import { Button } from '../navigation-button/NavigationButton';
import { hueSections, sectionNavigationMap } from '../../../../constants/HueHubHomeConstants';
import styles from './CopyCarousel.module.css';
import PropTypes from 'prop-types';


export const CopyCarousel = ({
  eyebrowText,
  titleFirstLine,
  titleSecondLine,
  descriptionFirstParagraph,
  descriptionSecondParagraph,
  selectedSection,
  clickHandler
}) => {

  const navigate = useNavigate();

  const navigateToSection = useCallback((section) => {
    navigate(`${sectionNavigationMap[section].navigateTo}`);
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <div className={styles.eyebrow}>{eyebrowText}</div>
            <h1 className={styles.title}>
              {titleFirstLine} <br />
              {titleSecondLine}
            </h1>
          </div>
        </div>
        <p className={styles.description}>
          {descriptionFirstParagraph} <br />
          <br />
          {descriptionSecondParagraph}
        </p>
        {selectedSection !== 'aboutHue' && (
          <Button 
            onClick={() => navigateToSection(selectedSection)}
          >
            Go to {sectionNavigationMap[selectedSection].fullName}
          </Button>
        )}
        <div className={styles.indicators} role="tablist">
          {hueSections.map((section) => (
            <button 
              key={section}
              onClick={() => clickHandler(section)}
            >
              <CarouselIndicator 
                isActive={selectedSection === section} 
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

CopyCarousel.propTypes = {
  eyebrowText: PropTypes.string.isRequired,
  titleFirstLine: PropTypes.string.isRequired,
  titleSecondLine: PropTypes.string.isRequired,
  descriptionFirstParagraph: PropTypes.string.isRequired,
  descriptionSecondParagraph: PropTypes.string,
  selectedSection: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
