import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatBlock.module.css';

export const StatBlock = ({ 
  number, 
  description,
}) => {
  return (
    <div className={styles.statBlock} data-testid="stat-block">
      <div className={styles.statNumber}>{number}</div>
      <div className={styles.statDescription}>{description}</div>
    </div>
  );
};

StatBlock.propTypes = {
  number: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};