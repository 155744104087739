import React from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryStats.module.css';


export const CategoryStats = ({
  headlineText,
  amount,
  chartImageSrc,
  chartImageAlt,
  sourceText,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.statContent}>
        <div className={styles.headline}>
          <span className={styles.headlineText}>
            {headlineText}
          </span>
          <div className={styles.amount}>{amount}</div>
        </div>
        <div className={styles.chartSection}>
          <div className={styles.chartColumn}>
            <img
              loading="lazy"
              src={chartImageSrc}
              className={styles.chartImage}
              alt={chartImageAlt}
            />
            <div className={styles.source}>
              {sourceText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CategoryStats.propTypes = {
  headlineText: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  chartImageSrc: PropTypes.string.isRequired,
  chartImageAlt: PropTypes.string.isRequired,
  sourceText: PropTypes.string.isRequired,
};
