import React from "react";

import { Grid, Box, Typography, Divider } from "@mui/material";

import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";

/*
    Props:
        content: Object
        {
            micro, //selected micro name
            sov, // share of alcohol volume for the micro
            sop, // share of population of the micro
            microDescription, // Description of the selected micro
            keywords: Array['String'] // keywords for the selected micro
            microImage, // Hero image of the selected micro
            visualText // Visual text of the selected micro
        }
*/

function WrapUpHero({ content }) {
  const { t } = useTranslation(i18next.language);
  return (
    <Grid container className="wrapup-grid">
      <Grid item className="wrapup-left-item" xs={5}>
        <Box className="wrapup-section-title-container">
          <Box className="section-title">
            <Box className="title">
              <Typography
                className="micro-name"
                variant="body2"
                color="primary"
              >
                {content.micro}
              </Typography>
              <Typography
                className="section-heading"
                variant="h2"
                color="secondary"
              >
                {content.title}
              </Typography>
            </Box>
            <Box className="micro-attributes">
              <Typography className="micro-sov" variant="body1">
                <Trans components={{ colored: <i />, bold: <u /> }}>
                  {t("common.wrapup.totalAlcoholVolume", { sov: content.sov })}
                </Trans>
              </Typography>
              <Divider orientation="vertical" className="vertical-line" />
              {/* <Typography className="micro-sop" variant="body1">
                <Trans components={{ colored: <i />, bold: <u/> }}>
                  {t("common.wrapup.totalPopulation", { sop: content.sop })}
                </Trans>
              </Typography> */}
              {(content.microID && (content.microID.includes('Fram_DS') ||
                content.microID.includes('Fram_OCCAS'))) ?
                (<Typography className="micro-sop" variant="body1">
                  <Trans components={{ colored: <i />, bold: <u /> }}>
                    {t("common.wrapup.totalIncidence", { sop: content.sop })}
                  </Trans>
                </Typography>) :
                (
                  <Typography className="micro-sop" variant="body1">
                    <Trans components={{ colored: <i />, bold: <u /> }}>
                      {t("common.wrapup.totalPopulation", { sop: content.sop })}
                    </Trans>
                  </Typography>
                )
              }
            </Box>
          </Box>
          <Box className="section-description">
            <Typography
              className="micro-description"
              variant="body"
              color="secondary"
            >
              <Trans components={{ colored: <i /> }}>
                {content.microDescription}
              </Trans>
            </Typography>
            {content.keywords && (
              <Box className="micro-keywords-container">
                {content.keywords.map((keyword, index) => (
                  <Typography
                    component="div"
                    variant="body2"
                    className="micro-keyword"
                    key={`micro-keyword-${index + 1}`}
                  >
                    {keyword}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item className="wrapup-right-item" xs={6}>
        <Box
          className="wrapup-visual-container"
          sx={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 76.56%), url(${content.microImage}), lightgray 50% / cover no-repeat`,
          }}
        >
          <Typography className="visual-text" variant="h5" color="secondary">
            <Trans
              components={{
                coloredDynamic: <i />,
                coloredgreen: <b />,
                coloredyellow: <strong />,
                coloredorange: <span className="colored-orange" />,
                coloredpurple: <u />,
                coloredblue: <span className="colored-blue" />,
                colored: <strong />,
                coloredOutgoing: <span className="colored-outgoing " />
              }}
            >
              {content.visualText}
            </Trans>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WrapUpHero;
