import React from 'react';
import { StatBlock } from './compound-components/StatBlock';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './ShoppingTripSection.module.css';

export const ShoppingTripSection = () => {
  return (
    <Card height="400px">
      <div className={styles.statsCard} data-testid="shopping-trip-section-container">
        <div className={styles.statsTitle}>
          This is an <span className={styles.highlightedText}>alcohol-specific</span> trip framework
        </div>
        <div className={styles.statsContent}>
          <StatBlock 
            number="1 in 10"
            description="shopping trips is an alcohol trip"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/b9ce9be91114118fa068020aec52ca461af2c039c6658a3745fcffa311edc64b"
            className={styles.statsImage}
            alt="Alcohol shopping statistics visualization"
          />
        </div>
        <div className={styles.sourceText}>
          SOURCE: Numerator, Total Store Aug '23- Aug'24 Includes trips in Food,
          Mass & Club, Liquor, Gas & Convenience, Online, Dollar, Drug, &
          Military
        </div>
      </div>
    </Card>
  );
};