import React from 'react';
import styles from './Tab.module.css';
import PropTypes from 'prop-types';


export const Tab = ({ isActive, children, onClick }) => {
  return (
    <button
      className={isActive ? styles.activeTab : styles.inactiveTab}
      onClick={onClick}
      role="tab"
      aria-selected={isActive}
      tabIndex={0}
    >
      {children}
    </button>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
