import React from 'react';
import { MetricCard } from './MetricCard';
import styles from './GrowthMetrics.module.css';
import PropTypes from 'prop-types';


export const GrowthMetrics = ({ 
  growthMetrics, 
  subcatAccentColor,
  onAccentTextColor
}) => {
  return (
    <div className={styles.metricsContainer}>
      {growthMetrics.map((metric, index) => (
        <MetricCard 
          key={metric.label}
          value={metric.value}
          label={metric.label}
          accentColor={subcatAccentColor}
          accentTextColor={onAccentTextColor}
        />
      ))}
    </div>
  );
};

GrowthMetrics.propTypes = {
  growthMetrics: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
};
