import React from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryCard.module.css';

export const CategoryCard = ({ icon, title, backgroundColor }) => {
  return (
    <div className={styles.categoryCard} style={{ backgroundColor }}>
      <div className={styles.cardBackground} />
      <img loading="lazy" src={icon} alt={`${title} category icon`} className={styles.categoryIcon} />
      <div className={styles.categoryTitle}>{title}</div>
    </div>
  );
};

CategoryCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};
