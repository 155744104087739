import React from 'react';
import PropTypes from 'prop-types';
import styles from './SegmentCard.module.css';

export const SegmentCard = ({ title, category }) => {
  return (
    <div className={`${styles.segmentCard} ${styles[category]}`}>
      <div className={styles.cardBackground} />
      <div className={styles.segmentTitle}>{title}</div>
    </div>
  );
};

SegmentCard.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};
