import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionHeader.module.css';

export function SectionHeader({ 
  sectionName, 
  sectionTitle,
  accentColor,
  sectionNameColor,
  alignment, 
  titleSize, 
  'data-testid': testId 
}) {
  return (
    <header className={styles.header} data-testid={testId}>
      <div className={`${styles.growthContainer} ${styles[alignment]}`}>
        <div className={styles.textContainer}>
          <div className={styles.accentLine} style={{ borderColor: accentColor, backgroundColor: accentColor }} />
          <h2 className={styles.subtitle} style={{ color: sectionNameColor }}>{sectionName}</h2>
          <h1 className={`${styles.title} ${styles[titleSize]}`}>{sectionTitle}</h1>
        </div>
      </div>
    </header>
  );
}

SectionHeader.propTypes = {
  sectionName: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
  sectionNameColor: PropTypes.string.isRequired,
  alignment: PropTypes.string,
  titleSize: PropTypes.string,
  'data-testid': PropTypes.string,
};
