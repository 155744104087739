import { Builder } from '@builder.io/react';
import { AdditionalCategories } from '../alcohol-framework/components/additional-categories/AdditionalCategories';
import { AlcFrameworkHeader } from '../alcohol-framework/components/alc-framework-header/AlcFrameworkHeader';
import { CategoryExplorer } from '../alcohol-framework/components/category-explorer/CategoryExplorer';
import { InstructionsCard } from '../alcohol-framework/components/instruction-card/InstructionsCard';
import { AboutSection } from '../alcohol-subcategory/components/about/AboutSection';
import { AssociationCardSection } from '../alcohol-subcategory/components/associations/AssociationCardSection';
import { FlavorExpectations } from '../alcohol-subcategory/components/flavor-expectations/FlavorExpectations';
import { SubcategoryHeader } from '../alcohol-subcategory/components/header/SubcategoryHeader';
import { PricingTable } from '../alcohol-subcategory/components/price-tier/PricingTable';
import { SizingSection } from '../alcohol-subcategory/components/sizing/SizingSection';
import { SubcatQuotes } from '../alcohol-subcategory/components/testimonials/SubcatQuotes';
import { WrapUpSection } from '../alcohol-subcategory/components/wrap-up/WrapUpSection';
import { CategoryStats } from '../category-hub/components/category-size-stats/CategoryStats';
import { TopBrandTable } from '../category-hub/components/category-top-brands-tables/TopBrandTable';
import { SegmentExplorer } from '../category-hub/components/dig-deeper/SegmentExplorer';
import { CategoryHubHeader } from '../category-hub/components/header/CategoryHubHeader';
import { CategoryMethod } from '../category-hub/components/methodology/CategoryMethod';
import { SegmentDisplay } from '../category-hub/components/segment-legend/SegmentDisplay';


Builder.registerComponent(AdditionalCategories, {
  name: 'AdditionalCategories',
  inputs: [],
});

Builder.registerComponent(AlcFrameworkHeader, {
  name: 'AlcFrameworkHeader',
  inputs: [],
});

Builder.registerComponent(CategoryExplorer, {
  name: 'CategoryExplorer',
  inputs: [],
});

Builder.registerComponent(InstructionsCard, {
  name: 'InstructionsCard',
  inputs: [],
});

Builder.registerComponent(AboutSection, {
  name: 'AboutSection',
  inputs: [
    {
      name: 'aboutTitle',
      type: 'string',
      helperText: 'Enter the title for the About section'
    },
    {
      name: 'aboutTitleColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the title text'
    },
    {
      name: 'aboutText',
      type: 'longText',
      helperText: 'Enter the main text for the About section'
    },
    {
      name: 'guidelines',
      type: 'longText',
      helperText: 'Enter the guidelines text'
    },
    {
      name: 'subcatCansImg',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the subcategory cans'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the subcategory cans image'
        }
      ],
      helperText: 'Define the image and alt text for subcategory cans'
    }
  ],
});

Builder.registerComponent(AssociationCardSection, {
  name: 'AssociationCardSection',
  inputs: [
    {
      name: 'cardData',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'text',
          helperText: 'Use \\n for line breaks in card text'
        }
      ],
      helperText: 'Data for each association card entry with custom line breaks'
    },
    {
      name: 'sectionName',
      type: 'string',
      helperText: 'Enter the name of the section'
    },
    {
      name: 'sectionTitle',
      type: 'string',
      helperText: 'Enter the title of the section'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Key for the accent color of the subcategory'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Key for the text color on the accent'
    },
    {
      name: 'sectionNameColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Key for the color of the section name text'
    }
  ]
});

Builder.registerComponent(FlavorExpectations, {
  name: 'FlavorExpectations',
  inputs: [
    {
      name: 'flavorProfile',
      type: 'string',
      helperText: 'Enter the flavor profile'
    },
    {
      name: 'descriptionStart',
      type: 'string',
      helperText: 'Enter the beginning part of the description'
    },
    {
      name: 'descriptionHighlight',
      type: 'string',
      helperText: 'Enter the text to be highlighted in the description'
    },
    {
      name: 'descriptionEnd',
      type: 'string',
      helperText: 'Enter the concluding part of the description'
    },
    {
      name: 'highlightColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the highlighted text'
    },
    {
      name: 'flavorSliderImg',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the flavor slider'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the flavor slider image'
        }
      ],
      helperText: 'Upload an image for the flavor slider'
    }
  ],
});

Builder.registerComponent(SubcategoryHeader, {
  name: 'SubcategoryHeader',
  inputs: [
    {
      name: 'heroImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the hero image for the subcategory'
    },
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title of the subcategory'
    },
    {
      name: 'stat1',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          defaultValue: 'X%',
          helperText: 'Enter the statistic value for the first header stat'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Share of <CAT>',
          helperText: 'Enter the label for the first header stat'
        }
      ],
      helperText: 'Enter the first statistic with value and label'
    },
    {
      name: 'stat2',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          defaultValue: '$X.XB',
          helperText: 'Enter the statistic value for the second header stat'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Total Size',
          helperText: 'Enter the label for the second header stat'
        }
      ],
      helperText: 'Enter the second statistic with value and label'
    },
    {
      name: 'statColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Select a color category for the stats background'
    },
    {
      name: 'statTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color for the stat text'
    },
    {
      name: 'fontSizeOverride',
      type: 'string',
      helperText: 'Enter the font size override for the title (in px, exp: 94px)'
    }
  ]
});

Builder.registerComponent(PricingTable, {
  name: 'PricingTable',
  inputs: [
    {
      name: 'pricingTableImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for pricing table'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for pricing table image'
        }
      ],
      helperText: 'Upload an image for the pricing table'
    }
  ],
});

Builder.registerComponent(SizingSection, {
  name: 'SizingSection',
  inputs: [
    {
      name: 'sectionName',
      type: 'string',
      helperText: 'Enter the name for the section'
    },
    {
      name: 'sectionTitle',
      type: 'string',
      helperText: 'Enter the title for the section'
    },
    {
      name: 'chartTitle',
      type: 'string',
      helperText: 'Enter the title for the chart'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the subcategory accent'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color key for the text on the accent'
    },
    {
      name: 'sectionNameColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the section name text'
    },
    {
      name: 'brandShareDataColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color for the brand share data in the top 5 brands table'
    },
    {
      name: 'chartImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the chart'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the chart image'
        }
      ],
      helperText: 'Upload an image for the chart'
    },
    {
      name: 'growthMetrics',
      type: 'list',
      subFields: [
        {
          name: 'value',
          type: 'string',
          helperText: 'Enter the growth metric value'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the growth metric'
        }
      ],
      helperText: 'Enter growth metrics with values and labels'
    },
    {
      name: 'brandShareData',
      type: 'list',
      subFields: [
        {
          name: 'imageUrl',
          type: 'file',
          helperText: 'Upload an image for the brand',
          allowedFileTypes: ['jpeg', 'jpg', 'png']
        },
        {
          name: 'name',
          type: 'string',
          helperText: 'Enter the brand name'
        },
        {
          name: 'shareInfo',
          type: 'string',
          helperText: 'Enter share information'
        }
      ],
      helperText: 'Enter the brand share data with images, names, and information'
    },
    {
      name: 'headerAlignment',
      type: 'string',
      enum: ['left', 'center'],
      defaultValue: 'left',
      helperText: 'Horizontal alignment for the header'
    }
  ],
});

Builder.registerComponent(SubcatQuotes, {
  name: 'SubcatQuotes',
  inputs: [
    {
      name: 'quotesData',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'string',
          helperText: 'Enter the title for the quotes section'
        },
        {
          name: 'subtitle',
          type: 'string',
          helperText: 'Enter the subtitle for the quotes section'
        },
        {
          name: 'quotes',
          type: 'list',
          subFields: [
            {
              name: 'variant',
              type: 'string',
              enum: ['colored', 'transparent'],
              helperText: 'Select the variant color for the quote card'
            },
            {
              name: 'width',
              type: 'string',
              enum: ['narrow', 'wide'],
              helperText: 'Select the width for the quote card'
            },
            {
              name: 'quote',
              type: 'longText',
              helperText: 'Enter the quote text'
            }
          ],
          helperText: 'Add quotes with detailed attributes'
        }
      ],
      helperText: 'Add all quote data'
    },
    {
      name: 'subcatAccentColor',
      friendlyName: 'Colored Card Background',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      helperText: 'Enter the color key for the subcategory accent'
    },
    {
      name: 'onAccentTextColor',
      friendlyName: 'Colored Card Text Color',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],
      helperText: 'Enter the color key for the text on the accent'
    },
    {
      name: 'subTextColor',
      friendlyName: 'Gray Card Text Color',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],      
      helperText: 'Enter the color key for the sub text'
    },
    {
      name: 'quoteIcon2',
      friendlyName: 'Colored CardQuote Icon',
      type: 'string',
      enum: [
        'WHITE',
        'BLACK',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE'
      ],
      helperText: 'Enter the key for the second quote icon'
    },
    {
      name: 'quoteIcon1',
      friendlyName: 'Gray Card Quote Icon',
      type: 'string',
      enum: [
        'WHITE',
        'BLACK',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE'
      ],
      helperText: 'Enter the key for the first quote icon'
    }
  ],
});

Builder.registerComponent(WrapUpSection, {
  name: 'WrapUpSection',
  inputs: [
    {
      name: 'wrapUpHeaderSubcat',
      type: 'string',
      helperText: 'Enter the header subcategory text'
    },
    {
      name: 'wrapUpStat1',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          helperText: 'Enter the first statistic'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the first statistic'
        }
      ],
      helperText: 'Enter the first wrap-up statistic'
    },
    {
      name: 'wrapUpStat2',
      type: 'object',
      subFields: [
        {
          name: 'stat',
          type: 'string',
          helperText: 'Enter the second statistic'
        },
        {
          name: 'label',
          type: 'string',
          helperText: 'Enter the label for the second statistic'
        }
      ],
      helperText: 'Enter the second wrap-up statistic'
    },
    {
      name: 'wrapUpDescription',
      type: 'object',
      subFields: [
        {
          name: 'highlightSubcat',
          type: 'string',
          helperText: 'Enter the highlighted subcategory text'
        },
        {
          name: 'description',
          type: 'longText',
          helperText: 'Enter the description text'
        }
      ],
      helperText: 'Enter the wrap-up description'
    },
    {
      name: 'wrapUpCardsText',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
          helperText: 'Enter text for the card'
        }
      ],
      helperText: 'Enter the texts for wrap-up cards'
    },
    {
      name: 'wrapUpProductImage',
      type: 'object',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload the image for the product'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the product image'
        }
      ],
      helperText: 'Enter the product image details'
    },
    {
      name: 'subcatAccentColor',
      type: 'string',
      enum: [
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],      
      helperText: 'Select the accent color for the subcategory'
    },
    {
      name: 'onAccentTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'TEXT_BLACK',
      ],      
      helperText: 'Select the text color that appears on accent color'
    },
    {
      name: 'highlightTextColor',
      type: 'string',
      enum: [
        'TEXT_WHITE',
        'NON_ALC_YELLOW',
        'MALT_ICE_YELLOW',
        'AM_CLASSIC_ORANGE',
        'AM_LIGHT_ORANGE',
        'AM_BALANCE_ORANGE',
        'IMPORTS_ORANGE',
        'ELEVEATED_ORANGE',
        'IPA_DRK_ORANGE',
        'CRAFT_OTHER_RED',
        'FLAVORED_DRK_RED',
        'SELTZER_SPIRIT_PURPLE',
        'SELTZER_MALT_PURPLE',
        'HARD_CIDER_PURPLE',
        'FMWBS_LOW_PURPLE',
        'FMWBS_HIGH_PURPLE',
        'SPIRIT_COCKTAILS_PURPLE',
        'MULTI_SERVE_PURPLE',
      ],
      defaultValue: 'TEXT_WHITE',
      helperText: 'Select the highlight text color'
    }
  ],
});

Builder.registerComponent(CategoryStats, {
  name: 'CategoryStats',
  inputs: [
    {
      name: 'headlineText',
      type: 'string',
      helperText: 'Enter the headline text for the category stats'
    },
    {
      name: 'amount',
      type: 'string',
      helperText: 'Enter the amount to display as the category size'
    },
    {
      name: 'chartImageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the chart image for category market share'
    },
    {
      name: 'chartImageAlt',
      type: 'string',
      defaultValue: '<CATEGORY> category market share donut chart',
      helperText: 'Enter the alt text for the chart image'
    },
    {
      name: 'sourceText',
      type: 'string',
      defaultValue: 'Source: IWSR 2023 FY Share of Dollars',
      helperText: 'Enter the source text for the category stats'
    }
  ],
});

Builder.registerComponent(TopBrandTable, {
  name: 'TopBrandTable',
  inputs: [
    {
      name: 'categoryStructure',
      type: 'richText',
      helperText: 'Enter the category structure text or HTML'
    },
    {
      name: 'topBrandsTable',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the image for the top brands table'
    },
    {
      name: 'topBrandsTableAltText',
      type: 'string',
      helperText: 'Enter the alt text for the top brands table image'
    },
    {
      name: 'sizeGrowthTable',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      helperText: 'Upload the image for the size growth table'
    },
    {
      name: 'sizeGrowthTableAltText',
      type: 'string',
      helperText: 'Enter the alt text for the size growth table image'
    }
  ],
});

Builder.registerComponent(SegmentExplorer, {
  name: 'SegmentExplorer',
  inputs: [
    {
      name: 'segmentCards',
      type: 'list',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload an image for the segment card'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter alt text for the segment card image'
        },
        {
          name: 'navigateTo',
          type: 'string',
          helperText: 'Enter the URL to navigate to when the card is clicked'
        }
      ],
      helperText: 'Define cards with images, alt texts, and navigation targets'
    }
  ],
});

Builder.registerComponent(CategoryHubHeader, {
  name: 'CategoryHubHeader',
  inputs: [
    {
      name: 'backgroundImageUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb647a3eb2a804215886b950080ac1d5a",
      helperText: 'Upload a background image for the header'
    },
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title for the header'
    },
    {
      name: 'descriptionStart',
      type: 'string',
      helperText: 'Enter the initial part of the description'
    },
    {
      name: 'highlightedText',
      type: 'string',
      helperText: 'Enter the text to be highlighted in the description'
    },
    {
      name: 'descriptionEnd',
      type: 'string',
      helperText: 'Enter the concluding part of the description'
    }
  ],
});

Builder.registerComponent(CategoryMethod, {
  name: 'CategoryMethod',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Using Data to Define Key <CATEGORY> Segments',
      helperText: 'Enter the title for the method section'
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'We utilized shopper and consumer data to understand how the <CATEGORY> Category is actually segmented.',
      helperText: 'Enter the description text for the method section'
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/024dd9b7ba639746e7e18081636842d3b158c6ce2c8b95cb72a05309f85c866a',
      helperText: 'Upload an image for the category method visualization'
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue: 'Category segmentation visualization',
      helperText: 'Enter the alt text for the image'
    }
  ],
});

Builder.registerComponent(SegmentDisplay, {
  name: 'SegmentDisplay',
  inputs: [
    {
      name: 'title',
      type: 'string',
      helperText: 'Enter the title text for the segment display section'
    },
    {
      name: 'segmentCount',
      type: 'string',
      helperText: 'Enter the text displaying the count of segments'
    },
    {
      name: 'segmentCards',
      type: 'list',
      subFields: [
        {
          name: 'imgUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          helperText: 'Upload an image for the segment card'
        },
        {
          name: 'altText',
          type: 'string',
          helperText: 'Enter the alt text for the segment card image'
        },
        {
          name: 'navigateTo',
          type: 'string',
          helperText: 'Enter the URL to navigate to when the card is clicked'
        }
      ],
      helperText: 'Add individual segment cards with image, alt text, and navigation targets'
    }
  ],
});