import React from "react";
import styles from "./HueHubVisualization.module.css";
import PropTypes from 'prop-types';

const DEMAND_LANDSCAPE_IMAGE = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3a6f0467f6cc4fe39636993e35f6d62c";
const CATEGORY_STRUCTURE_IMAGE = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F77111551e4dd48cd9465159f1baf212b";
const TRIP_TYPES_IMAGE = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F398d5a97e50a48ad8fb287b6ddeb065a";

export const HueHubVisualization = ({ clickHandler }) => {
  return (
    <div className={styles.donutWrapper}>
      <button className={styles.donutLeft} onClick={() => clickHandler('demandLandscape')}>
        <img 
          src={DEMAND_LANDSCAPE_IMAGE} 
          alt="HUE Hub Visualization"
        />
      </button>
      <button className={styles.donutCenter} onClick={() => clickHandler('categoryStructure')}>
        <img 
          src={CATEGORY_STRUCTURE_IMAGE} 
          alt="HUE Hub Visualization"
        />
      </button>
      <button className={styles.donutRight} onClick={() => clickHandler('tripTypes')}>
        <img 
          src={TRIP_TYPES_IMAGE} 
          alt="HUE Hub Visualization"
        />
      </button>
    </div>
  );
};

HueHubVisualization.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};
