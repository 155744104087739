import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.css';


export const Card = ({ children, height }) => {
  return (
    <div className={styles.content} style={{ height: height }}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string
};
