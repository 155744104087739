import { datadogRum } from '@datadog/browser-rum';

export function initDataDog() {
    const enableDD = window.env.REACT_APP_DATADOG_RUM_ENABLE;

    if (enableDD == 'true') {
        console.log('Enabled RUM');
        const datadogConfig = {
            applicationId: window.env.REACT_APP_DATADOG_APPLICATION_ID || "",
            clientToken: window.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
            site: window.env.REACT_APP_DATADOG_SITE_NAME,
            service: window.env.REACT_APP_DATADOG_SERVICE_NAME || "",
            env: window.env.REACT_APP_DATADOG_ENV || "",
            version: "1.0.0",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            trackSessionAcrossSubdomains: true,
            useSecureSessionCookie: true,
        }
        datadogRum.init(datadogConfig);

        const userEmail = localStorage.getItem('user_email') || '';
        const userName = localStorage.getItem('user_name') || '';

        if (userEmail && userName) {
            datadogRum.setUser({
                email: userEmail,
                name: userName,
            });
        } else {
            console.log('User email or name is missing or empty.');
        }
    }
}