import React from 'react';
import PropTypes from 'prop-types';
import styles from './CarouselIndicator.module.css';

export const CarouselIndicator = ({ isActive }) => {
  return (
    <div 
      className={`${styles.indicator} ${isActive ? styles.active : styles.inactive}`}
      role="tab"
      aria-selected={isActive}
      tabIndex={0}
    />
  );
};

CarouselIndicator.propTypes = {
  isActive: PropTypes.bool.isRequired
};
