/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import Runner from "../custom/Runner";
import SectionFive from "./SectionFive";
import i18next from "i18next";

function HomePage() {

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);
  
  return (
    <div className="main-content home-page">
      <div className="content">
        <SectionOne />
      </div>
      <SectionTwo />
      {/* <div className="content"> */}
      <SectionThree />
      <SectionFour />
      <SectionFive />
      {/* </div> */}
      <div className="runner">
        <Runner forPage={"homepage"} />
      </div>
    </div>
  );
}

export default HomePage;