import React from 'react';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './AlcoholSubcategories.module.css';


const ALCOHOL_SUBCATEGORIES_IMAGE_SRC = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fce829d71618d47f182f3ce744f3cefed";

export const AlcoholSubcategories = () => {
  return (
    <div className={styles.container}>
      <Card data-testid="research-section-container" height="600px">
        <h1 className={styles.heading}>
          Each of these categories also has segments:
        </h1>
        <img 
          src={ALCOHOL_SUBCATEGORIES_IMAGE_SRC} 
          alt="Alcohol subcategories chart" 
          className={styles.alcoholSubcategoriesChart}
        />
      </Card>
    </div>
  );
};
