import React from 'react';
import styles from './CategoryBars.module.css';

export default function CategoryBars() {
  return (
    <div className={styles.container}>
      <div className={`${styles.barSection} ${styles.barNarrow}`}>
        <div className={styles.sectionBar}>Alcohol Focused</div>
        <div className={styles.scale}>
          <div className={styles.text}>
            <div>Immediate Needs</div>
            <div>Stock Up</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8eaab61204cb4c0a99bc561191bec8b7"
            className={styles.scaleImage}
            alt="Scale visualization"
          />
        </div>
      </div>
      <div className={`${styles.barSection} ${styles.barWide}`}>
        <div className={styles.sectionBar}>Alcohol & more</div>
        <div className={styles.scale}>
          <div className={styles.text}>
            <div>Immediate Needs</div>
            <div>Stock Up</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/49bbc3b4f6e9fcf8e0596cac070ac622fdaf9b1e71c81c04d8c5bd3c3cff99b2"
            className={styles.scaleImage}
            alt="Scale visualization"
          />
        </div>
      </div>
    </div>
  );
}
