export const tripData = [
  {
    id: 1,
    title: "Alcohol grab & go",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/3d649e82d3bc170647acafe6dc76353bf9b3b49a9a939805f52a6d2fc6c8b3a9",
    color: "rgba(255, 149, 33, 1)",
    trips: "29%",
    spend: "33%",
    description: {
      prefix: "An",
      highlight: "in-and-out trip to pick up a single alcohol ",
      suffix: "category to unwind or share with friends."
    },
    navigateTo: "/trip-types/alcohol-grab-go"
  },
  {
    id: 2,
    title: "Alcohol reload",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/348cfa571566b24c64f22217da667e37aff2f06939946b08fe59af85556e1ca1",
    color: "rgba(3, 195, 190, 1)",
    trips: "9%",
    spend: "18%",
    description: {
      prefix: "An",
      highlight: "exploratory trip to pick up multiple alcohol categories ",
      suffix: "to restock the home bar or prepare for a party."
    },
    navigateTo: "/trip-types/alcohol-reload"
  },
  {
    id: 3,
    title: "pit stop essentials",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/ce0dd526f87e801a344f3dbba4224290850a4cd547728f02b6117334a236cda9",
    color: "rgba(6, 111, 192, 1)", 
    trips: "26%",
    spend: "17%",
    description: {
      prefix: "An",
      highlight: "in-and-out trip to grab alcohol ",
      suffix: "typically for immediate consumption, and gas, tobacco, or snacks."
    },
    navigateTo: "/trip-types/pit-stop-essentials"
  },
  {
    id: 4,
    title: "today's meal+",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/1fc368366db2e17daafa8a34a22d1fd58fbbb77e72ab60de60f5a00c17459ba9",
    color: "rgba(82, 168, 75, 1)",
    trips: "16%",
    spend: "12%",
    description: {
      prefix: "A",
      highlight: "moderate-length trip to get ingredients or prepared food ",
      suffix: "for an upcoming meal, with alcohol brought into the basket as an addition."
    },
    navigateTo: "/trip-types/todays-meal-plus"
  },
  {
    id: 5,
    title: "frequent fill-in",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/c48e051a98e0cb3571249c62c6401015ad6d6fa9f9394b7ff30f6d081d839d4f",
    color: "rgba(250, 76, 75, 1)",
    trips: "17%",
    spend: "16%",
    description: {
      prefix: "A",
      highlight: "longer trip to get select food and beverage items, ",
      suffix: "to avoid running out of staples or prepare for upcoming events."
    },
    navigateTo: "/trip-types/frequent-fill-in"
  },
  {
    id: 6,
    title: "household stock-up",
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/4bceaf30f18bedda160f3b8d8e67785d6d8b1cbe1a8510c5ddaaa2cac171060e",
    color: "rgba(164, 70, 197, 1)",
    trips: "3%",
    spend: "3%",
    description: {
      prefix: "A",
      highlight: "longer trip to fully restock common household supplies, ",
      suffix: "pantry items, food, and beverages."
    },
    navigateTo: "/trip-types/household-stock-up"
  }
];