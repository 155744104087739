export const researchData = [
  {
    id: 1,
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/e199d0a1ec08e52f47b5aebd37e5a00f9f9be072e698581421bfc435bf40d048",
    title: "Measure",
    number: "Over 30,000",
    description: "shoppers surveyed and trip receipts collected"
  },
  {
    id: 2,
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/3e62e26f06eb341a3119ba9f69fca0deaabc49b1627d17c9e95a09d92bde1a2b",
    title: "Analyze",
    number: "Synthesize",
    description: "trip purpose, total basket purchases, store & alcohol choice themes, & more"
  },
  {
    id: 3,
    image: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/a360168c16a9de11d2afbae0f544fac64b2995b61d425dbb918e3331f516fd46",
    title: "articulate",
    number: "Over 250",
    description: "qualitative interviews deliver the voice of the shopper"
  }
];