import React from 'react';
import { IconCard } from './compound-components/IconCard';
import { SectionHeader } from './compound-components/SectionHeader';
import { strategyIcons, activationIcons, retailIcons } from './data/iconData';
import { ColorGradient } from '../../../cat-trip-reuse-components/color-gradient/ColorGradient';
import styles from './CommercialTeams.module.css';


export const CommercialTeams = () => {

  return (
    <div className={styles.container} data-testid="commercial-teams-container">
      <ColorGradient data-testid="color-gradient" />
      <div className={styles.contentWrao}>
        <div className={styles.heading}>
          The alcohol trip type will be used{" "}
          <span className={styles.highlight}>across commercial teams </span>to develop{" "}
          <span className={styles.highlight}>shopper & channel strategies</span>
        </div>
        <div className={styles.content}>
          <div className={styles.sections}>
            <div className={styles.strategySection}>
              <SectionHeader title="Strategy" />
            </div>
            <div className={styles.activationSection}>
              <SectionHeader title="Activation" />
            </div>
            <div className={styles.retailSection}>
              <SectionHeader title="Retail" />
            </div>
          </div>
          <div className={styles.iconContainer}>
            <div className={styles.iconWrapper}>
              <div className={styles.iconGrid}>
                {strategyIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
                {activationIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
                {retailIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};