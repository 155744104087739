export const CatTripNavOptions = {
  homeHeaderOptions: [
    {
      title: "Alcohol Categories",
      segment: "alcohol-categories",
      path: "/categories",
      type: "drawer",
    },
    {
      title: "Trip Types",
      segment: "trip-types",
      path: "/trip-types",
      type: "drawer",
    },
  ],
  footerOptions: [
    {
      title: "Updates",
      segment: "updates",
      path: "/updates",
      type: "nav",
    },
    {
      title: "Privacy Policy",
      segment: "privacy",
      path: "/privacy",
      type: "nav",
    },
    {
      title: "Terms & Conditions",
      segment: "terms",
      path: "/terms",
      type: "nav",
    },
  ],
};
