import React from 'react';
import { CategoryCard } from './compound-components/CategoryCard';
import styles from './AdditionalCategories.module.css';


const spiritsData = {
  title: "Spirits",
  icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/e0167142e97eaeca005fc1787196e05abcdec3cb2393b28a71f9951b2c094ec6",
  categories: ["Gin", "Vodka", "Tequila", "Whiskey", "Rum", "Brandy", "Cordials"]
};

const wineData = {
  title: "Wine",
  icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/51c97be1e32a3b6d086b275c0c9ab16cab13301a0f2d3b0b0416df2964562159",
  categories: ["Sparkling", "Rose", "White Still", "Flavored / Fruity Wine", "Red Still"]
};

export const AdditionalCategories = () => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.categoryContainer}>
        <div className={styles.headerSection}>
          <h2 className={styles.mainTitle}>additional categories & Segments</h2>
          <p className={styles.description}>
            In addition to the beer and hard beverage categories, we also
            include two additional categories for wine and spirits.
          </p>
        </div>
        <div className={styles.cardsSection}>
          <CategoryCard 
            title={spiritsData.title}
            icon={spiritsData.icon}
            categories={spiritsData.categories}
            variant="blue"
          />
          <CategoryCard 
            title={wineData.title}
            icon={wineData.icon}
            categories={wineData.categories}
            variant="maroon"
          />
        </div>
      </div>
    </div>
  );
}
