import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
  Button
} from "@mui/material";
import OpenInNew from "@mui/icons-material/OpenInNew";

import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { assets } from "./images";
import Runner from "../custom/Runner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { helpQna } from "../../constants/HelpConstants";
import ToolTipPopUp from "../custom/Modal/ToolTipPopUp";
import { helpTooltipPopup } from "../../constants/HelpConstants";

function Help() {
  const { t } = useTranslation(i18next.language);

  const [openTooltip, setTooltipOpen] = useState(false);

  useEffect(() => {});

  function questionClicked(path) {
    scrollToElement(path);
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    const headerHeight =
      document.querySelector(".content-header")?.offsetHeight || 0;

    if (element) {
      const offset =
        element.getBoundingClientRect().top + window.scrollY - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }
  function showTooltipPopup() {
    setTooltipOpen(true);
  }
  function handleTooltipClose(isTooltipOpen) {
    setTooltipOpen(isTooltipOpen);
  }

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  return (
    <div className="main-content help-page">
      {openTooltip && (
        <ToolTipPopUp
          CardData={helpTooltipPopup}
          closeHandler={handleTooltipClose}
        />
      )}
      <div className="content">
        <div className="help-content">
          <Box className="content-header">
            <Typography
              variant="body"
              color="text.brand"
              className="page-heading"
            >
              {t("help.pageHeading")}
            </Typography>
            <Typography
              variant="h2"
              color="text.primary"
              className="page-title"
            >
              {t("help.pageTitle")}
            </Typography>
          </Box>
          <Grid
            container
            justifyContent="center"
            spacing={10}
            className="user-guide-container"
          >
            <Grid item xs={4} sm={4} className="left-items">
              <Button className="popup-cta-button"
                variant="outlined"
                startIcon={<OpenInNew color="#f6e003" />}
                onClick={() => showTooltipPopup()}>
                {t('help.ctaButtonTitle')}
              </Button>

              {helpQna.map((item, itemIndex) => (
                <Accordion key={itemIndex} className="item">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {t(item.title)}
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    {item.items.map((content, index) => (
                      <Typography
                        className="accordion-question"
                        onClick={() =>
                          questionClicked(`qna${itemIndex}${index}`)
                        }
                        key={index}
                      >
                        {t(`${item.itemsPath}.question${content}`)}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item xs={8} sm={8} className="right-items" id="rightItems">
              {helpQna?.map((item, itemIndex) => (
                <Box key={item.title} className="right-items-div">
                  <Typography className="right-items-title">
                    {t(item.title)}
                  </Typography>

                  {item?.items?.map((content, index) => {
                    const answers = t(`${item.itemsPath}.answer${content}`, {
                      returnObjects: true,
                    });

                    return (
                      <Box
                        className="right-items-box"
                        id={`qna${itemIndex}${index}`}
                        key={index}
                      >
                        <Typography className="right-items-question">
                          {t(`${item.itemsPath}.question${content}`)}
                        </Typography>
                        <Box className="right-items-answer">
                          {answers?.map((answerItem, answerItemIndex) => (
                            <Box
                              key={answerItemIndex}
                              className="answer-section"
                            >
                              {answerItem?.heading && (
                                <Typography
                                  variant="body"
                                  color="text.primary"
                                  className="answer"
                                >
                                  <Trans
                                    components={{
                                      highlight: <strong />,
                                      colored: <i />,
                                    }}
                                  >
                                    {answerItem.heading}
                                  </Trans>
                                </Typography>
                              )}
                              <Typography
                                variant="body"
                                color="text.primary"
                                className="answer"
                              >
                                <Trans
                                  components={{
                                    highlight: <strong />,
                                    colored: <i />,
                                    underlined: answerItem.link ? (
                                      <a
                                        href={answerItem.link}
                                        alt="answer link"
                                        className="underlined"
                                      >
                                        ""
                                      </a>
                                    ) : (
                                      <button
                                        // href="javascript:void(0)"
                                        onClick={() => {
                                          showTooltipPopup();
                                          return false;
                                        }}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                          border: "none",
                                          background: "none",
                                          color: "white",
                                          fontSize: "inherit",
                                        }}
                                        alt="action link"
                                        className="underlined"
                                      >
                                        ""
                                      </button>
                                    ),
                                    danger: <danger className="danger" />,
                                  }}
                                >
                                  {answerItem.text}
                                </Trans>
                              </Typography>
                              {answerItem.visual && (
                                <Container
                                  className={`section2-${answerItem.visual} section2-${answerItem.visual} answer-visual`}
                                  sx={{
                                    backgroundImage: `url(${assets.help[answerItem.visual]
                                      })`,
                                    aspectRatio: 1,
                                  }}
                                ></Container>
                              )}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="runner">
        <Runner forPage={"helppage"} />
      </div>
    </div>
  );
}

export default Help;
