import React from 'react';
import PropTypes from 'prop-types';
import styles from './AssociationCard.module.css';


export const AssociationCard = ({ 
  text, 
  subcatAccentColor,
  onAccentTextColor,
}) => {
  return (
    <div data-testid="association-card" className={styles.patternCard}>
      <div className={styles.cardBackground} style={{ backgroundColor: subcatAccentColor }} />
      <div className={styles.cardText}>
        {text.split('\\n').map((line) => (
          <span key={line} style={{ color: onAccentTextColor }}>
            {line}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

AssociationCard.propTypes = {
  text: PropTypes.string.isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
};
