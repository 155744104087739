//import { BlockBlobClient } from '@azure/storage-blob'
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { b2cLoginRequest } from "../auth-config"; // Update the path if necessary
import { msalInstance } from "../index"; // Adjust the path


let cachedAccessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null
let tokenExpirationTime = localStorage.getItem('tokenExpirationTime') ? new Date(localStorage.getItem('tokenExpirationTime')) : null;

export async function getAccessToken(instance) {
    if (!instance) {
        throw new Error("MSAL instance is not initialized.");
    }
    // Ensure MSAL instance is initialized
    if (!instance.getAllAccounts) {
        throw new Error("You must call and await the initialize function before using MSAL APIs.");
    }
    localStorage.setItem('isredirect', false);

    const currentTime = new Date();

    const accounts = await instance.getAllAccounts();

    const account = accounts.length > 0 ? accounts[0] : null;
    // const activeAccount = instance.getActiveAccount();
    // if (!activeAccount) {
    //     throw new Error("No active account found. Please log in.");
    // }
    const tokenRequest = {
        ...b2cLoginRequest,
        account,
    };

    // Check if there is a valid cached token
    if (cachedAccessToken && currentTime < tokenExpirationTime) {
        return cachedAccessToken;
    }
    try {
        const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
        cachedAccessToken = tokenResponse.accessToken;
        tokenExpirationTime = tokenResponse.expiresOn.toISOString();
        localStorage.setItem('accessToken', cachedAccessToken);
        localStorage.setItem('tokenExpirationTime', tokenExpirationTime);

        return cachedAccessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            try {
                localStorage.setItem('isredirect', true);
                localStorage.clear();
                return cachedAccessToken
                // TODO : re-implement usage of acquireTokenPopup
                // const tokenResponse = await instance.acquireTokenPopup(tokenRequest);
                // cachedAccessToken = tokenResponse.accessToken;
                // tokenExpirationTime = tokenResponse.expiresOn.toISOString();

                // localStorage.setItem('accessToken', cachedAccessToken);
                // localStorage.setItem('tokenExpirationTime', tokenExpirationTime);
                // console.log("acquireTokenPopup success",)
                // // localStorage.setItem('tokenUpdated', Date.now());
                // return cachedAccessToken;
            } catch (popupError) {
                console.error("Error during clearing Session details:", popupError);
                throw popupError;
            }
        } else {
            localStorage.setItem('isredirect', true);
            localStorage.clear();
            console.error("Error acquiring token:", error);
            return cachedAccessToken
        }
    }
}


// Get Default Headers
export async function getDefaultHeaders() {
    const headers = {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
    };
    try {
        const isLoggedin = localStorage.getItem('isLoggedin');
        if (isLoggedin) {
            const token = await getAccessToken(msalInstance);
            if (!token) {
                throw new Error("Token is empty or undefined.");
            }
            headers["Authorization"] = `Bearer ${token}`;
        } else {
            console.warn("User is not logged in. Authorization header not added.");
        }
    } catch (error) {
        console.error("Error fetching access token:", error);
    }
    return headers;
}


export async function getAssetsSASToken() {
    try {
        await fetch(`${window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT}/StorageToken`, {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: await getDefaultHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem('sasKey', data.sasKey)
                localStorage.setItem('storageUrl', data.storageUrl)
                localStorage.setItem('expiry', data.expiry)
            })
            .catch((err) => {
                console.log(err.message);
            });
    } catch (error) {
        console.error("Error fetching SAS Token:", error.message);

        // Optional: Log full error for debugging (e.g., network issues)
        console.error("Full error details:", error);
    }

}

let sasTokenRefreshInterval = null;

// Start periodic refresh for SAS token
export async function startSASTokenRefresh() {
    const refreshTime = 13 * 60 * 1000;

    if (sasTokenRefreshInterval) {
        clearInterval(sasTokenRefreshInterval);
    }

    try {
        await getAssetsSASToken();
    } catch (error) {
        console.error("Error refreshing SAS token:", error);
    }
    sasTokenRefreshInterval = setInterval(async () => {
        try {
            await getAssetsSASToken();
        } catch (error) {
            console.error("Error refreshing SAS token:", error);
        }
    }, refreshTime);
}

export async function fetchPeopleSegmentData(microSegment) {

    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + "/PeopleSegments?segment=" + microSegment
    return await fetch(url, {

        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: await getDefaultHeaders()
    })
        .then((response) => response.json())
}

export async function fetchOccasionsData(microOccasion) {


    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + "/OccasionsFramework?segment=" + microOccasion
    return await fetch(url, {

        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: await getDefaultHeaders()
    })
        .then((response) => response.json())
}

export async function fetchDemandSpaceData(microDemandSpace) {


    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + "/DemandSpaces?segment=" + microDemandSpace
    return await fetch(url, {

        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: await getDefaultHeaders()
    })
        .then((response) => response.json())
}

export async function submitFeedback(requestBody) {


    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + "/Application"
    return await fetch(url, {

        method: 'POST',
        headers: await getDefaultHeaders(),
        body: JSON.stringify(requestBody)
    })
        .then((response) => response.json())
}

export async function getInfoPopupStatus(userId) {


    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + `/PopupStatus?userId=${userId}`
    return await fetch(url, {

        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: await getDefaultHeaders(),
    })
        .then((response) => response.json())
}

export async function submitInfoPopupStatus(requestBody) {


    let url = window.env.REACT_APP_DEMAND_LANDSCAPE_API_ENDPOINT + "/PopupStatus?"
    return await fetch(url, {

        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        headers: await getDefaultHeaders(),
        body: JSON.stringify(requestBody)
    })
        .then((response) => response.json())
        .catch((err) => console.error(err.message))
}

/*export async function downloadFile(filename) {
    const file = filename.split('?')[0].split('/').pop()
    
    // Create a blob client from SAS token
    const client = new BlockBlobClient(filename);

    // Get blob url
    console.log(`blob.url: ${client.url}`);

    // Download file contents
    try {
        const downloadResponse = await client.download()
        console.log(`Received download file response: ${JSON.stringify(downloadResponse._response)}`)
    } catch(err) {
        console.log(`Error downloading file: ${JSON.stringify(err)}`)
    }
}
export async function downloadAllFiles() {
    let url = 'https://abi-dev-web-api.azurewebsites.net/api/ExportDataPack?fileName=PeopleSegment';
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        headers: getDefaultHeaders(),
    })
    .then((response) => {console.log(response); return response;})

    async function blobToString(blob) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
          fileReader.onloadend = (ev) => {
            resolve(ev.target.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsText(blob);
        });
      }
}*/