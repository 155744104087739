import React from 'react';
import Footer from './Footer';
import CatTripHomeHeader from './CatTripHeaderNav';
import { Outlet } from 'react-router';


function HueDetailPageLayout() {

    return (
        <div className='container'>
          <CatTripHomeHeader />
          <Outlet />
          <Footer />
        </div>
    )
}

export default HueDetailPageLayout;
