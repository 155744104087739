import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
} from "@mui/material/";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TripTypesMenuOptions } from "../../constants/TripTypesCats";
import { AlcoholCategoriesMenuOptions } from "../../constants/AlcoholCats";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';


function CatTripDropDownMenu({ selectedSegment, closeHandler }) {
  const navigate = useNavigate();

  function getMenuOptions(selectedSegment) {
    let menuOptions = [];
    switch (selectedSegment) {
      case "alcohol-categories":
        menuOptions = AlcoholCategoriesMenuOptions;
        break;
      case "trip-types":
        menuOptions = TripTypesMenuOptions;
        break;
      default:
        break;
    }

    return menuOptions;
  }

  function getHubTitle(selectedSegment) {
    let hubTitle = "";
    switch (selectedSegment) {
      case "alcohol-categories":
        hubTitle = <>Category Structure<br />HUB</>;
        break;
      case "trip-types":
        hubTitle = "Trip Types HUB";
        break;
      default:
        break;
    }
    return hubTitle;
  }

  function getNavigationPath(segment, params) {
    let path = "";
    switch (segment) {
      case "alcohol-categories":
        path = `/categories${
          params ? `/${params.path}` : ""
        }`;
        break;
      case "trip-types":
        path = `/trip-types${
          params ? `/${params.path}` : ""
        }`;
        break;
      default:
        break;
    }
    return path;
  }

  return (
    <Box className="drop-down-menu-container">
      <Grid container className="menu-container" gap={5.5}>
        <Grid item xs={2} className="left-menu-item" data-testid="left-menu-grid">
          <Box className="menu-item-hub-container">
            <Button
              variant="outlined"
              className="menu-item-hub-title-button"
              endIcon={<ChevronRightIcon className="icon" />}
              onClick={() => {
                const path = getNavigationPath(selectedSegment);
                closeHandler();
                navigate(path);
                window.scrollTo(0,0)
              }}
            >
              <Typography className="cat-trip-nav-button-text">
                {getHubTitle(selectedSegment)}
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9} className="right-menu-item" data-testid="right-menu-grid">
          <Grid container className="menu-items-container">
            {getMenuOptions(selectedSegment).map((menu, index) => (
              <Grid
                item
                xs={9 / getMenuOptions(selectedSegment).length}
                key={`dropdown-menuitem-container-${index + 1}`}
              >
                <Box className="menu-list-items">
                  {<Box className={`menu-header-container ${selectedSegment}`}>
                    {menu.header &&<Typography
                      className={`menu-header ${selectedSegment} header-${
                        index + 1
                      }`}
                    >
                      {menu.header}
                    </Typography>}
                  </Box>}
                  <List className="menu-list-item-container">
                    {menu.listOfItems.map((item, index) => (
                      <ListItem
                        key={`menu-list-item-${index + 1}`}
                        className="menu-list-item"
                        onClick={() => {
                          const path = getNavigationPath(
                            selectedSegment,
                            item.routeParams
                          );
                          closeHandler();
                          navigate(path);
                          window.scrollTo(0,0)
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              className={`cat-trip-menu-list-item-text`}
                            >
                              {item.itemText}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

CatTripDropDownMenu.propTypes = {
  selectedSegment: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default CatTripDropDownMenu;
