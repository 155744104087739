import React from "react";
import { Box, Card, Typography } from "@mui/material";
// import ScopeBadge from "../Badges/ScopeBadge";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { demandSpaceAssets } from "../../common/DemandSpaceAssets";

function DemandSpaceSectionUGCCard({
  demandspace,
  cardData,
  hoverHandler,
  pageName,
  space,
}) {
  // const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  const classMapping = {
    Fram_PSGMT_2: "comfort-craver",
    Fram_PSGMT_3: "loyal-traditionalist",
    Fram_PSGMT_5: "mature-discoverer",
    Fram_PSGMT_6: "diligent-discoverer",
    Fram_PSGMT_8: "carefree-fun-lover",
    Fram_PSGMT_9: "proud-celebrator",
    Fram_PSGMT_11: "creative-explorer",
    Fram_PSGMT_12: "social-curator",
  };

  const currentKey = space;
  const currentClass = pageName
    ? classMapping[currentKey] || ""
    : "demandspace-color";

  function isHovered(e, hoveredState) {
    if (hoverHandler) {
      hoverHandler(hoveredState ? e.currentTarget.id : "");
    }
  }

  return (
    // <Box
    //   className="demandspace-ugc-card-container"
    //   sx={{
    //     backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.61) 100%), url(${demandSpaceAssets.heroImages[demandspace]})`,
    //   }}
    //   id={demandspace}
    //   onClick={() => {
    //     navigate(`/demandspace/${demandspace}`)
    //     window.scrollTo(0, 0)
    //   }}
    //   onMouseEnter={(e) => isHovered(e, true)}
    //   onMouseLeave={(e) => isHovered(e, false)}
    // >
    //   {/* <Box className="scope-container">
    //     <ScopeBadge
    //       data={{
    //         value: t("common.scopeBadge.scopeBadgeValue", {
    //           value: parseFloat(cardData.scopeData).toFixed(1),
    //         }),
    //         label: t("common.scopeBadge.ofVolume"),
    //       }}
    //       scopeBadgeStyle={"scopeBadgeDemandspace"}
    //     />
    //   </Box> */}
    //   <Box className="ugc-text-container">
    //     <Typography variant="h3" className="demandspace-name">
    //       {cardData.title}
    //     </Typography>
    //     <Typography variant="body" className="demandspace-occasions">
    //       {cardData.subtitle}
    //     </Typography>
    //   </Box>
    // </Box>

    <Box className="demandspace-ugc-card-container">
      <Card
        className="ds-hover-card"
        id={demandspace}
        onClick={() => {
          navigate(`/demand-landscape/demandspace/${demandspace}`);
          window.scrollTo(0, 0);
        }}
        onMouseEnter={(e) => isHovered(e, true)}
        onMouseLeave={(e) => isHovered(e, false)}
      >
        <Box>
          <Box
            className="ds-card-img"
            sx={{
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.61) 100%), url(${demandSpaceAssets.heroImages[demandspace]()})`,
            }}
          />

          <Box className="ds-card-title">
            <Box className="ugc-text-container">
              <Typography
                variant="body"
                className={`demandspace-occasions ${currentClass}`}
              >
                {cardData.subtitle}
              </Typography>
              <Typography variant="h3" className="demandspace-name">
                {cardData.title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default DemandSpaceSectionUGCCard;
