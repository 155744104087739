import React from 'react'
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './ShopQuestions.module.css';


export function ShopQuestions() {

  const WHAT_IMAGE_URL = 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F91f51a61d24643fab84d67d8bb2785ae';

  return (
    <div className={styles.cardWrap} data-testid="research-section-container">
      <Card height="400px">
        <section className={styles.container}>
          <h2 className={styles.heading}>
            There are 2 key questions that must be answered to define an Alcohol Trip Type
          </h2>
          <div className={styles.imageContainer}>
            <img 
              className={styles.shopperImage} 
              src={WHAT_IMAGE_URL} 
              alt="What did they buy?" 
            />
            <img 
              className={styles.shopperImage} 
              src={WHAT_IMAGE_URL} 
              alt="What did they buy?" 
            />
          </div>
        </section>
      </Card>
    </div>
  )
}
