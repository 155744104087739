import React from 'react';
import { AssociationCard } from './compound-components/AssociationCard';
import { SectionHeader } from '../common-components/SectionHeader';
import { COLORS } from '../../../../constants/CatTripColors';
import PropTypes from 'prop-types';
import styles from './AssociationCardSection.module.css';


export const AssociationCardSection = ({ 
  cardData, 
  sectionName, 
  sectionTitle,
  subcatAccentColor,
  onAccentTextColor,
  sectionNameColor
}) => {
  
  return (
    <>
      <SectionHeader 
        data-testid="section-header"
        sectionName={sectionName} 
        sectionTitle={sectionTitle}
        accentColor={COLORS[subcatAccentColor]}
        sectionNameColor={COLORS[sectionNameColor]}
      />
      <div className={styles.associationCards}>
        {cardData.map(({text}) => (
          <AssociationCard 
            key={text} 
            data-testid="association-card"
            text={text} 
            subcatAccentColor={COLORS[subcatAccentColor]}
            onAccentTextColor={COLORS[onAccentTextColor]}
          />
        ))}
      </div>
    </>
  );
};

AssociationCardSection.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
  })).isRequired,
  sectionName: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
  sectionNameColor: PropTypes.string.isRequired,
};
