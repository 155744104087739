import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  Avatar,
  Grid,
  Typography,
  Box,
  Divider,
  MobileStepper,
  Button,
  useTheme,
} from "@mui/material";

import { peopleSegmentAssets } from "../PeopleSegmentAssets.js";
import { getTemplateColors } from "../../../constants/constants";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const MeetThePeople = ({ space, pageName }) => {
  const { t } = useTranslation(i18next.language);
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const boxCorousel = {
    background: "#1D1D1D",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };

  const classMapping = {
    Fram_PSGMT_2: "comfort-craver",
    Fram_PSGMT_3: "loyal-traditionalist",
    Fram_PSGMT_5: "mature-discoverer",
    Fram_PSGMT_6: "diligent-discoverer",
    Fram_PSGMT_8: "carefree-fun-lover",
    Fram_PSGMT_9: "proud-celebrator",
    Fram_PSGMT_11: "creative-explorer",
    Fram_PSGMT_12: "social-curator",
  };
  const classMappingBorder = {
    Fram_PSGMT_2: "comfort-craver-border",
    Fram_PSGMT_3: "loyal-traditionalist-border",
    Fram_PSGMT_5: "mature-discoverer-border",
    Fram_PSGMT_6: "diligent-discoverer-border",
    Fram_PSGMT_8: "carefree-fun-lover-border",
    Fram_PSGMT_9: "proud-celebrator-border",
    Fram_PSGMT_11: "creative-explorer-border",
    Fram_PSGMT_12: "social-curator-border",
  };

  const classMappingMainAvatarBorder = {
    Fram_PSGMT_2: "comfort-craver-main-avatar-border",
    Fram_PSGMT_3: "loyal-traditionalist-main-avatar-border",
    Fram_PSGMT_5: "mature-discoverer-main-avatar-border",
    Fram_PSGMT_6: "diligent-discoverer-main-avatar-border",
    Fram_PSGMT_8: "carefree-fun-lover-main-avatar-border",
    Fram_PSGMT_9: "proud-celebrator-main-avatar-border",
    Fram_PSGMT_11: "creative-explorer-main-avatar-border",
    Fram_PSGMT_12: "social-curator-main-avatar-border",
  };

  const currentKey = space;
  const currentClass = pageName
    ? "demandspace-color"
    : classMapping[currentKey] || "";
  const currentClassBorder = pageName
    ? "demandspacecolor-border"
    : classMappingBorder[currentKey] || "";

  const currentClassBorderMainAvatar = pageName
    ? "demandspacecolor-border"
    : classMappingMainAvatarBorder[currentKey] || "";

  const [selectedAvatar, setSelectedAvatar] = useState(0);

  const avatars = peopleSegmentAssets.meetThePeople[space]?.avatars?.();

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index);
  };

  const ClickableAvatar = ({ src, onClick, isSelected }) => {
    return (
      <Avatar
        className={`avatar ${currentClassBorder} ${isSelected ? "" : "selected"
          }`}
        src={src}
        onClick={onClick}
      />
    );
  };

  const attributions = t(
    `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.attribution`
  ).split("|");
  const meetPeopleQuotes = t(
    `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.tall-quote`,
    {
      returnObjects: true,
    }
  );
  const tallQuote = meetPeopleQuotes.map((item) => item.quote);


  return (
    <Grid container gap={5} className="meetthepeople-container">
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        className="meeththepeople-avatar-list-grid-item"
      >
        <Box sx={boxCorousel}>
          {avatars?.map((avatar, index) => (
            <ClickableAvatar
              key={`clickable-avatar-${index}`}
              src={avatar}
              onClick={() => handleAvatarClick(index)}
              isSelected={selectedAvatar === index}
            />
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={10.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          className="meeththepeople-avatar-data-grid-item-top"
        >
          <Grid item xs={2.9}>
            <Box className="avatar-data">
              <Avatar
                className={`avatar-big ${currentClassBorderMainAvatar}`}
                src={avatars[selectedAvatar]}
              />
              <Box className="card-text">
                <Box className="card-text-spacing">
                  <Typography className={`name-text ${currentClass}`}>
                    <Trans>
                      {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.name`
                      )}
                    </Trans>
                  </Typography>

                  <Typography className={`segment-name ${currentClass}`}>
                    <Trans>
                      {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.peopleSegment`
                      )}
                    </Trans>
                  </Typography>
                  <Box className="attribution-text-container">
                    {/* <Typography className={`details-text ${currentClass}`}> */}
                    {attributions.map((attributionItem, index) => (
                      <React.Fragment key={attributionItem}>
                        <Typography className="details-text">
                          {attributionItem}
                        </Typography>
                        {index !== attributions.length - 1 && (
                          <Divider
                            orientation="vertical"
                            // color={getTemplateColors(space)}
                            className={`attributions-divider`}
                          />
                        )}
                      </React.Fragment>
                    ))}
                    {/* </Typography> */}
                    {/* <Divider
                      orientation="vertical"
                      color={getTemplateColors(space)}
                      className={`attributions-divider`}
                    />
                    <Typography className={`details-text ${currentClass}`}>
                      {
                        t(
                          `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.attribution`
                        ).split("|")[1]
                      }
                    </Typography> */}
                  </Box>
                </Box>

                <Typography className={`attributionDetails-text`}>
                  <Trans>
                    {t(
                      `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.attributionDetails`
                    )}
                  </Trans>
                </Typography>
                {/* <Typography className="quote-text">
                  {t(
                    t(
                      `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote`
                    )
                  )}
                </Typography> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2.8}>
            <Grid container gap={2.5} minHeight="100%">
              {peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].squareImages?.().map((image, index) => (
                <Grid item key={`carousel-square-image-${index}`} xs={12}>
                  <Box
                    className="image-class"
                    sx={{
                      backgroundImage: `url(${image})`,
                    }}
                  >
                    {/* <img src={image} alt="" /> */}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {peopleSegmentAssets.meetThePeople[space][
            `carousel-${selectedAvatar}`
          ].verticalImage?.() ? (
            <>
              <Grid item xs={2.9}>
                <Box
                  className="big-image-class"
                  sx={{
                    backgroundImage: `url(${peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`]?.verticalImage()
                      })`,
                  }}
                >
                  {/* <img
                    src={
                      peopleSegmentAssets.meetThePeople[space][
                        `carousel-${selectedAvatar}`
                      ].verticalImage
                    }
                    alt=""
                  /> */}
                </Box>
              </Grid>
              <Grid item xs={2.8} sx={{ display: "flex", flexDirection: "column" }}>
                {/* <Box className="big-quote-container"> */}
                <Box className={`big-quote card-${activeStep}`}>
                  <span className={`quote-mark ${currentClass}`}>“</span>
                  <Typography className="quote-class">
                    {tallQuote[activeStep]}
                  </Typography>
                </Box>
                <MobileStepper
                  variant="dots"
                  steps={tallQuote.length}
                  position="static"
                  activeStep={activeStep}
                  className={`custom-mobile-stepper ${space}`}
                  nextButton={
                    <Button
                      className={`nextButton ${space}`}
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === tallQuote.length - 1}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      className={`backButton ${space}`}
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                    </Button>
                  }
                  sx={{
                    ".MuiMobileStepper-dotActive": {
                      backgroundColor: getTemplateColors(currentClass),
                    },
                  }}
                />
                {/* </Box> */}
              </Grid>
            </>
          ) : (
            <Grid item xs={5.9}>
              <Box className="big-quote">
                <span className={`quote-mark ${currentClass}`}>“</span>
                <Typography className="quote-class large-font">
                  {tallQuote[activeStep]}
                </Typography>
              </Box>
              <MobileStepper
                variant="dots"
                steps={tallQuote.length}
                position="static"
                activeStep={activeStep}
                className={`custom-mobile-stepper ${space}`}
                nextButton={
                  <Button
                    className={`nextButton ${space}`}
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === tallQuote.length - 1}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    className={`backButton ${space}`}
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </Button>
                }
                sx={{
                  ".MuiMobileStepper-dotActive": {
                    backgroundColor: getTemplateColors(currentClass),
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className="meeththepeople-avatar-data-grid-item-bottom"
        >
          <Grid item xs={5.9}>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                alignSelf: "stretch",
              }}
            >
              {peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].videos[0] ||
                peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].videoImage?.() ? (
                <>
                  <Grid item xs={6}>
                    <Box className="video-class">
                      {peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].videos[0] ? (
                        <video
                          controls
                          src={
                            peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].videos[0]
                          }
                          poster={
                            peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].thumbnails &&
                            peopleSegmentAssets.meetThePeople[space][`carousel-${selectedAvatar}`].thumbnails[0]
                          }
                          width="100%"
                          height="100%"
                          type="video/mp4"
                        />
                      ) : (
                        <img
                          src={
                            peopleSegmentAssets.meetThePeople[space][
                              `carousel-${selectedAvatar}`
                            ].videoImage()
                          }
                          alt="Fallback"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box className="text-comp">
                      <Typography className={`text-approach`}>
                        {/* ${currentClass} */}
                        {/* {t(
                            `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.videoTitle-left`
                          )} */}
                        <span style={{ color: "#ffffff" }}>“</span>
                        {t(
                          `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-left`
                        )}
                        <span style={{ color: "#ffffff" }}>”</span>
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12}>
                      <Paper className="bottom-quote-comp">
                        <div className={`bottom-quote-text ${currentClass}`}>
                          <span style={{ color: "#ffffff" }}>“</span>
                          {t(
                            `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-left`
                          )}
                          <span style={{ color: "#ffffff" }}>”</span>
                        </div>
                      </Paper>
                    </Grid> */}
                </>
              ) : (
                <Grid item xs={12}>
                  <Box className="text-comp-xs-12">
                    <Typography className={`text-approach`}>
                      {/* ${currentClass} */}
                      {/* {t(
                          `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.videoTitle-left`
                        )} */}
                      <span style={{ color: "#ffffff" }}>“</span>
                      {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-left`
                      )}
                      <span style={{ color: "#ffffff" }}>”</span>
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={5.9}>
            <Grid container justifyContent="space-between">
              {peopleSegmentAssets.meetThePeople[space][
                `carousel-${selectedAvatar}`
              ].videos[1] ? (
                <>
                  <Grid item xs={6}>
                    <Box className="video-class">
                      <video
                        controls
                        src={
                          peopleSegmentAssets.meetThePeople[space][
                            `carousel-${selectedAvatar}`
                          ].videos[1]
                        }
                        poster={
                          peopleSegmentAssets.meetThePeople[space][
                            `carousel-${selectedAvatar}`
                          ].thumbnails &&
                          peopleSegmentAssets.meetThePeople[space][
                            `carousel-${selectedAvatar}`
                          ].thumbnails[1]
                        }
                        width="100%"
                        height="100%"
                        type="video/mp4"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="text-comp">
                      <Typography className={`text-approach`}>
                        {/* {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.videoTitle-right`
                      )} */}
                        <span style={{ color: "#ffffff" }}>“</span>
                        {t(
                          `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-right`
                        )}
                        <span style={{ color: "#ffffff" }}>”</span>
                      </Typography>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Box className="text-comp-xs-12">
                    <Typography className={`text-approach`}>
                      {/* {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.videoTitle-right`
                      )} */}
                      <span style={{ color: "#ffffff" }}>“</span>
                      {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-right`
                      )}
                      <span style={{ color: "#ffffff" }}>”</span>
                    </Typography>
                  </Box>
                </Grid>
              )}

              {/* <Grid item xs={12}>
                  <Paper className="bottom-quote-comp">
                    <div className={`bottom-quote-text ${currentClass}`}>
                      <span style={{ color: "#ffffff" }}>“</span>
                      {t(
                        `people-segment.template.meetThePeople.${space}.carousel-${selectedAvatar}.quote-right`
                      )}
                      <span style={{ color: "#ffffff" }}>”</span>
                    </div>
                  </Paper>
                </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MeetThePeople;
