import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SegmentExplorer.module.css';
import PropTypes from 'prop-types';


export const SegmentExplorer = ({ segmentCards }) => {

  const navigate = useNavigate();

  const clickHandler = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <div className={styles.segmentsContainer}>
      <h1 className={styles.title}>Click on a Segment to Dig Deeper</h1>
      <div className={styles.grid}>
        {segmentCards.map((card) => (
          <button
            key={card.altText}
            className={styles.segmentCardButton}
            onClick={() => clickHandler(card.navigateTo)}
          >
            <img
              src={card.imgUrl}
              alt={card.altText}
              loading="lazy"
              className={styles.segmentCard}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

SegmentExplorer.propTypes = {
  segmentCards: PropTypes.arrayOf(PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    navigateTo: PropTypes.string.isRequired,
  })).isRequired,
};
