import Logo from "../../assets/images/Login/Logo.svg";

//Home page
import HomeLogo from "../../assets/images/Login/Mark.svg";
import Measure from "../../assets/images/Home/Measure.svg";
import Analyze from "../../assets/images/Home/Analyze.svg";
import Articulate from "../../assets/images/Home/Articulate.svg";

import { ArrowForward } from "@mui/icons-material";

//Help
import qna1 from "../../assets/images/Help/help-a1-visual.png";
import qna3 from "../../assets/images/Help/help-a3-visual.png";
import qna4a from "../../assets/images/Help/help-a4-p1-visual.png";
import qna4b from "../../assets/images/Help/help-a4-p2-visual.png";
import qna4c from "../../assets/images/Help/help-a4-p3-visual.png";
import qna5 from "../../assets/images/Help/help-a5-visual.png";
import qna6 from "../../assets/images/Help/help-a6-visual.png";
import qna7 from "../../assets/images/Help/help-a7-visual.png";
import qna8 from "../../assets/images/Help/data-source.png";
import qna9 from "../../assets/images/Help/help-a9-visual.png";

//Tooltip images
// import QuickLinkImg from "../../assets/images/PopImgs/CardImg1.png"
// import CategorySizingImg from "../../assets/images/PopImgs/CardImg2.png"
// import DownloadsImg from "../../assets/images/PopImgs/CardImg3.png"
// import ExpandingImg from "../../assets/images/PopImgs/CardImg4.png"
// import KeyDriversImg from "../../assets/images/PopImgs/CardImg5.png"
// import modal_image1 from "../../assets/images/PopImgs/CardImg5.png"

// const QuickLinkImg = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg1.jpg?${localStorage.getItem('sasKey')}`;
// const CategorySizingImg = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg2.jpg?${localStorage.getItem('sasKey')}`;
// const DownloadsImg = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg3.jpg?${localStorage.getItem('sasKey')}`;
// const ExpandingImg = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg4.jpg?${localStorage.getItem('sasKey')}`;
// const KeyDriversImg = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg5.jpg?${localStorage.getItem('sasKey')}`;



// const home_heroOffsetImage_front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Home_HeroOffsetImage_front.jpg?${localStorage.getItem('sasKey')}`;
// const home_heroOffsetImage_top = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Home_HeroOffsetImage_top.jpg?${localStorage.getItem('sasKey')}`;
// const home_demandLandscapeIntersection_image = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Demand-Landscape-Intersection.png?${localStorage.getItem('sasKey')}`;
// const home_demandLandscapeFramework_image = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Home_DemandLandscapeFramework_Image.jpg?${localStorage.getItem('sasKey')}`;
// const home_heroOffset_video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/video/home-hero-video.mp4?${localStorage.getItem('sasKey')}`;
// const modal_image = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Home_Modal_Image.jpg?${localStorage.getItem('sasKey')}`;
// const modal_image1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Tooltip/CardImg6.jpg?${localStorage.getItem('sasKey')}`;


// const persona_profile_pdf = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Persona.pdf?${localStorage.getItem('sasKey')}`;
// const persona_profile_ppt = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Persona.pptx?${localStorage.getItem('sasKey')}`;
// const occasion_profile_pdf = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Occasion.pdf?${localStorage.getItem('sasKey')}`;
// const occasion_profile_ppt = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Occasion.pptx?${localStorage.getItem('sasKey')}`;
// const demandspace_profile_pdf = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/DemandSpace.pdf?${localStorage.getItem('sasKey')}`;
// const demandspace_profile_ppt = `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/DemandSpace.pptx?${localStorage.getItem('sasKey')}`;

const getSasKey = () => localStorage.getItem('sasKey') || '';
const getStorageUrl = () => window.env.REACT_APP_AZURE_STORAGE_URL;

export const assets = {
  icons: {
    ArrowForward: ArrowForward,
  },
  logo: Logo,
  home: {
    homeLogo: HomeLogo,
    firstTimeImg: () => `${getStorageUrl()}/home/images/Home_Modal_Image.jpg?${getSasKey()}`,
    fullScreenExperienceImg: () => `${getStorageUrl()}/Tooltip/CardImg6.jpg?${getSasKey()}`,
    homepage_offsetvideo: () => `${getStorageUrl()}/home/video/home-hero-video.mp4?${getSasKey()}`,
    homepage_offsetImage_front: () => `${getStorageUrl()}/home/images/Home_HeroOffsetImage_front.jpg?${getSasKey()}`,
    homepage_offsetImage_top: () => `${getStorageUrl()}/home/images/Home_HeroOffsetImage_top.jpg?${getSasKey()}`,
    measure: Measure,
    analyze: Analyze,
    articulate: Articulate,
    demandLandscapeFrameworkImage: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Home_DemandLandscapeFramework_Image.jpg?${getSasKey()}`,
    demandLandscapeIntersection: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/images/Demand-Landscape-Intersection.png?${getSasKey()}`
  },
  downloads: {
    persona_profile_pdf: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Persona.pdf?${getSasKey()}`,
    persona_profile_ppt: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Persona.pptx?${getSasKey()}`,
    occasion_profile_pdf: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Occasion.pdf?${getSasKey()}`,
    occasion_profile_ppt: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/Occasion.pptx?${getSasKey()}`,
    demandspace_profile_pdf: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/DemandSpace.pdf?${getSasKey()}`,
    demandspace_profile_ppt: `${window.env.REACT_APP_AZURE_STORAGE_URL}/home/profiles/DemandSpace.pptx?${getSasKey()}`
  },

  help: {
    qna1: qna1,
    qna3: qna3,
    qna4a: qna4a,
    qna4b: qna4b,
    qna4c: qna4c,
    qna5: qna5,
    qna6: qna6,
    qna7: qna7,
    qna8: qna8,
    qna9: qna9,
  },
  tooltip: {
    quickLinkImg: () => `${getStorageUrl()}/Tooltip/CardImg1.jpg?${getSasKey()}`,
    categorySizingImg: () => `${getStorageUrl()}/Tooltip/CardImg2.jpg?${getSasKey()}`,
    downloadsImg: () => `${getStorageUrl()}/Tooltip/CardImg3.jpg?${getSasKey()}`,
    expandingDataImg: () => `${getStorageUrl()}/Tooltip/CardImg4.jpg?${getSasKey()}`,
    keyDriversImg: () => `${getStorageUrl()}/Tooltip/CardImg5.jpg?${getSasKey()}`,
    fullScreenExperienceImg: () => `${getStorageUrl()}/Tooltip/CardImg6.jpg?${getSasKey()}`
  }
};

