import React from 'react';
import { LandingHeader } from '../cat-trip-reuse-components/header/LandingHeader';
import { AlcoholCategories } from './components/alcohol-categories/AlcoholCategories';
import { AlcoholSubcategories } from './components/alcohol-subcategories/AlcoholSubcategories';
import { PriceTiers } from './components/price-tiers/PriceTiers';
import { CategoryStructureIntro } from './components/category-structure/CategoryStructureIntro';
import styles from './CategoryLandingPage.module.css';


const CategoryLandingPage = () => {
  const introText = "INTRODUCING THE A-B CATEGORY STRUCTURE";
  const title = "A Guide to how we organize alcohol categories";
  const imageSrc = "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/1cafff71dd40ea48be03845ac1674f64a42b3dccae7e0183ae2756443756c13a";
  const altText = "Alcohol category structure header";

  return (
    <div className={styles.container}>
      <LandingHeader 
        title={title} 
        introText={introText} 
        imageSrc={imageSrc} 
        altText={altText} 
        buttonPath="/categories/framework"
        buttonCopy="View the Category Structure"
      />
      <AlcoholCategories />
      <AlcoholSubcategories />
      <PriceTiers />
      <CategoryStructureIntro />
    </div>
  )
}

export default CategoryLandingPage
