import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeatureCard.module.css';


export const FeatureCard = ({ icon, title, description, alt }) => {
  return (
    <div className={styles.featureCard}>
      <img 
        src={icon} 
        className={styles.icon} 
        alt={alt}
        loading="lazy"
      />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

FeatureCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
