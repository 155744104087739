import React from 'react';
import styles from './CategoryCard.module.css';


export const CategoryCard = ({ title, icon, categories, variant }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.topSection}>
        <div className={`${styles.card} ${styles[variant]}`}>
          <div className={styles.iconContainer}>
            <img 
              src={icon} 
              alt={`${title} category icon`} 
              className={styles.categoryIcon} 
              loading="lazy"
            />
            <div className={styles.categoryTitle}>{title}</div>
          </div>
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.categoriesList}>
          {categories.map((category, index) => (
            <div key={index} className={styles.categoryItem}>
              {category}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
