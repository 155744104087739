export const TripTypesMenuOptions = [
  {
    header: "Trip Types",
    listOfItems: [
      {
        itemText: "Alcohol Grab & Go",
        routeParams: {
          path: "alcohol-grab-go",
        },
      },
      {
        itemText: "Alcohol Reload",
        routeParams: {
          path: "alcohol-reload",
        },
      },
    ],
  },
  {
    listOfItems: [
      {
        itemText: "Pit Stop Essentials",
        routeParams: {
          path: "pit-stop-essentials",
        },
      },
      {
        itemText: "Today's Meal+",
        routeParams: {
          path: "todays-meal-plus",
        },
      },
    ],
  },
  {
    listOfItems: [
      {
        itemText: "Frequent Fill-In",
        routeParams: {
          path: "frequent-fill-in",
        },
      },
      {
        itemText: "Household Stock-Up",
        routeParams: {
          path: "household-stock-up",
        },
      },
    ],
  },
];
