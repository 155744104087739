export const strategyIcons = [
  {
    id: 1,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/30a2dceb7072d657564dc11ef47a8f8c36ad2faffe61c57dff27f71a4f4c1343",
    title: "Category",
    alt: "Category strategy icon"
  },
  {
    id: 2,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/f24fbecbbe244ede53e9da53e70d33e9d1c9e7a91dda4115e366a083d5508e6b",
    title: "Portfolio",
    alt: "Portfolio strategy icon"
  },
  {
    id: 3,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/33ebd9e3b26dec4a30088e546146c102eb993086b4b47c6772136a899b5a485c",
    title: "Innovation",
    alt: "Innovation strategy icon"
  },
  {
    id: 4,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/aa4fbfedfb80ad08efbcad29008b619aceaae4d51ad7ae5ae44d6341698c6a9e",
    title: "Brand",
    alt: "Brand strategy icon"
  }
];

export const activationIcons = [
  {
    id: 5,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/13318991a42604ef3a7b82aeae25725e7447bc73eeec990519597e385cc617b7",
    title: "Media / Data",
    alt: "Media and data activation icon"
  }
];

export const retailIcons = [
  {
    id: 6,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/d31d84a1785b57d003ef3099a1a9d6469589d3791089b5caffdb81cf035e39cb",
    title: "Trade",
    alt: "Trade retail icon"
  },
  {
    id: 7,
    src: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/03612c16fb806b96b35bd956136fc4133eebf5c6fd883f39e404b10e0f86fa12",
    title: <>Business<br />Planning</>,
    alt: "Business planning retail icon"
  }
];