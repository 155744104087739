import React from 'react';
import Footer from './Footer';
import CatTripHomeHeader from './CatTripHeaderNav';
import { Outlet } from 'react-router';
import CatTripSubNav from './CatTripSubNav';
import { mainNavLinks as beerMainNavLinks, subNavLinks as beerSubNavLinks, navColorSet as beerNavColorSet } from '../../constants/BeerHubSubNavOptions';
import { mainNavLinks as hardBevMainNavLinks, subNavLinks as hardBevSubNavLinks, navColorSet as hardBevNavColorSet } from '../../constants/HardBevSubNavOptions';
import { mainNavLinks as tripTypesMainNavLinks, subNavLinks as tripTypesSubNavLinks, navColorSet as tripTypesNavColorSet } from '../../constants/TripTypesSubNavOptions';
import PropTypes from 'prop-types';

function HueDetailPageLayout({ page, hubType }) {
    const getNavOptions = (hubType) => {
        switch(hubType) {
            case 'beer':
                return {
                    mainNavLinks: beerMainNavLinks,
                    subNavLinks: beerSubNavLinks,
                    navColorSet: beerNavColorSet
                };
            case 'hardBev':
                return {
                    mainNavLinks: hardBevMainNavLinks,
                    subNavLinks: hardBevSubNavLinks,
                    navColorSet: hardBevNavColorSet
                };
            case 'tripTypes':
                return {
                    mainNavLinks: tripTypesMainNavLinks,
                    subNavLinks: tripTypesSubNavLinks,
                    navColorSet: tripTypesNavColorSet
                };
            default:
                return null;
        }
    };

    const navOptions = getNavOptions(hubType);

    return (
        <div className='container'>
            <CatTripHomeHeader />
            {navOptions && (
                <CatTripSubNav
                    subCategory={page}
                    mainNavLinks={navOptions.mainNavLinks}
                    subNavLinks={navOptions.subNavLinks}
                    anchorBarColor={navOptions.navColorSet[page].anchorBarColor}
                    anchorBarColorSecondary={navOptions.navColorSet[page].anchorBarColorSecondary}
                    textColor={navOptions.navColorSet[page].textColor}
                />
            )}
            <Outlet />
            <Footer />
        </div>
    );
}

HueDetailPageLayout.propTypes = {
    page: PropTypes.string.isRequired,
    hubType: PropTypes.string.isRequired,
};

export default HueDetailPageLayout;
