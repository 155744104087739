import * as React from "react";
import styles from './InstructionsCard.module.css';


export const InstructionsCard = () => {
  return (
    <div className={styles.cardContainer}>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6d3396fdc09f45e3bf7258e94cddd998" 
        alt="Instructions" 
        className={styles.instructionsImage}
      />
    </div>
  );
}
