import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionHeader.module.css';

export const SectionHeader = ({ title }) => {
  return (
    <div className={styles.header}>
      <div className={styles.line} />
      <div className={styles.title}>{title}</div>
      <div className={styles.line} />
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};