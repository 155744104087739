import React from 'react';
import styles from './TripTypeFWHeader.module.css';

export default function TripTypeFWHeader() {
  return (
    <>
      <div className={styles.mainNav}>
        <div className={styles.moleculeContent}>
          <div className={styles.links} />
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.copy}>
          <h1 className={styles.title}>alcohol trip types framework</h1>
          <p className={styles.description}>
            A shopper-centric map of trips across the alcohol category that
            creates the frame to understand shoppers' needs and what drives
            store and product choice across channels
          </p>
        </div>
      </div>
    </>
  );
}
