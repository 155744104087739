import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import ConsumptionVisualCard from "../../custom/Cards/ConsumptionVisualCard";
import { demandspaceOccassionPeopleSegmentMapping } from "../../../constants/DemandSpace";
import { demandSpaceAssets } from "../../common/DemandSpaceAssets";
import WayFinderDemandSpace from "../../common/page-sections/WayFinderDemandSpace";
import { useNavigate } from "react-router-dom";
import { PlayArrow } from "@mui/icons-material";

function SectionOne({ space, macro }) {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  const videoRef = useRef();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  function playVideo() {
    videoRef.current.play();
  }

  return (
    <>
      <div className="section1-demandspacetemplate">
        <Grid container className="intro-demandspacetemplate">
          <Grid item className="left-item-demandspacetemplate" xs={6}>
            <Box className="left-item-content-demandspacetemplate">
              <Typography
                variant="h4"
                className={`paragraph-demandspacetemplate`}
              >
                <Trans
                  components={{
                    colored: <strong />,
                    coloredDynamic: <i />,
                    coloredgreen: <b />,
                    coloredyellow: <strong />,
                    coloredorange: <span className="colored-orange" />,
                    coloredpurple: <u />,
                    coloredblue: <span className="colored-blue" />,
                    coloredOutgoing: <span className="colored-outgoing" />
                  }}
                >
                  {t(
                    `demandspace.template.peopleSegmentandOccasions.${space}.partOfMacro`,
                    {
                      type: t(`demandspace.type.${space}`),
                      macroOccasion: t(`demandspace.macroOccasion.${space}`),
                    }
                  )}
                </Trans>
              </Typography>
              {/* <Box
                sx={{ cursor: "pointer" }}
                className={`hero-wayfinder ${space}`}
                onClick={() => {
                  navigate("/demandspace");
                  window.scrollTo(0, 0);
                }}
              >
                <WayFinderDemandSpace
                  ids={demandspaceOccassionPeopleSegmentMapping[`${space}`]}
                />
              </Box> */}
              <Box className="left-item-box">
                <Typography variant="body" className="greytext">
                  <Trans components={{ colored: <i /> }}>
                    {t("demandspace.template.whatIsAllAbout", {
                      type: t(`demandspace.type.${space}`),
                    })}
                  </Trans>
                </Typography>
                <Typography variant="h5" className="descriptiontext">
                  {t(`demandspace.template.intro.${space}.paragraph`)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Divider
            orientation="vertical"
            className="vertical-divider"
          ></Divider>
          <Grid item className="right-item" xs={6}>
            {/* <Box className="right-item-box">
              <Typography variant="body" className="greytext">
                <Trans components={{ colored: <i /> }}>
                  {t("demandspace.template.whatIsAllAbout", {
                    type: t(`demandspace.type.${space}`),
                  })}
                </Trans>
              </Typography>
              <Typography variant="h5" className="descriptiontext">
                {t(`demandspace.template.intro.${space}.paragraph`)}
              </Typography>
            </Box> */}
            <Box
              sx={{ cursor: "pointer" }}
              className={`hero-wayfinder ${space}`}
              onClick={() => {
                navigate("/demand-landscape/demandspace");
                window.scrollTo(0, 0);
              }}
            >
              <WayFinderDemandSpace
                ids={demandspaceOccassionPeopleSegmentMapping[`${space}`]}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className="spaces-container-video-div">
          {!isVideoPlaying && (
            <Box className="callout-button">
              <PlayArrow className="play-circle" onClick={() => playVideo()} />
            </Box>
          )}
          <Container className="space-container" maxWidth="xl">
            <video
              className="video-tag"
              controls
              poster={
                demandSpaceAssets.intro[space].featuredPersonVideoThumbnail()
              }
              ref={videoRef}
              onPlay={() => setIsVideoPlaying(true)}
              onEnded={() => setIsVideoPlaying(false)}
              src={demandSpaceAssets.intro[space].featuredPersonVideo()}
              type="video/mp4"
            />
          </Container>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, alignSelf: 'stretch' }}>
          <Box className="visual-snapshots-container">
            <Typography className="snapshot-typo" variant="h5">
              {t("demandspace.template.whatPeopleDrinking")}
            </Typography>
            <Divider
              sx={{ flex: "1 0 0" }}
              className="snapshot-divider"
            ></Divider>
          </Box>
          <Grid container className="section1-image-list" gap={2.5}>
            {macro?.map((item, index) => (
              <Grid item key={index} xs={2.8}>
                <ConsumptionVisualCard space="demand-space" bevData={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, alignSelf: 'stretch' }}>
          <Box className="visual-snapshots-container">
            <Typography className="snapshot-typo" variant="h5">
              {t("demandspace.template.visualSnapshot")}
            </Typography>
            <Divider
              sx={{ flex: "1 0 0" }}
              className="snapshot-divider"
            ></Divider>
          </Box>
          <ImageList className="visual-snapshot-imagelist" sx={{ width: "100%", marginTop: 0 }} gap={20} cols={4} rowHeight={300}          >
            {demandSpaceAssets.visualSnapshot[space].map((items, index) => {
              let imageItem = null
              if (index !== demandSpaceAssets.visualSnapshot[space].length - 1) {
                imageItem = (<ImageListItem
                  key={index}
                  sx={{ borderRadius: "1rem", overflow: "hidden" }}
                >
                  <img src={`${items.grid()}`} alt={"grid Images"} loading="lazy" />
                </ImageListItem>)
              }
              return imageItem
            })}
          </ImageList>
        </Box>
      </div>
    </>
  );
}

export default SectionOne;
