import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Divider,
  MobileStepper,
  Button,
  useTheme,
} from "@mui/material";
import { demandSpaceAssets } from "../DemandSpaceAssets";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { getTemplateColors } from "../../../constants/constants";

const DemandspaceHowTheyDrink = ({ space, pageName }) => {
  const { t } = useTranslation(i18next.language);
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const boxCorousel = {
    background: "#1D1D1D",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };

  const classMapping = {
    Fram_PSGMT_2: "comfort-craver",
    Fram_PSGMT_3: "loyal-traditionalist",
    Fram_PSGMT_5: "mature-discoverer",
    Fram_PSGMT_6: "diligent-discoverer",
    Fram_PSGMT_8: "carefree-fun-lover",
    Fram_PSGMT_9: "proud-celebrator",
    Fram_PSGMT_11: "creative-explorer",
    Fram_PSGMT_12: "social-curator",
  };
  const classMappingBorder = {
    Fram_PSGMT_2: "comfort-craver-border",
    Fram_PSGMT_3: "loyal-traditionalist-border",
    Fram_PSGMT_5: "mature-discoverer-border",
    Fram_PSGMT_6: "diligent-discoverer-border",
    Fram_PSGMT_8: "carefree-fun-lover-border",
    Fram_PSGMT_9: "proud-celebrator-border",
    Fram_PSGMT_11: "creative-explorer-border",
    Fram_PSGMT_12: "social-curator-border",
  };

  const classMappingMainAvatarBorder = {
    Fram_PSGMT_2: "comfort-craver-main-avatar-border",
    Fram_PSGMT_3: "loyal-traditionalist-main-avatar-border",
    Fram_PSGMT_5: "mature-discoverer-main-avatar-border",
    Fram_PSGMT_6: "diligent-discoverer-main-avatar-border",
    Fram_PSGMT_8: "carefree-fun-lover-main-avatar-border",
    Fram_PSGMT_9: "proud-celebrator-main-avatar-border",
    Fram_PSGMT_11: "creative-explorer-main-avatar-border",
    Fram_PSGMT_12: "social-curator-main-avatar-border",
  };

  const [selectedAvatar, setSelectedAvatar] = useState(0);

  const selectedAvatarIdentifier = t(`demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.personaID`);
  const currentClass = classMapping[selectedAvatarIdentifier] || "";
  const currentClassBorder = classMappingBorder[selectedAvatarIdentifier] || "";
  const currentClassBorderMainAvatar = classMappingMainAvatarBorder[selectedAvatarIdentifier] || "";

  const avatars = demandSpaceAssets?.howTheyDrink[space]?.avatars();

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index);
  };

  const ClickableAvatar = ({ src, onClick, isSelected }) => {
    return (
      <Avatar
        className={`avatar ${currentClassBorder} ${isSelected ? "" : "selected"
          }`}
        src={src}
        onClick={onClick}
      />
    );
  };
  const attributions = t(
    `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.attribution`
  ).split("|");

  const howTheyDrinkQuotes = t(
    `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-left`,
    {
      returnObjects: true,
    }
  );
  const leftQuote = Array.isArray(howTheyDrinkQuotes) ? howTheyDrinkQuotes.map(item => item?.quote) : [];


  const videoUrls = demandSpaceAssets?.howTheyDrink[space]?.[`carousel-${selectedAvatar}`]?.videos?.();
  return (
    <Grid container gap={5} className="meetthepeople-container">
      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        className="meeththepeople-avatar-list-grid-item"
      >
        <Box sx={boxCorousel}>
          {avatars?.map((avatar, index) => (
            <ClickableAvatar
              key={`clickable-avatar-${index}`}
              src={avatar}
              onClick={() => handleAvatarClick(index)}
              isSelected={selectedAvatar === index}
            />
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={10.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid
          container
          className="meeththepeople-avatar-data-grid-item-top"
          justifyContent="space-between"
        >
          <Grid item xs={2.9}>
            <Box className="avatar-data">
              <Avatar
                className={`avatar-big ${currentClassBorderMainAvatar}`} //demandspacecolor-main-avatar-border `}
                src={avatars[selectedAvatar]}
              />
              <Box className="card-text">
                <Box className="card-text-spacing">
                  <Typography className={`name-text ${currentClass}`}>
                    <Trans>
                      {t(
                        `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.name`
                      )}
                    </Trans>
                  </Typography>
                  <Typography className={`segment-name ${currentClass}`}>
                    <Trans>
                      {t(
                        `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.peopleSegment`
                      )}
                    </Trans>
                  </Typography>
                  <Box className="attribution-items">
                    {attributions.map((attributionItem, index) => (
                      <React.Fragment key={index}>
                        <Typography className={`ds-attribution-text`}>
                          {attributionItem}
                        </Typography>
                        {index !== attributions.length - 1 && (
                          <Divider
                            orientation="vertical"
                            className={`attribution-divider`}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Box>
                <Typography className={`attributionDetails-text`}>
                  <Trans>
                    {t(`demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.attributionDetails`)}
                  </Trans>
                </Typography>
                {/* <Typography className="quote-text">
                  {t(
                    t(
                      `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.description`
                    )
                  )}
                </Typography> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2.8}>
            <Grid container gap={2.5} minHeight="100%">
              {demandSpaceAssets?.howTheyDrink[space][`carousel-${selectedAvatar}`]?.squareImages()?.map((image, index) => (
                <Grid item key={`carousel-square-image-${index}`} xs={12}>
                  <Box
                    className="image-class"
                    sx={{
                      backgroundImage: `url(${image})`,
                    }}
                  >
                    {/* <img src={image} alt="" /> */}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {demandSpaceAssets?.howTheyDrink[space][`carousel-${selectedAvatar}`]
            ?.verticalImage?.() ? (
            <>
              <Grid item xs={2.9}>
                <Box
                  className="big-image-class"
                  sx={{
                    backgroundImage: `url(${demandSpaceAssets?.howTheyDrink[space][`carousel-${selectedAvatar}`]?.verticalImage()
                      })`,
                  }}
                >
                  {/* <img
                    src={
                      demandSpaceAssets?.howTheyDrink[space][
                        `carousel-${selectedAvatar}`
                      ]?.verticalImage
                    }
                    alt=""
                  /> */}
                </Box>
              </Grid>
              <Grid item xs={2.8} sx={{ display: "flex" }}>
                <Box className="big-quote">
                  {/* <span className={`quote-mark ${currentClass}`}>“</span> */}
                  <Typography className="quote-class ds-bigquote">
                    {t(
                      `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.description`
                    )}
                  </Typography>
                  <div></div>
                </Box>
              </Grid>
            </>
          ) : (
            <Grid item xs={5.9} sx={{ display: "flex" }}>
              <Box className="big-quote">
                {/* <span className={`quote-mark ${currentClass}`}>“</span> */}
                <Typography className={`quote-class ds-bigquote ${selectedAvatarIdentifier}`}>
                  {t(
                    `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.description`
                  )}
                </Typography>
                <div></div>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          className="meeththepeople-avatar-data-grid-item-bottom"
          justifyContent="space-between"
        >
          <Grid item xs={5.9}>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                alignSelf: "stretch",
              }}
            >
              {demandSpaceAssets?.howTheyDrink[space][
                `carousel-${selectedAvatar}`
              ]?.videos[0] ? (
                <>
                  <Grid item xs={6}>
                    <Box className="video-class">
                      <video
                        controls
                        src={
                          demandSpaceAssets?.howTheyDrink[space][
                            `carousel-${selectedAvatar}`
                          ]?.videos[0]
                        }
                        width="100%"
                        height="100%"
                        type="video/mp4"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Box className={`big-quote card-${activeStep}`}>
                      <span className={`quote-mark ${currentClass}`}>“</span>
                      <Typography className="quote-class">
                        {leftQuote[activeStep]}
                      </Typography>
                    </Box> */}
                    <Box className="text-comp-container">
                      <Box className="text-comp-ds">
                        <span
                          className={`text-approach-quote-mark-ds ${currentClass}`}
                        >
                          “
                        </span>
                        <Typography className="text-approach-ds">
                          {leftQuote[activeStep]}
                          <span
                            style={{ color: "#ffffff" }}
                            className={`${currentClass}`}
                          >
                            ”
                          </span>
                        </Typography>
                      </Box>
                      <MobileStepper
                        variant="dots"
                        steps={leftQuote.length}
                        position="static"
                        activeStep={activeStep}
                        className={`custom-mobile-stepper ${space}`}
                        nextButton={
                          <Button
                            className={`nextButton ${space}`}
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === leftQuote.length - 1}
                          >
                            {theme.direction === "rtl" ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        }
                        backButton={
                          <Button
                            className={`backButton ${space}`}
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            {theme.direction === "rtl" ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                          </Button>
                        }
                        sx={{
                          ".MuiMobileStepper-dotActive": {
                            backgroundColor: getTemplateColors(currentClass),
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Paper className="bottom-quote-comp">
                      <div className="bottom-quote-text">
                        <span style={{ color: "#ffffff" }} className={`${currentClass}`}>“</span>
                        {t(
                          `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-left`
                        )}
                        <span style={{ color: "#ffffff" }} className={`${currentClass}`}>”</span>
                      </div>
                    </Paper>
                  </Grid> */}
                </>
              ) : (
                <Grid item xs={12}>
                  {/* <Paper className="bottom-quote-comp-video-not-available">
                    <div className="bottom-quote-text-video-not-available">
                      <span style={{ color: "#ffffff" }} className={`${currentClass}`}>“</span>
                      {t(
                        `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-left`
                      )}
                      <span style={{ color: "#ffffff" }} className={`${currentClass}`}>”</span>
                    </div>
                  </Paper> */}
                  <Box className="text-comp-container">
                    <Box className={`text-comp-xs-12-ds card-${activeStep} ${selectedAvatarIdentifier}`}>
                      <span
                        className={`text-approach-quote-mark-ds ${currentClass} card-${activeStep}`}
                      >
                        “
                      </span>
                      <Typography className={`text-approach-ds card-${activeStep}`}>
                        {/* {t(
                        `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.videoTitle-left`
                      )} */}
                        {leftQuote[activeStep]}
                        <span
                          style={{ color: "#ffffff" }}
                          className={`${currentClass} card-${activeStep}`}
                        >
                          ”
                        </span>
                      </Typography>
                    </Box>
                    <MobileStepper
                      variant="dots"
                      steps={leftQuote.length}
                      position="static"
                      activeStep={activeStep}
                      className={`custom-mobile-stepper ${selectedAvatarIdentifier}`}
                      nextButton={
                        <Button
                          className={`nextButton ${selectedAvatarIdentifier}`}
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === leftQuote.length - 1}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button
                          className={`backButton ${selectedAvatarIdentifier}`}
                          size="small"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </Button>
                      }
                      sx={{
                        ".MuiMobileStepper-dotActive": {
                          backgroundColor: getTemplateColors(selectedAvatarIdentifier),
                        },
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={5.9}>
            <Grid
              container
              justifyContent={"space-between"}
              sx={{
                alignSelf: "stretch",
              }}
            >
              {demandSpaceAssets?.howTheyDrink[space][
                `carousel-${selectedAvatar}`
              ]?.videos() ? (
                <>
                  <Grid item xs={6}>
                    <Box className="video-class">
                      <video
                        controls
                        src={
                          videoUrls[1]
                        }
                        width="100%"
                        height="100%"
                        type="video/mp4"
                        className="demandspace-video"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="text-comp">
                      <Typography className="text-approach">
                        {/* {t(
                            `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.videoTitle-right`
                          )} */}
                        <span
                          style={{ color: "#ffffff" }}
                          className={`${currentClass}`}
                        >
                          “
                        </span>
                        {t(
                          `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-right`
                        )}
                        <span
                          style={{ color: "#ffffff" }}
                          className={`${currentClass}`}
                        >
                          ”
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}>
                      <Paper className="bottom-quote-comp">
                        <div className="bottom-quote-text">
                          <span style={{ color: "#ffffff" }} className={`${currentClass}`}>“</span>
                          {t(
                            `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-right`
                          )}
                          <span style={{ color: "#ffffff" }} className={`${currentClass}`}>”</span>
                        </div>
                      </Paper>
                    </Grid> */}
                </>
              ) : (
                <Grid item xs={12}>
                  <Box className="text-comp-xs-12">
                    <Typography className="text-approach">
                      {/* {t(
                          `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.videoTitle-right`
                        )} */}
                      <span
                        style={{ color: "#ffffff" }}
                        className={`${currentClass}`}
                      >
                        “
                      </span>
                      {t(
                        `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-right`
                      )}
                      <span
                        style={{ color: "#ffffff" }}
                        className={`${currentClass}`}
                      >
                        ”
                      </span>
                    </Typography>
                  </Box>
                </Grid>
              )}
              {/* {demandSpaceAssets?.howTheyDrink[space][
                  `carousel-${selectedAvatar}`
                ]?.videos[1] && (
                  <Grid item xs={12}>
                    <Paper className="bottom-quote-comp-video-not-available">
                      <div className="bottom-quote-text-video-not-available">
                        <span className={`${currentClass}`}>“</span>
                        {t(
                          `demandspace.template.consumption.howTheyDrink.${space}.carousel-${selectedAvatar}.quote-right`
                        )}
                        <span className={`${currentClass}`}>”</span>
                      </div>
                    </Paper>
                  </Grid>
                )} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DemandspaceHowTheyDrink;
