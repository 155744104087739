export const COLORS = {
  // BEER HUB COLORS
  NON_ALC_YELLOW: '#F2CE19',
  MALT_ICE_YELLOW: '#F1B91A',
  AM_CLASSIC_ORANGE: '#F1A01F',
  AM_LIGHT_ORANGE: '#F18721',
  AM_BALANCE_ORANGE: '#D67A26',
  IMPORTS_ORANGE: '#D86826',
  ELEVEATED_ORANGE: '#D75527',
  IPA_DRK_ORANGE: '#B43D1E',
  CRAFT_OTHER_RED: '#973108',
  FLAVORED_DRK_RED: '#4C0A00',
  // HARD BEV HUB COLORS
  SELTZER_SPIRIT_PURPLE: '#A484BC',
  SELTZER_MALT_PURPLE: '#7F6192',
  HARD_CIDER_PURPLE: '#6955A4',
  FMWBS_LOW_PURPLE: '#634C9F',
  FMWBS_HIGH_PURPLE: '#4D4391',
  SPIRIT_COCKTAILS_PURPLE: '#564099',
  MULTI_SERVE_PURPLE: '#4B2F8B',
  // TRIP TYPES COLORS
  PITSTOP_BLUE: '#0F70BB',
  GRAB_GO_ORANGE: '#FF9621',
  ALC_RELOAD_TEAL: '#03C3BE',
  TODAY_MEAL_GREEN: '#52A84B',
  FREQ_FILL_IN_RED: '#FA4C4B',
  STOCKUP_PURPLE: '#A446C5',
  // COMMON COLORS
  DARK_OVERLAY: 'rgba(0,0,0,0.12)',
  TEXT_BLACK: '#000000',
  TEXT_WHITE: '#FFFFFF'
};