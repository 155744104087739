import React from 'react';
import PropTypes from 'prop-types';
import { StatCard } from './compound-components/StatCard';
import styles from './SubcategoryHeader.module.css';


export const SubcategoryHeader = ({ 
  heroImage, 
  title, 
  stat1,
  stat2,
  statColor,
  statTextColor,
  fontSizeOverride
}) => {
  return (
    <header className={styles.hero}>
      <img 
        src={heroImage}
        alt={title} 
        className={styles.heroImage}
      />
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle} style={{ fontSize: fontSizeOverride }}>{title}</h1>
        <div className={styles.stats}>
          <StatCard 
            value={stat1.stat} 
            label={stat1.label} 
            color={statColor}
            textColor={statTextColor}
          />
          <StatCard 
            value={stat2.stat} 
            label={stat2.label} 
            color={statColor}
            textColor={statTextColor}
          />
        </div>
      </div>
    </header>
  );
};

SubcategoryHeader.propTypes = {
  heroImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  stat1: PropTypes.shape({
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  stat2: PropTypes.shape({
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  statColor: PropTypes.string.isRequired,
  statTextColor: PropTypes.string.isRequired,
  fontSizeOverride: PropTypes.string,
};
