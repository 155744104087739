import React, { useEffect } from "react";

import SectionIntro from "../../common/page-sections/SectionIntro";

import { occasionsAssets } from "../../common/OccasionsAssets";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Box, Divider, Grid, Typography } from "@mui/material";

import {
  whatItLooksLikeModularContent,
  occasionsWhatItLooksLikeExampleAudioData,
} from "../../../constants/OccasionsConstants";
import OccasionsExampleAudioCard from "../../custom/Cards/OccasionsExampleAudioCard";
import { useParams } from "react-router-dom";
import { getModularCards } from "../../../constants/constants";

function SectionThree() {
  const { t } = useTranslation(i18next.language);
  const { micro } = useParams();

  const exampleAudioCardData = occasionsWhatItLooksLikeExampleAudioData[
    micro
  ].map((item) => {
    return {
      media: item.media,
      title: t(item.title),
      subtitle: t(item.subtitle),
      audioLink: item.audioLink,
    };
  });

  useEffect(() => {
    if (localStorage.getItem("i18nnextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  return (
    <div className="section3">
      <SectionIntro
        heading={t("occasions.template.whatItLooksLike.heading")}
        description={t("occasions.template.whatItLooksLike.description")}
        sectionImages={{
          front: occasionsAssets.whatItLooksLike[micro]["front"]?.(),
          back: occasionsAssets.whatItLooksLike[micro]["back"]?.(),
        }}
      />
      <Box className="occasions-modular-content">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5
        }}>
          <Box className="inthierownowrds-container">
            <Typography className="snapshot-typo" variant="h5">
              {t("demandspace.template.driversAndNeeds.inTheirOwnWords")}
            </Typography>
            <Divider
              sx={{ flex: "1 0 0" }}
              className="snapshot-divider"
            ></Divider>
          </Box>
          <Grid
            container
            gap={2}
            justifyContent="space-between"
            className="whatItLooksLike-modularcontent-container"
          >
            {whatItLooksLikeModularContent[micro].map((modularItem, index) => (
              <Grid
                item
                xs={5.85}
                sx={{ borderRadius: "16px" }}
                key={`occasions-whatitlookslike-modularcard-${index + 1}`}
                className="modular-item"
              >
                {getModularCards(
                  index === 4 ? "occasionsyellow" :"occasions",
                  modularItem.cardType,
                  modularItem.data
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5
        }}>
          <Box className="divider-container">
            <Typography variant="body1" className="divider-text">
              {t("occasions.template.whatItLooksLike.example.heading")}
            </Typography>
            <Divider className="divider" />
          </Box>
          <Grid container className="audio-card-list" gap={2.5}>
            {exampleAudioCardData.map((item, index) => (
              <Grid item xs={3.85} key={`example-audio-card-${index}`}>
                <OccasionsExampleAudioCard cardData={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default SectionThree;
