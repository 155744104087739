import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CategoryCard } from './compound-components/CategoryCard';
import { FlavorSpectrum } from './compound-components/FlavorSpectrum';
import { SegmentCard } from './compound-components/SegmentCard';
import styles from './CategoryExplorer.module.css';


const beerCategorySegments = [
  {label: 'Non-Alcoholic', to: '/categories/beer-hub/non-alcoholic'},
  {label: 'Malts & Ices', to: '/categories/beer-hub/malts-ices'},
  {label: 'American Classics', to: '/categories/beer-hub/american-classic'},
  {label: 'American Light', to: '/categories/beer-hub/american-light'},
  {label: 'American Balanced Light', to: '/categories/beer-hub/american-balanced'},
  {label: 'Imports', to: '/categories/beer-hub/imports'},
  {label: 'Elevated', to: '/categories/beer-hub/elevated'},
  {label: 'IPA/Pale Ale', to: '/categories/beer-hub/ipa-pale-ale'},
  {label: 'Craft All Other', to: '/categories/beer-hub/craft-all-other'},
  {label: 'Flavored', to: '/categories/beer-hub/flavored'},
];

const hardBeverageCategorySegments = [
  {label: 'Seltzers (Spirit-Based)', to: '/categories/hard-bev-hub/seltzer-spirit'},
  {label: 'Seltzers (Malt-Sugar)', to: '/categories/hard-bev-hub/seltzer-malt'},
  {label: 'Hard Cider', to: '/categories/hard-bev-hub/hard-cider'},
  {label: 'FMWBS (Low ABV)', to: '/categories/hard-bev-hub/fmwbs-low-abv'},
  {label: 'FMWBS (High ABV)', to: '/categories/hard-bev-hub/fmwbs-high-abv'},
  {label: 'Spirit-Based Cocktails', to: '/categories/hard-bev-hub/spirit-based-cocktails'},
  {label: 'Multi-Serve', to: '/categories/hard-bev-hub/multi-serve'},
];

export const CategoryExplorer = () => {

  const navigate = useNavigate();

  const clickHandler = useCallback((navigateTo) => {
    navigate(navigateTo);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>explorable categories & segments</h2>
        <p className={styles.description}>
          Learn about the beer and hard beverage categories and segments, how
          they're used, and what makes them distinct within the alcohol
          category framework.
        </p>
      </header>

      <div className={styles.segments}>
        <section className={styles.beerSection}>
          <CategoryCard
            icon="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/dcdacf16f91f2a1b1c64820639c33acf94af1befde0338610bc392d2d8a23e2e"
            title="beer"
            backgroundColor="rgba(242, 206, 25, 0.25)"
          />
          <div className={styles.beerContent}>
            <FlavorSpectrum
              title="beer flavor spectrum"
              gradient="linear-gradient(90deg, #F2CE19 31.5%, #D4B31C 51%, #8B6E0F 72%)"
            />
            <div className={styles.segmentGrid}>
              {beerCategorySegments.map((segment) => (
                <button
                  key={segment.label}
                  className={styles.segmentCardButton}
                  onClick={() => clickHandler(segment.to)}
                >
                  <SegmentCard key={segment.label} title={segment.label} category="beer" />
                </button>
              ))}
            </div>
          </div>
        </section>

        <section className={styles.hardBeverageSection}>
          <CategoryCard
            icon="https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/2a2696b19cacf7161b874251fcddc443dea85bc16fbb5f7d729ea4bf028201b6"
            title="hard beverage"
            backgroundColor="rgba(127, 97, 146, 0.5)"
          />
          <div className={styles.hardBeverageContent}>
            <FlavorSpectrum
              title="hard beverage flavor spectrum"
              gradient="linear-gradient(90deg, #A484BC 31.5%, #7F6192 51%, #4B2F8B 72%)"
            />
            <div className={styles.segmentGrid}>
              {hardBeverageCategorySegments.map((segment) => (
                <button
                  key={segment.label}
                  className={styles.segmentCardButton}
                  onClick={() => clickHandler(segment.to)}
                >
                  <SegmentCard key={segment.label} title={segment.label} category="hardBev" />
                </button>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
