export const hueHubCopy = {
  aboutHue: {
    eyebrowText: 'understanding the framework',
    titleFirstLine: 'what is',
    titleSecondLine: 'hue hub?',
    descriptionFirstParagraph: 'HUE stands for Human Understanding Ecosystem and explores the connected insights that frame the alcohol category.',
    descriptionSecondParagraph: 'Click a segment in the HUE Hub graphic to learn more about how How Consumers Use, How People Organize, and How Shoppers Choose.'
  },
  demandLandscape: {
    eyebrowText: 'how consumers use',
    titleFirstLine: 'demand',
    titleSecondLine: 'landscape',
    descriptionFirstParagraph: "The Demand Landscape serves as a single, people-first source of truth to capture total alcohol demand in the US."
  },
  categoryStructure: {
    eyebrowText: 'how people organize',
    titleFirstLine: 'category',
    titleSecondLine: 'structure',
    descriptionFirstParagraph: "A category-centric map that defines what products are in and what's outside of total alcohol, how those products are organized into categories and segments, and the drivers of this classification."
  },
  tripTypes: {
    eyebrowText: 'how shoppers choose',
    titleFirstLine: 'alcohol',
    titleSecondLine: 'trip types',
    descriptionFirstParagraph: "A shopper-centric map of trips across the alcohol category that creates the frame to understand shoppers' needs and what drives store and product choice across channels"
  },
};

export const features = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/9dcfb3c4d6c38331943a72fcf8852374ecb7f0f9bdd862bfbc33da6f06b5f7aa",
    title: "One set of connected Alcohol frameworks",
    description: "That follow consumers to shoppers, along the path to purchase",
    alt: "Connected frameworks icon"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/f586865ec572492032e22714aec14390252774fbf6d01f50d3e67d92af8583de",
    title: "Common language and shared understanding",
    description: "Segments in categories, people & occasions, and shoppers across channels",
    alt: "Common language icon"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/a1d10ba2040ba8ac087a5eb293dffc86ba5ee7cf5b30334eb4517cd52c650247",
    title: "Linked data sources and immersive learning",
    description: "Used for deep human diagnostics that update over time",
    alt: "Linked data icon"
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/831654c5bae849fa8ed778a88a013b19/4d49794e718e536cc052cb82c28a708e1d965a328017ba7ff15e38a1609ada29",
    title: "Multiple Commercial Use Cases",
    description: "From strategy to activation and execution",
    alt: "Commercial use cases icon"
  }
];

const homePageLinks = {
  tripTypes: '/trip-types',
  categoryStructure: '/categories',
  demandLandscape: '/demand-landscape'
}

export const buttons = [
  {
    buttonCopy: "Explore Trip Types",
    navigateTo: homePageLinks.tripTypes
  },
  {
    buttonCopy: "Explore Category Structure",
    navigateTo: homePageLinks.categoryStructure
  },
  {
    buttonCopy: "Explore Demand Landcape",
    navigateTo: homePageLinks.demandLandscape
  }
];

export const hueSections = ['aboutHue', 'demandLandscape', 'categoryStructure', 'tripTypes'];

export const sectionNavigationMap = {
  'demandLandscape': {
    fullName: 'Demand Landscape',
    navigateTo: homePageLinks.demandLandscape
  },
  'categoryStructure': {
    fullName: 'Category Structure',
    navigateTo: homePageLinks.categoryStructure
  },
  'tripTypes': {
    fullName: 'Alcohol Trip Types',
    navigateTo: homePageLinks.tripTypes
  },
};
