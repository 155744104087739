import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import PieChartComponent from "../../custom/Charts/PieChartComponent";
import BarChartComponent from "../../custom/Charts/BarChartComponent";
import CustomHorizontalBar from "../../custom/Charts/CustomHorizontalBar";
import HorizontalBarChart from "../../custom/Charts/HorizontalBarChart";
import { t } from "i18next";
import SectionIntro from "../../common/page-sections/SectionIntro";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useParams } from "react-router-dom";
import { peopleSegmentAssets } from "../../common/PeopleSegmentAssets";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { downloadsAssets } from "../../common/DownloadsAssets";
import { getTemplateColors } from "../../../constants/constants";

const SectionFour = ({ sectionData }) => {
  const { macroSegment,microSegment } = useParams();
  const theme = useTheme();
  const demographicData = t("common.demographics", {
    returnObjects: true,
  });

  function downloadFile(url, fileName) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadDemographicTable() {
    downloadFile(downloadsAssets.demographicTableData.DataPackSpreadsheet, "dataPack.xlsx");
  }

  const color = getTemplateColors(microSegment);
  
  return (
    <>
      <Box className={`demographic-background ${macroSegment}-dark`}>
        <Box className="demographic-main">
          <SectionIntro
            heading={t("common.demographics.title", {
              microSegment: t(`people-segment.type.${microSegment}`),
            })}
            actionButton={
              <Button
                className="download-all-button outlined-button"
                component="label"
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={downloadDemographicTable}
              >
                {t("common.demographics.downloadDemographicsTable")}
              </Button>
            }
            sectionImages={{
              back: peopleSegmentAssets.demographics[microSegment].back(),
              front: peopleSegmentAssets.demographics[microSegment].front(),
            }}
          />
        </Box>
      </Box>

      <Box className="demographic-background-main">
        <Grid container gap={2} className="demographic-items-container">
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <PieChartComponent
                data={sectionData?.gender}
                title={demographicData.genderGraphHeading}
                colors={["white", theme.palette[microSegment].main]}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <BarChartComponent
                data={sectionData?.age}
                title={demographicData?.ageGraphHeading}
                color={theme.palette[microSegment].main}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <HorizontalBarChart
                data={sectionData?.ethnicity}
                title={demographicData?.ethnicityGraphHeading}
                color={theme.palette[microSegment].main}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <HorizontalBarChart
                data={sectionData?.income}
                title={demographicData?.incomeGraphHeading}
                color={theme.palette[microSegment].main}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <HorizontalBarChart
                data={sectionData?.livingArea}
                title={demographicData?.livingAreaGraphHeading}
                color={theme.palette[microSegment].main}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={4} md={4} lg={4} xl={4}> */}
          <Grid item xs={3.85} className="demographic-items">
            <Box>
              <CustomHorizontalBar
                data={sectionData?.regions}
                title={demographicData?.regionsGraphHeading}
                color="#F6E003"
              />
            </Box>
          </Grid>
        </Grid>
        <Box className="demographic-info">
          <InfoOutlinedIcon sx={{color : color}} />
          <Typography className="demographic-typo">
            {t("common.demographics.demographicsInfo")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SectionFour;
