import React from 'react'
import { LandingHeader } from '../cat-trip-reuse-components/header/LandingHeader';
import { CommercialTeams } from './components/commercial-teams/CommercialTeams';
import { ResearchSection } from './components/research-section/ResearchSection';
import { ShoppingTripSection } from './components/shopping-trips/ShoppingTripSection';
import { ShopQuestions } from './components/shop-questions/ShopQuestions';
import { TripTypesFramework } from './components/trip-types-framework/TripTypesFramework';
import styles from './TripTypesLandingPage.module.css';

export default function TripTypesLandingPage() {

  const introText = "INTRODUCING A-B ALCOHOL TRIP TYPES";
  const title = "A PEOPLE-FIRST GUIDE TO ALCOHOL SHOPPING";
  const imageSrc = "https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fae3b2305807740789c0ae8dd0c961270";
  const altText = "Alcohol trip types header";

  return (
    <div className={styles.container}>
      <LandingHeader 
        title={title} 
        introText={introText} 
        imageSrc={imageSrc} 
        altText={altText} 
        buttonPath="/trip-types/framework"
        buttonCopy="View Alcohol Trip Types"
      />
      <CommercialTeams />
      <ResearchSection />
      <ShoppingTripSection />
      <ShopQuestions />
      <TripTypesFramework />
    </div>
  )
}
