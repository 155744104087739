const getSasKey = () => localStorage.getItem('sasKey') || '';
//Hub Hero Images
// const comfortCraversHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CC_HeroImage.jpg?${getSasKey()}`;
// const loyalTraditionalistsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/LT_HeroImage.jpg?${getSasKey()}`;
// const balancedEnjoyersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/BE_HeroImage.jpg?${getSasKey()}`;
// const carefreeFunLoversHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CFL_HeroImage.jpg?${getSasKey()}`;
// const diligentDiscoverersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/DD_HeroImage.jpg?${getSasKey()}`;
// const proudCelebratorsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/PC_HeroImage.jpg?${getSasKey()}`;
// const creativeExplorersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CE_HeroImage.jpg?${getSasKey()}`;
// const socialCuratorsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/SC_HeroImage.jpg?${getSasKey()}`;

// // Template Images
// //Hero Images
// const easyGoingHangout = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/EH_Hero.jpg?${getSasKey()}`;
// const routineLiving = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/RL_Hero.jpg?${getSasKey()}`;
// const energeticNightOut = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/ENO_Hero.jpg?${getSasKey()}`;
// const upbeatSocialMeal = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/USM_Hero.jpg?${getSasKey()}`;
// const casualGoodTimes = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/CGT_Hero.jpg?${getSasKey()}`;
// const simpleUnplug = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/SU_Hero.jpg?${getSasKey()}`;
// const livelySocialGathering = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/LSG_Hero.jpg?${getSasKey()}`;
// const intentionalUnwind = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/IU_Hero.jpg?${getSasKey()}`;
// const refinedWeekendOut = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/RWO_Hero.jpg?${getSasKey()}`;
// const thoughtFulConnection = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/TC_Hero.jpg?${getSasKey()}`;
// const sensibleSlowdown = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/SS_Hero.jpg?${getSasKey()}`;
// const initimateDiningIn = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/ID_Hero.jpg?${getSasKey()}`;
// const everydayMeal = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/EM_Hero.jpg?${getSasKey()}`;

// //Intro
// const CGT_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const CGT_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const EGH_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const EGH_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const EM_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const EM_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const ENO_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const ENO_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const IU_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const IU_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const RL_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const RL_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const ID_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const ID_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const LSG_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const LSG_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const RWO_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const RWO_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const SU_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const SU_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const SS_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const SS_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const TC_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const TC_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const USM_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const USM_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// //Visual Snapshot Images
// //1.Casual Good Times
// const CGT_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid1.jpg?${getSasKey()}`;

// const CGT_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid2.jpg?${getSasKey()}`;

// const CGT_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid3.jpg?${getSasKey()}`;

// const CGT_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid4.jpg?${getSasKey()}`;

// const CGT_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid5.jpg?${getSasKey()}`;

// const CGT_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid6.jpg?${getSasKey()}`;

// const CGT_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid7.jpg?${getSasKey()}`;

// const CGT_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid8.jpg?${getSasKey()}`;

// const CGT_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid9.jpg?${getSasKey()}`;

// //Easy Going Hangout

// const EH_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid1.jpg?${getSasKey()}`;

// const EH_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid2.jpg?${getSasKey()}`;

// const EH_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid3.jpg?${getSasKey()}`;

// const EH_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid4.jpg?${getSasKey()}`;

// const EH_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid5.jpg?${getSasKey()}`;

// const EH_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid6.jpg?${getSasKey()}`;

// const EH_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid7.jpg?${getSasKey()}`;

// const EH_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid8.jpg?${getSasKey()}`;

// const EH_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid9.jpg?${getSasKey()}`;

// //Energetic Night Out
// const ENO_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid1.jpg?${getSasKey()}`;

// const ENO_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid2.jpg?${getSasKey()}`;

// const ENO_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid3.jpg?${getSasKey()}`;

// const ENO_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid4.jpg?${getSasKey()}`;

// const ENO_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid5.jpg?${getSasKey()}`;

// const ENO_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid6.jpg?${getSasKey()}`;

// const ENO_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid7.jpg?${getSasKey()}`;

// const ENO_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid8.jpg?${getSasKey()}`;

// const ENO_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid9.jpg?${getSasKey()}`;

// //Routine Living
// const RL_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid1.jpg?${getSasKey()}`;

// const RL_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid2.jpg?${getSasKey()}`;

// const RL_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid3.jpg?${getSasKey()}`;

// const RL_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid4.jpg?${getSasKey()}`;

// const RL_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid5.jpg?${getSasKey()}`;

// const RL_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid6.jpg?${getSasKey()}`;

// const RL_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid7.jpg?${getSasKey()}`;

// const RL_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid8.jpg?${getSasKey()}`;

// const RL_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid9.jpg?${getSasKey()}`;

// //Upbeat Social Meal
// const USM_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid1.jpg?${getSasKey()}`;

// const USM_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid2.jpg?${getSasKey()}`;

// const USM_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid3.jpg?${getSasKey()}`;

// const USM_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid4.jpg?${getSasKey()}`;

// const USM_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid5.jpg?${getSasKey()}`;

// const USM_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid6.jpg?${getSasKey()}`;

// const USM_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid7.jpg?${getSasKey()}`;

// const USM_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid8.jpg?${getSasKey()}`;

// const USM_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid9.jpg?${getSasKey()}`;

// //SimpleUnplug

// const SU_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid1.jpg?${getSasKey()}`;

// const SU_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid2.jpg?${getSasKey()}`;

// const SU_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid3.jpg?${getSasKey()}`;

// const SU_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid4.jpg?${getSasKey()}`;

// const SU_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid5.jpg?${getSasKey()}`;

// const SU_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid6.jpg?${getSasKey()}`;

// const SU_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid7.jpg?${getSasKey()}`;

// const SU_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid8.jpg?${getSasKey()}`;

// const SU_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid9.jpg?${getSasKey()}`;

// //Lively Social Gathering

// const LSG_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid1.jpg?${getSasKey()}`;

// const LSG_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid2.jpg?${getSasKey()}`;

// const LSG_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid3.jpg?${getSasKey()}`;

// const LSG_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid4.jpg?${getSasKey()}`;

// const LSG_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid5.jpg?${getSasKey()}`;

// const LSG_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid6.jpg?${getSasKey()}`;

// const LSG_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid7.jpg?${getSasKey()}`;

// const LSG_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid8.jpg?${getSasKey()}`;

// const LSG_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid9.jpg?${getSasKey()}`;

// //Intentional Unwind
// const IU_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid1.jpg?${getSasKey()}`;

// const IU_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid2.jpg?${getSasKey()}`;

// const IU_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid3.jpg?${getSasKey()}`;

// const IU_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid4.jpg?${getSasKey()}`;

// const IU_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid5.jpg?${getSasKey()}`;

// const IU_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid6.jpg?${getSasKey()}`;

// const IU_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid7.jpg?${getSasKey()}`;

// const IU_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid8.jpg?${getSasKey()}`;

// const IU_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid9.jpg?${getSasKey()}`;

// //Refined Weekend Out

// const RWO_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid1.jpg?${getSasKey()}`;

// const RWO_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid2.jpg?${getSasKey()}`;

// const RWO_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid3.jpg?${getSasKey()}`;

// const RWO_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid4.jpg?${getSasKey()}`;

// const RWO_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid5.jpg?${getSasKey()}`;

// const RWO_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid6.jpg?${getSasKey()}`;

// const RWO_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid7.jpg?${getSasKey()}`;

// const RWO_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid8.jpg?${getSasKey()}`;

// const RWO_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid9.jpg?${getSasKey()}`;

// //Thoughtful Connection

// const TC_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid1.jpg?${getSasKey()}`;

// const TC_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid2.jpg?${getSasKey()}`;

// const TC_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid3.jpg?${getSasKey()}`;

// const TC_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid4.jpg?${getSasKey()}`;

// const TC_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid5.jpg?${getSasKey()}`;

// const TC_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid6.jpg?${getSasKey()}`;

// const TC_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid7.jpg?${getSasKey()}`;

// const TC_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid8.jpg?${getSasKey()}`;

// const TC_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid9.jpg?${getSasKey()}`;

// //Sensible Slowdown

// const SS_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid1.jpg?${getSasKey()}`;

// const SS_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid2.jpg?${getSasKey()}`;

// const SS_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid3.jpg?${getSasKey()}`;

// const SS_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid4.jpg?${getSasKey()}`;

// const SS_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid5.jpg?${getSasKey()}`;

// const SS_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid6.jpg?${getSasKey()}`;

// const SS_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid7.jpg?${getSasKey()}`;

// const SS_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid8.jpg?${getSasKey()}`;

// const SS_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid9.jpg?${getSasKey()}`;

// //Intimate Dining In

// const ID_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid1.jpg?${getSasKey()}`;

// const ID_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid2.jpg?${getSasKey()}`;

// const ID_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid3.jpg?${getSasKey()}`;

// const ID_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid4.jpg?${getSasKey()}`;

// const ID_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid5.jpg?${getSasKey()}`;

// const ID_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid6.jpg?${getSasKey()}`;

// const ID_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid7.jpg?${getSasKey()}`;

// const ID_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid8.jpg?${getSasKey()}`;

// const ID_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid9.jpg?${getSasKey()}`;

// //everyday Meal

// const EM_Grid1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid1.jpg?${getSasKey()}`;

// const EM_Grid2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid2.jpg?${getSasKey()}`;

// const EM_Grid3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid3.jpg?${getSasKey()}`;

// const EM_Grid4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid4.jpg?${getSasKey()}`;

// const EM_Grid5 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid5.jpg?${getSasKey()}`;

// const EM_Grid6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid6.jpg?${getSasKey()}`;

// const EM_Grid7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid7.jpg?${getSasKey()}`;

// const EM_Grid8 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid8.jpg?${getSasKey()}`;

// const EM_Grid9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid9.jpg?${getSasKey()}`;

// //The People

// const CGT_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/casual-good-times/CGT_people_Front.jpg?${getSasKey()}`;
// const CGT_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/casual-good-times/CGT_people_Back.jpg?${getSasKey()}`;

// const EH_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/easygoing-hangout/EH_people_Front.jpg?${getSasKey()}`;
// const EH_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/easygoing-hangout/EH_people_Back.jpg?${getSasKey()}`;

// const ENO_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/energetic-nightout/ENO_people_Front.jpg?${getSasKey()}`;
// const ENO_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/energetic-nightout/ENO_people_Back.jpg?${getSasKey()}`;

// const IU_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intentional-unwind/IU_people_Front.jpg?${getSasKey()}`;
// const IU_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intentional-unwind/IU_people_Back.jpg?${getSasKey()}`;

// const ID_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intimate-dining-in/ID_people_Front.jpg?${getSasKey()}`;
// const ID_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intimate-dining-in/ID_people_Back.jpg?${getSasKey()}`;

// const LSG_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/lively-social-gathering/LSG_people_Front.jpg?${getSasKey()}`;
// const LSG_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/lively-social-gathering/LSG_people_Back.jpg?${getSasKey()}`;

// const RWO_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/refined-weekend-out/RWO_people_Front.jpg?${getSasKey()}`;
// const RWO_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/refined-weekend-out/RWO_people_Back.jpg?${getSasKey()}`;

// const RL_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/routine-living/RL_people_Front.jpg?${getSasKey()}`;
// const RL_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/routine-living/RL_people_Back.jpg?${getSasKey()}`;

// const SS_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/sensible-slowdown/SS_people_Front.jpg?${getSasKey()}`;
// const SS_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/sensible-slowdown/SS_people_Back.jpg?${getSasKey()}`;

// const SU_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/simple-unplug/SU_people_Front.jpg?${getSasKey()}`;
// const SU_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/simple-unplug/SU_people_Back.jpg?${getSasKey()}`;

// const TC_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/thoughtful-connection/TC_people_Front.jpg?${getSasKey()}`;
// const TC_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/thoughtful-connection/TC_people_Back.jpg?${getSasKey()}`;

// const USM_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/upbeat-social-meal/USM_people_Front.jpg?${getSasKey()}`;
// const USM_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/upbeat-social-meal/USM_people_Back.jpg?${getSasKey()}`;

// const EM_People_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/everyday-meal/EM_people_Front.jpg?${getSasKey()}`;
// const EM_People_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/everyday-meal/EM_people_Back.jpg?${getSasKey()}`;

// // The Occasions

// const CGT_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Front.jpg?${getSasKey()}`;
// const CGT_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Back.jpg?${getSasKey()}`;

// const EH_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Front.jpg?${getSasKey()}`;
// const EH_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Back.jpg?${getSasKey()}`;

// const ENO_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Front.jpg?${getSasKey()}`;
// const ENO_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Back.jpg?${getSasKey()}`;

// const IU_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Front.jpg?${getSasKey()}`;
// const IU_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Back.jpg?${getSasKey()}`;

// const ID_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Front.jpg?${getSasKey()}`;
// const ID_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Back.jpg?${getSasKey()}`;

// const LSG_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Front.jpg?${getSasKey()}`;
// const LSG_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Back.jpg?${getSasKey()}`;

// const RWO_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Front.jpg?${getSasKey()}`;
// const RWO_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Back.jpg?${getSasKey()}`;

// const RL_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/routine-living/RL_occasions_Front.jpg?${getSasKey()}`;
// const RL_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/routine-living/RL_occasions_Back.jpg?${getSasKey()}`;

// const SS_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Front.jpg?${getSasKey()}`;
// const SS_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Back.jpg?${getSasKey()}`;

// const SU_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Front.jpg?${getSasKey()}`;
// const SU_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Back.jpg?${getSasKey()}`;

// const TC_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Front.jpg?${getSasKey()}`;
// const TC_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Back.jpg?${getSasKey()}`;

// const USM_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Front.jpg?${getSasKey()}`;
// const USM_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Back.jpg?${getSasKey()}`;

// const EM_Occasions_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Front.jpg?${getSasKey()}`;
// const EM_Occasions_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Back.jpg?${getSasKey()}`;

// // Contextual Factor

// const CGT_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Front.jpg?${getSasKey()}`;
// const CGT_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Back.jpg?${getSasKey()}`;

// const EH_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Front.jpg?${getSasKey()}`;
// const EH_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Back.jpg?${getSasKey()}`;

// const ENO_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Front.jpg?${getSasKey()}`;
// const ENO_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Back.jpg?${getSasKey()}`;

// const IU_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Front.jpg?${getSasKey()}`;
// const IU_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Back.jpg?${getSasKey()}`;

// const ID_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Front.jpg?${getSasKey()}`;
// const ID_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Back.jpg?${getSasKey()}`;

// const LSG_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Front.jpg?${getSasKey()}`;
// const LSG_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Back.jpg?${getSasKey()}`;

// const RWO_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Front.jpg?${getSasKey()}`;
// const RWO_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Back.jpg?${getSasKey()}`;

// const RL_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Front.jpg?${getSasKey()}`;
// const RL_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Back.jpg?${getSasKey()}`;

// const SS_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Front.jpg?${getSasKey()}`;
// const SS_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Back.jpg?${getSasKey()}`;

// const SU_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Front.jpg?${getSasKey()}`;
// const SU_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Back.jpg?${getSasKey()}`;

// const TC_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Front.jpg?${getSasKey()}`;
// const TC_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Back.jpg?${getSasKey()}`;

// const USM_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Front.jpg?${getSasKey()}`;
// const USM_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Back.jpg?${getSasKey()}`;

// const EM_ContextualFactor_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Front.jpg?${getSasKey()}`;
// const EM_ContextualFactor_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Back.jpg?${getSasKey()}`;

// // Drivers and Needs

// const CGT_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Front.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Back.jpg?${getSasKey()}`;

// const EH_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Front.jpg?${getSasKey()}`;
// const EH_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Back.jpg?${getSasKey()}`;

// const ENO_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Front.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Back.jpg?${getSasKey()}`;

// const IU_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Front.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Back.jpg?${getSasKey()}`;

// const ID_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Front.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Back.jpg?${getSasKey()}`;

// const LSG_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Front.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Back.jpg?${getSasKey()}`;

// const RWO_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Front.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Back.jpg?${getSasKey()}`;

// const RL_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd8486bcf4ecd4458978e04905d2bede6`;
// const RL_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/routine-living/RL_drivers-needs_Back.jpg?${getSasKey()}`;

// const SS_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Front.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Back.jpg?${getSasKey()}`;

// const SU_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/simple-unplug/SU_drivers-needs_Front.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2b5f7560e6d7491c89ab38622c993661`;

// const TC_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Front.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Back.jpg?${getSasKey()}`;

// const USM_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Front.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Back.jpg?${getSasKey()}`;

// const EM_DriversNeeds_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Front.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Back.jpg?${getSasKey()}`;
// //ModularContent
// //Easygoing Hangout
// const EGH_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_10.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_2.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_3.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_4.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_6.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_7.jpg?${getSasKey()}`;
// const EGH_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_9.jpg?${getSasKey()}`;

// // Routine Living
// const RL_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_10.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_2.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_3.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_4.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_6.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_7.jpg?${getSasKey()}`;
// const RL_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_9.jpg?${getSasKey()}`;

// //Energetic Night Out
// const ENO_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_10.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_2.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_3.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_4.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_6.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_7.jpg?${getSasKey()}`;
// const ENO_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_9.jpg?${getSasKey()}`;

// // Upbeat Social Meal
// const USM_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_10.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_2.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_3.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_4.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_6.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_7.jpg?${getSasKey()}`;
// const USM_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_9.jpg?${getSasKey()}`;

// // Casual Good Times

// const CGT_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_10.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_2.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_3.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_4.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_6.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_7.jpg?${getSasKey()}`;
// const CGT_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_9.jpg?${getSasKey()}`;

// //Simple Unplug
// const SU_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_10.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_2.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_3.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_4.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_6.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_7.jpg?${getSasKey()}`;
// const SU_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_9.jpg?${getSasKey()}`;

// // Lively Social Gathering

// const LSG_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_10.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_2.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_3.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_4.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_6.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_7.jpg?${getSasKey()}`;
// const LSG_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_9.jpg?${getSasKey()}`;

// //Intentional Unwind
// const IU_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_10.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_2.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_3.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_4.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_6.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_7.jpg?${getSasKey()}`;
// const IU_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_9.jpg?${getSasKey()}`;

// //Refined Weekend Out
// const RWO_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_10.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_2.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_3.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_4.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_6.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_7.jpg?${getSasKey()}`;
// const RWO_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_9.jpg?${getSasKey()}`;

// // Thoughtful Connection
// const TC_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_10.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_2.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_3.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_4.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_6.jpg?${getSasKey()}`;
// const TC_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F16c7799ccf2d400c9538dad7e4e248fc`;
// const TC_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_9.jpg?${getSasKey()}`;

// //Sensible Slowdown
// const SS_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_10.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_2.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_3.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_4.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_6.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_7.jpg?${getSasKey()}`;
// const SS_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_9.jpg?${getSasKey()}`;

// // Intimate Dining In
// const ID_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_10.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_2.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_3.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_4.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_6.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_7.jpg?${getSasKey()}`;
// const ID_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_9.jpg?${getSasKey()}`;

// // everyday Meal In
// const EM_DriversNeeds_Mod10 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_10.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_2.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_3.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod4 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_4.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod6 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_6.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod7 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_7.jpg?${getSasKey()}`;
// const EM_DriversNeeds_Mod9 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_9.jpg?${getSasKey()}`;

// // Consumption

// const CGT_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/casual-good-times/CGT_consumption_Front.jpg?${getSasKey()}`;
// const CGT_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/casual-good-times/CGT_consumption_Back.jpg?${getSasKey()}`;

// const EH_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Front.jpg?${getSasKey()}`;
// const EH_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Back.jpg?${getSasKey()}`;

// const ENO_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Front.jpg?${getSasKey()}`;
// const ENO_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Back.jpg?${getSasKey()}`;

// const IU_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intentional-unwind/IU_consumption_Front.jpg?${getSasKey()}`;
// const IU_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intentional-unwind/IU_consumption_Back.jpg?${getSasKey()}`;

// const ID_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Front.jpg?${getSasKey()}`;
// const ID_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Back.jpg?${getSasKey()}`;

// const LSG_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Front.jpg?${getSasKey()}`;
// const LSG_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Back.jpg?${getSasKey()}`;

// const RWO_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/refined-weekend-out/RWO_consumption_Front.jpg?${getSasKey()}`;
// const RWO_Consumption_Back = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5ab6faae0bf34f1ba9088fe5454fc694`;

// const RL_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/routine-living/RL_consumption_Front.jpg?${getSasKey()}`;
// const RL_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/routine-living/RL_consumption_Back.jpg?${getSasKey()}`;

// const SS_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Front.jpg?${getSasKey()}`;
// const SS_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Back.jpg?${getSasKey()}`;

// const SU_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/simple-unplug/SU_consumption_Front.jpg?${getSasKey()}`;
// const SU_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/simple-unplug/SU_consumption_Back.jpg?${getSasKey()}`;

// const TC_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Front.jpg?${getSasKey()}`;
// const TC_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Back.jpg?${getSasKey()}`;

// const USM_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Front.jpg?${getSasKey()}`;
// const USM_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Back.jpg?${getSasKey()}`;

// const EM_Consumption_Front = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/everyday-meal/EM_consumption_Front.jpg?${getSasKey()}`;
// const EM_Consumption_Back = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/everyday-meal/EM_consumption_Back.jpg?${getSasKey()}`;

// //How they Drink

// //VerticalImage are commented and for future use we have passed verticalImage as null, we can uncomment and use the verticalImage if needed
// //Casual Good Times

// const CGT_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Samantha.jpg?${getSasKey()}`;
// const CGT_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_2.jpg?${getSasKey()}`;
// const CGT_Carousel1_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_3.jpg?${getSasKey()}`;
// // const CGT_Carousel1_VerticalImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_2.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car1_Video.mp4?${getSasKey()}`;

// const CGT_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Sam.jpg?${getSasKey()}`;
// const CGT_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_1.jpg?${getSasKey()}`;
// const CGT_Carousel2_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_3.jpg?${getSasKey()}`;
// // const CGT_Carousel2_VerticalImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_2.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car2_Video.mp4?${getSasKey()}`;

// const CGT_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Jessica.jpg?${getSasKey()}`;
// const CGT_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_1.jpg?${getSasKey()}`;
// const CGT_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_3.jpg?${getSasKey()}`;
// // const CGT_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fefc3309095fd4468849e41934b769c42%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_36}&alt=media&optimized=true`;

// const CGT_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Leo.jpg?${getSasKey()}`;
// const CGT_Carousel4_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_1.jpg?${getSasKey()}`;
// const CGT_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_2.jpg?${getSasKey()}`;
// // const CGT_Carousel4_VerticalImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_2.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car4_Video.mp4?${getSasKey()}`;

// const CGT_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Andrea.jpg?${getSasKey()}`;
// const CGT_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_3.jpg?${getSasKey()}`;
// const CGT_Carousel5_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_2.jpg?${getSasKey()}`;
// // const CGT_Carousel5_VerticalImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_1.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car5_Video.mp4?${getSasKey()}`;

// const CGT_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Tyler.jpg?${getSasKey()}`;
// const CGT_Carousel6_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_3.jpg?${getSasKey()}`;
// const CGT_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_2.jpg?${getSasKey()}`;
// // const CGT_Carousel6_VerticalImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_1.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const CGT_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car6_Video.mp4?${getSasKey()}`;

// //Easy Going Hangout

// const EGH_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Jamie.jpg?${getSasKey()}`;
// const EGH_Carousel1_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_3.jpg?${getSasKey()}`;
// const EGH_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_2.jpg?${getSasKey()}`;
// // const EGH_Carousel1_VerticalImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_1.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EGH_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car1_Video.mp4?${getSasKey()}`;

// const EGH_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Karl.jpg?${getSasKey()}`;
// const EGH_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car2_1.jpg?${getSasKey()}`;
// const EGH_Carousel2_SquareImage3 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feea20152e262491197e79f652ea5a525`;
// // const EGH_Carousel2_VerticalImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car2_2.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EGH_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car2_Video.mp4?${getSasKey()}`;

// const EGH_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Nick_IA.jpg?${getSasKey()}`;
// const EGH_Carousel3_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_2.jpg?${getSasKey()}`;
// const EGH_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_1.jpg?${getSasKey()}`;
// // const EGH_Carousel3_VerticalImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_2.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EGH_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car3_Video.mp4?${getSasKey()}`;

// const EGH_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Marti.jpg?${getSasKey()}`;
// const EGH_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_1.jpg?${getSasKey()}`;
// const EGH_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_2.jpg?${getSasKey()}`;
// const EGH_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car4_Video.mp4?${getSasKey()}`;

// const EGH_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Brad.jpg?${getSasKey()}`;
// const EGH_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_2.jpg?${getSasKey()}`;
// const EGH_Carousel5_SquareImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_3.jpg?${getSasKey()}`;
// // const EGH_Carousel5_VerticalImage4 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_4.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EGH_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car5_Video.mp4?${getSasKey()}`;

// const EGH_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Yessica.jpg?${getSasKey()}`;
// const EGH_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car3_Video.mp4?${getSasKey()}`;
// const EGH_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car6_2.jpg?${getSasKey()}`;
// const EGH_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car6_Video.mp4?${getSasKey()}`;

// //Routine Living

// const RL_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Jamie.jpg?${getSasKey()}`;
// const RL_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car1_3.jpg?${getSasKey()}`;
// const RL_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car1_2.jpg?${getSasKey()}`;
// // const RL_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car1_Video.mp4?${getSasKey()}`;

// const RL_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Karl.jpg?${getSasKey()}`;
// const RL_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9e18565ce28849b682ad1f80d0a0adc8`;
// const RL_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F31b6589e81eb43339e63257a77cc19a8`;
// // const RL_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F331c334dca6b4d01a7969a10068e1013%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_37}&alt=media&optimized=true`;

// const RL_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Nick_IA.jpg?${getSasKey()}`;
// const RL_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car3_1.jpg?${getSasKey()}`;
// const RL_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car3_1.jpg?${getSasKey()}`;
// // const RL_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car3_Video.mp4?${getSasKey()}`;

// const RL_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Marti.jpg?${getSasKey()}`;
// const RL_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car4_1.jpg?${getSasKey()}`;
// const RL_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car4_3.jpg?${getSasKey()}`;
// // const RL_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car4_Video.mp4?${getSasKey()}`;

// const RL_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Brad.jpg?${getSasKey()}`;
// const RL_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9782dd4eece547b9a9503d30866aed2a`;
// const RL_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3bc10f3545c045f5bb3d5c8dbe32c408`;
// // const RL_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car5_Video.mp4?${getSasKey()}`;

// const RL_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Yessica.jpg?${getSasKey()}`;
// const RL_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car6_1.jpg?${getSasKey()}`;
// const RL_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car6_3.jpg?${getSasKey()}`;
// // const RL_Carousel6_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/routine-living/images/RL_car6_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RL_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car6_Video.mp4?${getSasKey()}`;

// //Energetic Night Out

// const ENO_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Nick_NY.jpg?${getSasKey()}`;
// const ENO_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_1.jpg?${getSasKey()}`;
// const ENO_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_2.jpg?${getSasKey()}`;
// // const ENO_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car1_Video.mp4?${getSasKey()}`;

// const ENO_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Hector.jpg?${getSasKey()}`;
// const ENO_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_1.jpg?${getSasKey()}`;
// const ENO_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_2.jpg?${getSasKey()}`;
// // const ENO_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa5920331a4fb4e20a44b86d4bd04da01%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_38}&alt=media&optimized=true`;

// const ENO_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Tyler.jpg?${getSasKey()}`;
// const ENO_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_1.jpg?${getSasKey()}`;
// const ENO_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_2.jpg?${getSasKey()}`;
// // const ENO_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car3_Video.mp4?${getSasKey()}`;

// const ENO_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Samantha.jpg?${getSasKey()}`;
// const ENO_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_1.jpg?${getSasKey()}`;
// const ENO_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_3.jpg?${getSasKey()}`;
// // const ENO_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car4_Video.mp4?${getSasKey()}`;

// const ENO_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Andrea.jpg?${getSasKey()}`;
// const ENO_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_1.jpg?${getSasKey()}`;
// const ENO_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_2.jpg?${getSasKey()}`;
// // const ENO_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car5_Video.mp4?${getSasKey()}`;

// const ENO_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jessica.jpg?${getSasKey()}`;
// const ENO_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_3.jpg?${getSasKey()}`;
// const ENO_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_2.jpg?${getSasKey()}`;
// // const ENO_Carousel6_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car6_Video.mp4?${getSasKey()}`;

// const ENO_Carousel7_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jourdan.jpg?${getSasKey()}`;
// const ENO_Carousel7_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_1.jpg?${getSasKey()}`;
// const ENO_Carousel7_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_2.jpg?${getSasKey()}`;
// // const ENO_Carousel7_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const ENO_Carousel7_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car7_Video.mp4?${getSasKey()}`;

// const ENO_Carousel8_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Craig.jpg?${getSasKey()}`;
// const ENO_Carousel8_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_1.jpg?${getSasKey()}`;
// const ENO_Carousel8_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_2.jpg?${getSasKey()}`;
// // const ENO_Carousel8_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const ENO_Carousel8_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car8_Video.mp4?${getSasKey()}`;

// //Upbeat Social Meal

// const USM_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Nick_NY.jpg?${getSasKey()}`;
// const USM_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_1.jpg?${getSasKey()}`;
// const USM_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_2.jpg?${getSasKey()}`;
// // const USM_Carousel1_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const USM_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car1_Video.mp4?${getSasKey()}`;

// const USM_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Andrea.jpg?${getSasKey()}`;
// const USM_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_1.jpg?${getSasKey()}`;
// const USM_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_2.jpg?${getSasKey()}`;
// // const USM_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const USM_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car2_Video.mp4?${getSasKey()}`;

// const USM_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Jourdan.jpg?${getSasKey()}`;
// const USM_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_1.jpg?${getSasKey()}`;
// const USM_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_3.jpg?${getSasKey()}`;
// // const USM_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const USM_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car3_Video.mp4?${getSasKey()}`;

// const USM_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Tyler.jpg?${getSasKey()}`;
// const USM_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff445d2ee4d5f456981bdec704b3780bd`;
// const USM_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F774f876cba614152b10dd6e49dfbd865`;
// // const USM_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const USM_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car4_Video.mp4?${getSasKey()}`;

// const USM_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Craig.jpg?${getSasKey()}`;
// const USM_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3a9af699c44c4f1683864dbb3d5db3cf`;
// const USM_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car5_2.jpg?${getSasKey()}`;
// // const USM_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const USM_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car5_Video.mp4?${getSasKey()}`;

// const USM_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Hector.jpg?${getSasKey()}`;
// const USM_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F54f662ee7a754d408b4ccc77b7f85a10`;
// const USM_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fec14c4441f2c450d9d09797a3a058fee`;
// // const USM_Carousel6_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car6_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const USM_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car6_Video.mp4?${getSasKey()}`;

// //Simple Unplug

// const SU_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Andrea.jpg?${getSasKey()}`;
// const SU_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_1.jpg?${getSasKey()}`;
// const SU_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_2.jpg?${getSasKey()}`;
// // const SU_Carousel1_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SU_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car1_Video.mp4?${getSasKey()}`;

// const SU_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Jessica.jpg?${getSasKey()}`;
// const SU_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_1.jpg?${getSasKey()}`;
// // const SU_Carousel2_SquareImage2 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_2.jpg?${localStorage.getItem( "sasKey/ )}`;
// // const SU_Carousel2_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SU_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car2_Video.mp4?${getSasKey()}`;

// const SU_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Leo.jpg?${getSasKey()}`;
// const SU_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_1.jpg?${getSasKey()}`;
// const SU_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_2.jpg?${getSasKey()}`;
// // const SU_Carousel3_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SU_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car3_Video.mp4?${getSasKey()}`;

// const SU_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Tyler.jpg?${getSasKey()}`;
// const SU_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_1.jpg?${getSasKey()}`;
// const SU_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_2.jpg?${getSasKey()}`;
// // const SU_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const SU_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car4_Video.mp4?${getSasKey()}`;

// const SU_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Samantha.jpg?${getSasKey()}`;
// const SU_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_1.jpg?${getSasKey()}`;
// const SU_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_2.jpg?${getSasKey()}`;
// // const SU_Carousel5_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SU_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car5_Video.mp4?${getSasKey()}`;

// const SU_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Bledar.jpg?${getSasKey()}`;
// const SU_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_1.jpg?${getSasKey()}`;
// const SU_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_2.jpg?${getSasKey()}`;
// // const SU_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SU_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car6_Video.mp4?${getSasKey()}`;

// //Lively Social Gathering

// const LSG_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Nick_NY.jpg?${getSasKey()}`;
// const LSG_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_1.jpg?${getSasKey()}`;
// const LSG_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_2.jpg?${getSasKey()}`;
// // const LSG_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const LSG_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car1_Video.mp4?${getSasKey()}`;

// const LSG_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Hector.jpg?${getSasKey()}`;
// const LSG_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_3.jpg?${getSasKey()}`;
// const LSG_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_2.jpg?${getSasKey()}`;
// // const LSG_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const LSG_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car2_Video.mp4?${getSasKey()}`;

// const LSG_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Kiana.jpg?${getSasKey()}`;
// const LSG_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_1.jpg?${getSasKey()}`;
// const LSG_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_2.jpg?${getSasKey()}`;
// // const LSG_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const LSG_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car3_Video.mp4?${getSasKey()}`;

// const LSG_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Jourdan.jpg?${getSasKey()}`;
// const LSG_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_1.jpg?${getSasKey()}`;
// const LSG_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_3.jpg?${getSasKey()}`;
// // const LSG_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const LSG_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car4_Video.mp4?${getSasKey()}`;

// const LSG_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Craig.jpg?${getSasKey()}`;
// const LSG_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_1.jpg?${getSasKey()}`;
// const LSG_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_2.jpg?${getSasKey()}`;
// // const LSG_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const LSG_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car5_Video.mp4?${getSasKey()}`;

// const LSG_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Daniel_NY.jpg?${getSasKey()}`;
// const LSG_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_1.jpg?${getSasKey()}`;
// const LSG_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_2.jpg?${getSasKey()}`;
// // const LSG_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const LSG_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car6_Video.mp4?${getSasKey()}`;

// //Intentional Unwind

// const IU_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Nick_NY.jpg?${getSasKey()}`;
// const IU_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_1.jpg?${getSasKey()}`;
// const IU_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_2.jpg?${getSasKey()}`;
// // const IU_Carousel1_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IU_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car1_Video.mp4?${getSasKey()}`;

// const IU_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Hector.jpg?${getSasKey()}`;
// const IU_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_1.jpg?${getSasKey()}`;
// const IU_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_2.jpg?${getSasKey()}`;
// // const IU_Carousel2_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IU_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car2_Video.mp4?${getSasKey()}`;

// const IU_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Kiana.jpg?${getSasKey()}`;
// const IU_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_1.jpg?${getSasKey()}`;
// const IU_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_2.jpg?${getSasKey()}`;
// // const IU_Carousel3_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IU_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car3_Video.mp4?${getSasKey()}`;

// const IU_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Jourdan.jpg?${getSasKey()}`;
// const IU_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_1.jpg?${getSasKey()}`;
// const IU_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_2.jpg?${getSasKey()}`;
// // const IU_Carousel4_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IU_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car4_Video.mp4?${getSasKey()}`;

// const IU_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Craig.jpg?${getSasKey()}`;
// const IU_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_3.jpg?${getSasKey()}`;
// const IU_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_2.jpg?${getSasKey()}`;
// // const IU_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IU_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car5_Video.mp4?${getSasKey()}`;

// const IU_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Daniel_NY.jpg?${getSasKey()}`;
// const IU_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_1.jpg?${getSasKey()}`;
// const IU_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_2.jpg?${getSasKey()}`;
// // const IU_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IU_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car6_Video.mp4?${getSasKey()}`;

// //Refined Weekend Out

// const RWO_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Danielle.jpg?${getSasKey()}`;
// const RWO_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_1.jpg?${getSasKey()}`;
// const RWO_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_3.jpg?${getSasKey()}`;
// // const RWO_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RWO_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F97a0d53fcbc442cd83dcc15b38b97630%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_39}&alt=media&optimized=true`;

// const RWO_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Maria.jpg?${getSasKey()}`;
// const RWO_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_1.jpg?${getSasKey()}`;
// const RWO_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_2.jpg?${getSasKey()}`;
// // const RWO_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RWO_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car2_Video.mp4?${getSasKey()}`;

// const RWO_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Brandon.jpg?${getSasKey()}`;
// const RWO_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_1.jpg?${getSasKey()}`;
// const RWO_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_2.jpg?${getSasKey()}`;
// // const RWO_Carousel3_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const RWO_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car3_Video.mp4?${getSasKey()}`;

// const RWO_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Sharyn.jpg?${getSasKey()}`;
// const RWO_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_1.jpg?${getSasKey()}`;
// const RWO_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_2.jpg?${getSasKey()}`;
// // const RWO_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RWO_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car4_Video.mp4?${getSasKey()}`;

// const RWO_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Jeannine.jpg?${getSasKey()}`;
// const RWO_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_1.jpg?${getSasKey()}`;
// const RWO_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_2.jpg?${getSasKey()}`;
// // const RWO_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const RWO_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car5_Video.mp4?${getSasKey()}`;

// const RWO_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_1.jpg?${getSasKey()}`;
// const RWO_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_2.jpg?${getSasKey()}`;

// const RWO_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Kelly.jpg?${getSasKey()}`;
// // const RWO_Carousel6_SquareImage1 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_1.jpg?${localStorage.getItem( "sasKey/ )}`;
// // const RWO_Carousel6_SquareImage2 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_2.jpg?${localStorage.getItem( "sasKey/ )}`;
// // const RWO_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// // const RWO_Carousel6_VideoClip = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car6_Video.mp4?${localStorage.getItem( "sasKey/ )}`;

// //Thoughtful Connection

// const TC_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Danielle.jpg?${getSasKey()}`;
// const TC_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_1.jpg?${getSasKey()}`;
// const TC_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_3.jpg?${getSasKey()}`;
// // const TC_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const TC_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car1_Video.mp4?${getSasKey()}`;

// const TC_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Daniel_IL.jpg?${getSasKey()}`;
// const TC_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_1.jpg?${getSasKey()}`;
// const TC_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_2.jpg?${getSasKey()}`;
// // const TC_Carousel2_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const TC_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car2_Video.mp4?${getSasKey()}`;

// const TC_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_An.jpg?${getSasKey()}`;
// const TC_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_1.jpg?${getSasKey()}`;
// const TC_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_2.jpg?${getSasKey()}`;
// // const TC_Carousel3_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const TC_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car3_Video.mp4?${getSasKey()}`;

// const TC_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Sharyn.jpg?${getSasKey()}`;
// const TC_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_1.jpg?${getSasKey()}`;
// const TC_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_2.jpg?${getSasKey()}`;
// // const TC_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const TC_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car4_Video.mp4?${getSasKey()}`;

// const TC_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jeannine.jpg?${getSasKey()}`;
// const TC_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_1.jpg?${getSasKey()}`;
// const TC_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_2.jpg?${getSasKey()}`;
// // const TC_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const TC_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car5_Video.mp4?${getSasKey()}`;

// const TC_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jon.jpg?${getSasKey()}`;
// const TC_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_1.jpg?${getSasKey()}`;
// const TC_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_2.jpg?${getSasKey()}`;
// // const TC_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const TC_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car6_Video.mp4?${getSasKey()}`;

// //Sensible Slowdown

// const SS_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Danielle.jpg?${getSasKey()}`;
// const SS_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_1.jpg?${getSasKey()}`;
// const SS_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_2.jpg?${getSasKey()}`;
// // const SS_Carousel1_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SS_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car1_Video.mp4?${getSasKey()}`;

// const SS_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Maria.jpg?${getSasKey()}`;
// const SS_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F06630f7f060b4b32951b8400def57933`;
// const SS_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffa015778cc654ad09ad3ea451c7fd9b2`;
// // const SS_Carousel2_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car2_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SS_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car2_Video.mp4?${getSasKey()}`;

// const SS_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Brandon.jpg?${getSasKey()}`;
// const SS_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_1.jpg?${getSasKey()}`;
// const SS_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_2.jpg?${getSasKey()}`;
// // const SS_Carousel3_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SS_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car3_Video.mp4?${getSasKey()}`;

// const SS_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Sharyn.jpg?${getSasKey()}`;
// const SS_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_1.jpg?${getSasKey()}`;
// const SS_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_2.jpg?${getSasKey()}`;
// // const SS_Carousel4_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SS_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car4_Video.mp4?${getSasKey()}`;

// const SS_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Jeannine.jpg?${getSasKey()}`;
// const SS_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_1.jpg?${getSasKey()}`;
// const SS_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_2.jpg?${getSasKey()}`;
// // const SS_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const SS_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car5_Video.mp4?${getSasKey()}`;

// const SS_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Kelly.jpg?${getSasKey()}`;
// const SS_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_1.jpg?${getSasKey()}`;
// const SS_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_2.jpg?${getSasKey()}`;
// // const SS_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const SS_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F636b01e943b5480baae92fab96b9a095%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_40}&alt=media&optimized=true`;

// //Initimate Dining In

// const IDI_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Daniel_IL.jpg?${getSasKey()}`;
// const IDI_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_3.jpg?${getSasKey()}`;
// const IDI_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_2.jpg?${getSasKey()}`;
// // const IDI_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IDI_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car1_Video.mp4?${getSasKey()}`;

// const IDI_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Melissa.jpg?${getSasKey()}`;
// const IDI_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_3.jpg?${getSasKey()}`;
// const IDI_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_2.jpg?${getSasKey()}`;
// // const IDI_Carousel2_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IDI_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car2_Video.mp4?${getSasKey()}`;

// const IDI_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Anahi.jpg?${getSasKey()}`;
// const IDI_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_1.jpg?${getSasKey()}`;
// const IDI_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_2.jpg?${getSasKey()}`;
// // const IDI_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IDI_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car3_Video.mp4?${getSasKey()}`;

// const IDI_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Sam.jpg?${getSasKey()}`;
// const IDI_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_1.jpg?${getSasKey()}`;
// const IDI_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_3.jpg?${getSasKey()}`;
// // const IDI_Carousel4_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IDI_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car4_Video.mp4?${getSasKey()}`;

// const IDI_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Kat.jpg?${getSasKey()}`;
// const IDI_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_1.jpg?${getSasKey()}`;
// const IDI_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_2.jpg?${getSasKey()}`;
// // const IDI_Carousel5_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const IDI_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car5_Video.mp4?${getSasKey()}`;

// const IDI_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Elizabeth.jpg?${getSasKey()}`;
// const IDI_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_1.jpg?${getSasKey()}`;
// const IDI_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_2.jpg?${getSasKey()}`;
// // const IDI_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const IDI_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car6_Video.mp4?${getSasKey()}`;

// //Everyday Meal

// const EM_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Danielle.jpg?${getSasKey()}`;
// const EM_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_1.jpg?${getSasKey()}`;
// const EM_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_2.jpg?${getSasKey()}`;
// // const EM_Carousel1_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EM_Carousel1_VideoClip = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffd0d4a2b3dab427baed9a3293f1ab400%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_41}&alt=media&optimized=true`;

// const EM_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Steven.jpg?${getSasKey()}`;
// const EM_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_1.jpg?${getSasKey()}`;
// const EM_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_2.jpg?${getSasKey()}`;
// // const EM_Carousel2_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const EM_Carousel2_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car2_Video.mp4?${getSasKey()}`;

// const EM_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Leo.jpg?${getSasKey()}`;
// const EM_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_1.jpg?${getSasKey()}`;
// const EM_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_2.jpg?${getSasKey()}`;
// // const EM_Carousel3_VerticalImage3 = `${window.env.REACT_APP_AZURE_STORAGE_URL//   }/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_3.jpg?${localStorage.getItem(   "sasK
// //   )}`;
// const EM_Carousel3_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car3_Video.mp4?${getSasKey()}`;

// const EM_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Erika.jpg?${getSasKey()}`;
// const EM_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_1.jpg?${getSasKey()}`;
// const EM_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_2.jpg?${getSasKey()}`;
// // const EM_Carousel4_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const EM_Carousel4_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car4_Video.mp4?${getSasKey()}`;

// const EM_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Noha.jpg?${getSasKey()}`;
// const EM_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_1.jpg?${getSasKey()}`;
// const EM_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_2.jpg?${getSasKey()}`;
// // const EM_Carousel5_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const EM_Carousel5_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car5_Video.mp4?${getSasKey()}`;

// const EM_Carousel6_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Mike.jpg?${getSasKey()}`;
// const EM_Carousel6_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_1.jpg?${getSasKey()}`;
// const EM_Carousel6_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_2.jpg?${getSasKey()}`;
// // const EM_Carousel6_VerticalImage3 = `${
// //   window.env.REACT_APP_AZURE_STORAGE_URL// }/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_3.jpg?${localStorage.getItem( "sasKey/ )}`;
// const EM_Carousel6_VideoClip = `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car6_Video.mp4?${getSasKey()}`;

export const demandSpaceAssets = {
  hubHeroImages: {
    comfortCraversHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CC_HeroImage.jpg?${getSasKey()}`,
    loyalTraditionalistsHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/LT_HeroImage.jpg?${getSasKey()}`,
    balancedEnjoyersHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/BE_HeroImage.jpg?${getSasKey()}`,
    diligentDiscoverersHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/DD_HeroImage.jpg?${getSasKey()}`,
    carefreeFunLoversHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CFL_HeroImage.jpg?${getSasKey()}`,
    proudCelebratorsHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/PC_HeroImage.jpg?${getSasKey()}`,
    creativeExplorersHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CE_HeroImage.jpg?${getSasKey()}`,
    socialCuratorsHero: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/SC_HeroImage.jpg?${getSasKey()}`,
  },
  heroImages: {
    Fram_DS_5: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/CGT_Hero.jpg?${getSasKey()}`,
    Fram_DS_1: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/EH_Hero.jpg?${getSasKey()}`,
    Fram_DS_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/ENO_Hero.jpg?${getSasKey()}`,
    Fram_DS_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/USM_Hero.jpg?${getSasKey()}`,
    Fram_DS_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/RWO_Hero.jpg?${getSasKey()}`,
    Fram_DS_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/LSG_Hero.jpg?${getSasKey()}`,
    Fram_DS_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/TC_Hero.jpg?${getSasKey()}`,
    Fram_DS_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/RL_Hero.jpg?${getSasKey()}`,
    Fram_DS_12: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/ID_Hero.jpg?${getSasKey()}`,
    Fram_DS_13: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/EM_Hero.jpg?${getSasKey()}`,
    Fram_DS_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/SU_Hero.jpg?${getSasKey()}`,
    Fram_DS_8: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/IU_Hero.jpg?${getSasKey()}`,
    Fram_DS_11: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/common/SS_Hero.jpg?${getSasKey()}`,
  },
  intro: {
    Fram_DS_5: {
      //Casual Good Times
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/casual-good-times/video/CGT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      //Easy Going Hangout
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/easygoing-hangout/video/EGH_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      //Energetic Night Out
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/energetic-nightout/video/ENO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      //Upbeat Social Meal
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/upbeat-social-meal/video/USM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      //Refined Weekend Out
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/refined-weekend-out/video/RWO_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      //Lively Social Gathering
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/lively-social-gathering/video/LSG_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      //Thoughtful Connection
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/thoughtful-connection/video/TC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      //Routine Living
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/routine-living/video/RL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      //Intimate Dining
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intimate-dining-in/video/ID_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      //Everyday Meal
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/everyday-meal/video/EM_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      //Simple Unplug
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/simple-unplug/video/SU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      //Intentional Unwind
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/intentional-unwind/video/IU_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      //Sensible Slowdown
      featuredPersonVideo: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/intro/sensible-slowdown/video/SS_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`,
    },
  },
  visualSnapshot: {
    Fram_DS_5: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/casual-good-times/CGT_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_1: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/easygoing-hangout/EH_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_3: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/energetic-nightout/ENO_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_2: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/routine-living/RL_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_4: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/upbeat-social-meal/USM_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_6: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/simple-unplug/SU_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_7: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/lively-social-gathering/LSG_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_8: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intentional-unwind/IU_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_9: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/refined-weekend-out/RWO_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_10: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/thoughtful-connection/TC_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_11: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/sensible-slowdown/SS_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_12: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/intimate-dining-in/ID_Grid9.jpg?${getSasKey()}`,
      },
    ],
    Fram_DS_13: [
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid1.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid2.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid3.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid4.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid5.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid6.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid7.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid8.jpg?${getSasKey()}`,
      },
      {
        grid: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/visual-snapshot/everyday-meal/EM_Grid9.jpg?${getSasKey()}`,
      },
    ],
  },
  thePeople: {
    Fram_DS_5: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/casual-good-times/CGT_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/casual-good-times/CGT_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/easygoing-hangout/EH_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/easygoing-hangout/EH_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/routine-living/RL_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/routine-living/RL_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/energetic-nightout/ENO_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/energetic-nightout/ENO_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/upbeat-social-meal/USM_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/upbeat-social-meal/USM_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/simple-unplug/SU_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/simple-unplug/SU_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/lively-social-gathering/LSG_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/lively-social-gathering/LSG_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intentional-unwind/IU_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intentional-unwind/IU_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/refined-weekend-out/RWO_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/refined-weekend-out/RWO_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/thoughtful-connection/TC_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/thoughtful-connection/TC_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/sensible-slowdown/SS_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/sensible-slowdown/SS_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intimate-dining-in/ID_people_Front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/intimate-dining-in/ID_people_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/everyday-meal/EM_people_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-people/everyday-meal/EM_people_Back.jpg?${getSasKey()}`,
    },
  },
  theOccasions: {
    Fram_DS_5: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/casual-good-times/CGT_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/easygoing-hangout/EH_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/routine-living/RL_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/routine-living/RL_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/energetic-nightout/ENO_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/upbeat-social-meal/USM_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/simple-unplug/SU_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/lively-social-gathering/LSG_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intentional-unwind/IU_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/refined-weekend-out/RWO_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/thoughtful-connection/TC_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/sensible-slowdown/SS_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/intimate-dining-in/ID_occasions_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/the-occasions/everyday-meal/EM_occasions_Back.jpg?${getSasKey()}`,
    },
  },
  contextualFactor: {
    Fram_DS_5: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/casual-good-times/CGT_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/easygoing-hangout/EH_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/routine-living/RL_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/energetic-nightout/ENO_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/upbeat-social-meal/USM_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/simple-unplug/SU_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/lively-social-gathering/LSG_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intentional-unwind/IU_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/refined-weekend-out/RWO_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/thoughtful-connection/TC_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/sensible-slowdown/SS_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/intimate-dining-in/ID_contextual-factor_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/contextual-factor/everyday-meal/EM_contextual-factor_Back.jpg?${getSasKey()}`,
    },
  },
  driverAndNeeds: {
    Fram_DS_5: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/casual-good-times/CGT_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/easygoing-hangout/EH_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      front: () => `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd8486bcf4ecd4458978e04905d2bede6`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/routine-living/RL_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/energetic-nightout/ENO_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/upbeat-social-meal/USM_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/simple-unplug/SU_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2b5f7560e6d7491c89ab38622c993661`,
    },
    Fram_DS_7: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/lively-social-gathering/LSG_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intentional-unwind/IU_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/refined-weekend-out/RWO_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/thoughtful-connection/TC_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/sensible-slowdown/SS_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/intimate-dining-in/ID_drivers-needs_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/drivers-and-needs/everyday-meal/EM_drivers-needs_Back.jpg?${getSasKey()}`,
    },
  },
  inTheirOwnWords: {
    Fram_DS_1: {
      EGH_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_10.jpg?${getSasKey()}`,
      EGH_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_2.jpg?${getSasKey()}`,
      EGH_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_4.jpg?${getSasKey()}`,
      EGH_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_4.jpg?${getSasKey()}`,
      EGH_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_6.jpg?${getSasKey()}`,
      EGH_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_7.jpg?${getSasKey()}`,
      EGH_Mod_9: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/easygoing-hangout/images/EGH_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      RL_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_10.jpg?${getSasKey()}`,
      RL_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_2.jpg?${getSasKey()}`,
      RL_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_3.jpg?${getSasKey()}`,
      RL_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_4.jpg?${getSasKey()}`,
      RL_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_6.jpg?${getSasKey()}`,
      RL_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_7.jpg?${getSasKey()}`,
      RL_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/routine-living/images/RL_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      ENO_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_10.jpg?${getSasKey()}`,
      ENO_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_2.jpg?${getSasKey()}`,
      ENO_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_3.jpg?${getSasKey()}`,
      ENO_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_4.jpg?${getSasKey()}`,
      ENO_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_6.jpg?${getSasKey()}`,
      ENO_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_7.jpg?${getSasKey()}`,
      ENO_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/energetic-nightout/images/ENO_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      USM_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_10.jpg?${getSasKey()}`,
      USM_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_2.jpg?${getSasKey()}`,
      USM_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_3.jpg?${getSasKey()}`,
      USM_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_4.jpg?${getSasKey()}`,
      USM_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_6.jpg?${getSasKey()}`,
      USM_Mod_7: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_7.jpg?${getSasKey()}`,
      USM_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/upbeat-social-meal/images/USM_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_5: {
      CGT_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_10.jpg?${getSasKey()}`,
      CGT_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_2.jpg?${getSasKey()}`,
      CGT_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_3.jpg?${getSasKey()}`,
      CGT_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_4.jpg?${getSasKey()}`,
      CGT_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_6.jpg?${getSasKey()}`,
      CGT_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_7.jpg?${getSasKey()}`,
      CGT_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/casual-good-times/images/CGT_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      SU_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_10.jpg?${getSasKey()}`,
      SU_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_2.jpg?${getSasKey()}`,
      SU_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_3.jpg?${getSasKey()}`,
      SU_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_4.jpg?${getSasKey()}`,
      SU_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_6.jpg?${getSasKey()}`,
      SU_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_7.jpg?${getSasKey()}`,
      SU_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/simple-unplug/images/SU_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      LSG_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_10.jpg?${getSasKey()}`,
      LSG_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_2.jpg?${getSasKey()}`,
      LSG_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_3.jpg?${getSasKey()}`,
      LSG_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_4.jpg?${getSasKey()}`,
      LSG_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_6.jpg?${getSasKey()}`,
      LSG_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_7.jpg?${getSasKey()}`,
      LSG_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/lively-social-gathering/images/LSG_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      IU_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_10.jpg?${getSasKey()}`,
      IU_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_2.jpg?${getSasKey()}`,
      IU_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_3.jpg?${getSasKey()}`,
      IU_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_4.jpg?${getSasKey()}`,
      IU_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_6.jpg?${getSasKey()}`,
      IU_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_7.jpg?${getSasKey()}`,
      IU_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intentional-unwind/images/IU_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      RWO_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_10.jpg?${getSasKey()}`,
      RWO_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_2.jpg?${getSasKey()}`,
      RWO_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_3.jpg?${getSasKey()}`,
      RWO_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_4.jpg?${getSasKey()}`,
      RWO_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_6.jpg?${getSasKey()}`,
      RWO_Mod_7: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_7.jpg?${getSasKey()}`,
      RWO_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/refined-weekend-out/images/RWO_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_10: {
      TC_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_10.jpg?${getSasKey()}`,
      TC_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_2.jpg?${getSasKey()}`,
      TC_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_3.jpg?${getSasKey()}`,
      TC_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_4.jpg?${getSasKey()}`,
      TC_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_6.jpg?${getSasKey()}`,
      TC_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_7.jpg?${getSasKey()}`,
      TC_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/thoughtful-connection/images/TC_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      SS_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_10.jpg?${getSasKey()}`,
      SS_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_2.jpg?${getSasKey()}`,
      SS_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_3.jpg?${getSasKey()}`,
      SS_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_4.jpg?${getSasKey()}`,
      SS_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_6.jpg?${getSasKey()}`,
      SS_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_7.jpg?${getSasKey()}`,
      SS_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/sensible-slowdown/images/SS_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      ID_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_10.jpg?${getSasKey()}`,
      ID_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_2.jpg?${getSasKey()}`,
      ID_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_3.jpg?${getSasKey()}`,
      ID_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_4.jpg?${getSasKey()}`,
      ID_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_6.jpg?${getSasKey()}`,
      ID_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_7.jpg?${getSasKey()}`,
      ID_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/intimate-dining-in/images/ID_Mod_9.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      EM_Mod_10: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_10.jpg?${getSasKey()}`,
      EM_Mod_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_2.jpg?${getSasKey()}`,
      EM_Mod_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_3.jpg?${getSasKey()}`,
      EM_Mod_4: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_4.jpg?${getSasKey()}`,
      EM_Mod_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_6.jpg?${getSasKey()}`,
      EM_Mod_7: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_7.jpg?${getSasKey()}`,
      EM_Mod_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/in-their-own-words/everyday-meal/images/EM_Mod_9.jpg?${getSasKey()}`,
    },
  },
  consumption: {
    Fram_DS_5: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/casual-good-times/CGT_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/casual-good-times/CGT_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_1: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/easygoing-hangout/EH_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_2: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/routine-living/RL_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/routine-living/RL_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_3: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/energetic-nightout/ENO_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_4: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/upbeat-social-meal/USM_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_6: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/simple-unplug/SU_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/simple-unplug/SU_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_7: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/lively-social-gathering/LSG_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_8: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intentional-unwind/IU_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intentional-unwind/IU_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_9: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/refined-weekend-out/RWO_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5ab6faae0bf34f1ba9088fe5454fc694`,
    },
    Fram_DS_10: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/thoughtful-connection/TC_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_11: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/sensible-slowdown/SS_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_12: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/intimate-dining-in/ID_consumption_Back.jpg?${getSasKey()}`,
    },
    Fram_DS_13: {
      front: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/everyday-meal/EM_consumption_Front.jpg?${getSasKey()}`,
      back: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/consumption/everyday-meal/EM_consumption_Back.jpg?${getSasKey()}`,
    },
  },
  howTheyDrink: {
    Fram_DS_5: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Jessica.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Samantha.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Leo.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Sam.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Andrea.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_Portrait_Tyler.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car3_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fefc3309095fd4468849e41934b769c42%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_36}&alt=media&optimized=true`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_2.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car1_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car2_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/images/CGT_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/casual-good-times/video/CGT_Car6_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_1: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Jamie.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Marti.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Karl.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Brad.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Nick_IA.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_Portrait_Yessica.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_2.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car1_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feea20152e262491197e79f652ea5a525`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_2.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car5_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car3_Video.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/images/EGH_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/easygoing-hangout/video/EGH_Car6_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_2: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Jamie.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Marti.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Karl.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Brad.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Nick_IA.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_Portrait_Yessica.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car1_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car4_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9e18565ce28849b682ad1f80d0a0adc8`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F31b6589e81eb43339e63257a77cc19a8`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F331c334dca6b4d01a7969a10068e1013%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_37}&alt=media&optimized=true`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9782dd4eece547b9a9503d30866aed2a`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3bc10f3545c045f5bb3d5c8dbe32c408`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car3_1.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/images/RL_car6_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/routine-living/video/RL_Car6_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_3: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Andrea.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Tyler.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Nick_NY.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jourdan.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Jessica.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Samantha.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Hector.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_Portrait_Craig.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car1_2.jpg?${getSasKey()}`],
        verticalImage: null,
        videos: [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car7_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car7_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car6_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car4_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-6": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa5920331a4fb4e20a44b86d4bd04da01%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_38}&alt=media&optimized=true`],
      },
      "carousel-7": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/images/ENO_car8_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/energetic-nightout/video/ENO_Car8_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_4: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Nick_NY.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Jourdan.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Andrea.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Tyler.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Hector.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_Portrait_Craig.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car3_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff445d2ee4d5f456981bdec704b3780bd`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F774f876cba614152b10dd6e49dfbd865`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F54f662ee7a754d408b4ccc77b7f85a10`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fec14c4441f2c450d9d09797a3a058fee`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car6_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3a9af699c44c4f1683864dbb3d5db3cf`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/images/USM_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/upbeat-social-meal/video/USM_Car5_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_6: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Andrea.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Tyler.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Jessica.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Samantha.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Leo.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_Portrait_Bledar.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car2_1.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/images/SU_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/simple-unplug/video/SU_Car6_Video.mp4?${getSasKey()}`],
      },
    },

    Fram_DS_7: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Nick_NY.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Jourdan.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Hector.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Craig.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Kiana.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_Portrait_Daniel_NY.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car4_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/images/LSG_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/lively-social-gathering/video/LSG_Car6_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_8: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Nick_NY.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Jourdan.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Hector.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Craig.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Kiana.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_Portrait_Daniel_NY.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/images/IU_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intentional-unwind/video/IU_Car6_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_9: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Sharyn.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Danielle.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Jeannine.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Maria.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Kelly.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_Portrait_Brandon.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car1_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F97a0d53fcbc442cd83dcc15b38b97630%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_39}&alt=media&optimized=true`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/images/RWO_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/refined-weekend-out/video/RWO_Car3_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_10: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Sharyn.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Danielle.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jeannine.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Daniel_IL.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_Jon.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_Portrait_An.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car1_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car6_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/images/TC_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/thoughtful-connection/video/TC_Car3_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_11: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Sharyn.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Danielle.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Jeannine.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Maria.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Kelly.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_Portrait_Brandon.jpg?${getSasKey()}`
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car1_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F06630f7f060b4b32951b8400def57933`,
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffa015778cc654ad09ad3ea451c7fd9b2`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F636b01e943b5480baae92fab96b9a095%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_40}&alt=media&optimized=true`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/images/SS_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/sensible-slowdown/video/SS_Car3_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_12: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Anahi.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Sam.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Kat.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Elizabeth.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Melissa.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_Portrait_Daniel_IL.jpg?${getSasKey()}`
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car4_3.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car6_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_3.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/images/IDI_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/intimate-dining-in/video/IDI_Car1_Video.mp4?${getSasKey()}`],
      },
    },
    Fram_DS_13: {
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Leo.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Erika.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Noha.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Mike.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Steven.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_Portrait_Danielle.jpg?${getSasKey()}`,
      ],
      "carousel-0": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car3_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car3_Video.mp4?${getSasKey()}`],
      },
      "carousel-1": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car4_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car4_Video.mp4?${getSasKey()}`],
      },
      "carousel-2": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car5_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car5_Video.mp4?${getSasKey()}`],
      },
      "carousel-3": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car6_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car6_Video.mp4?${getSasKey()}`],
      },
      "carousel-4": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_1.jpg?${getSasKey()}`, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car2_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/video/EM_Car2_Video.mp4?${getSasKey()}`],
      },
      "carousel-5": {
        squareImages: () => [`${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_1.jpg?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/demandspace/template/how-they-drink/everyday-meal/images/EM_car1_2.jpg?${getSasKey()}`],
        verticalImage: () => null,
        videos: () => [null, `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ffd0d4a2b3dab427baed9a3293f1ab400%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_41}&alt=media&optimized=true`],
      },
    },
  },
};
