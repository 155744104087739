export const AlcoholCategoriesMenuOptions = [
  {
    header: "Beer",
    listOfItems: [
      {
        itemText: "Non-Alcoholic",
        routeParams: {
          path: "beer-hub/non-alcoholic",
        },
      },
      {
        itemText: "Malts & Ices",
        routeParams: {
          path: "beer-hub/malts-ices",
        },
      },
      {
        itemText: "American Classic",
        routeParams: {
          path: "beer-hub/american-classic",
        },
      },
      {
        itemText: "American Light",
        routeParams: {
          path: "beer-hub/american-light",
        },
      },
      {
        itemText: "American Balanced Light",
        routeParams: {
          path: "beer-hub/american-balanced",
        },
      },
    ],
  },
  {
    listOfItems: [
      {
        itemText: "Imports",
        routeParams: {
          path: "beer-hub/imports",
        },
      },
      {
        itemText: "Elevated",
        routeParams: {
          path: "beer-hub/elevated",
        },
      },
      {
        itemText: "IPA/Pale Ale",
        routeParams: {
          path: "beer-hub/ipa-pale-ale",
        },
      },
      {
        itemText: "Craft All Other",
        routeParams: {
          path: "beer-hub/craft-all-other",
        },
      },
      {
        itemText: "Flavored",
        routeParams: {
          path: "beer-hub/flavored",
        },
      },
    ],
  },
  {
    header: "Hard Beverage",
    listOfItems: [
      {
        itemText: "Seltzers (Spirit Based)",
        routeParams: {
          path: "hard-bev-hub/seltzer-spirit",
        },
      },
      {
        itemText: "Seltzers (Malt/Sugar)",
        routeParams: {
          path: "hard-bev-hub/seltzer-malt",
        },
      },
      {
        itemText: "Hard Cider",
        routeParams: {
          path: "hard-bev-hub/hard-cider",
        },
      },
      {
        itemText: "FMWBs Low ABV",
        routeParams: {
          path: "hard-bev-hub/fmwbs-low-abv",
        },
      },
    ],
  },
  {
    listOfItems: [
      {
        itemText: "FMWBs High ABV",
        routeParams: {
          path: "hard-bev-hub/fmwbs-high-abv",
        },
      },
      {
        itemText: "Spirit Based Cocktails",
        routeParams: {
          path: "hard-bev-hub/spirit-based-cocktails",
        },
      },
      {
        itemText: "Multi-Serve",
        routeParams: {
          path: "hard-bev-hub/multi-serve",
        },
      },
    ],
  },
];
